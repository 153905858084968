import React from "react";

// Customizable Area Start
import HeaderNavbar from "../../../components/src/Navbar";
import FavouritesTabs from "./components/FavouriteTabs";
// Customizable Area End

import FavouritesControllerWeb, {
  Props,
  configJSON,
} from "./FavouritesController.web";

export default class Favourites extends FavouritesControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <>
            <HeaderNavbar navigation={this.props.navigation}/>
            <FavouritesTabs  
              onClickNavigateToPostDetails={this.navigateToPostDetailsPage}
              profiles={this.state.profiles} 
              favouritesJobs={this.state.favouritesJobs} 
              favouritesPeople={this.state.favouritesPeople} 
              favouritesProjects={this.state.favouritesProjects} 
              data-test-id="favEvents" 
              favourites={this.state.favourites} 
              activeTab={this.state.activeTab} 
              onTabChange={this.handleChangeTab}
              onShareDataChange={this.handleShareData}
              onChangeChatMessage={this.handleChangeChatMessage}
              chatMessage={this.state.chatMessage}
              shareData={this.state.shareData}
              onClickShareButton={this.handleClickShareButton}
              isShareModalOpen={this.state.isShareModalOpen}
              onClickBackButton={this.handleBackButton}
              onClickChatButton={this.handleClickChatButton}
              isChatModalOpen={this.state.isChatModalOpen}
              isChatSend={this.state.isChatSend}
              onClickSendChat={this.handleSendChatButton}
              onClose={this.handleCloseButton}
              onClickDeleteFavourite={this.handleDeleteFavourite}
              searchKey={this.state.searchKey}
              onChangeSearchKey={this.handleChangeSearchKey}
              onNavigateToDetailsPage={this.navigateToPage}
              shareLink={this.state.shareLink}
              isLoading={this.state.isLoading}
              count={this.state.count}
              onLoadMoreClick={this.handleLoadMoreClick}
              needLoadMoreBtn={this.state.needLoadMoreBtn}
            />
        </>
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End