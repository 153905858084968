import React from "react";
// Customizable Area Start
import {
    Button,
    Grid, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Select,
    Box,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles, } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import CustomTextField from "./CustomTextField";
import CustomTag from "./CustomTags";
const closeIcon = require("./close.svg");
import * as Yup from "yup";
type Props = {
    isOpen: boolean;
    onClose: () => void;

}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        experienceInnerDiv1: {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "1195px",
            background: "#000",
            borderRadius: "4px, 4px, 0px, 0px",
            fontSize: "14px !important",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            lineHeight: "19.3px",
            color: "white",
            alignItems: "center"
        },
        buttonOutline: {
            borderRadius: "2px",
            borderColor: "black",
            marginTop: "20px",
            textTransform: "none"
        },
        dialougeHeading: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "19px",

        },
        buttonFilled: {
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            textTransform: "none"
        },
        crossImage: {
            position: 'absolute',
            top: 1,
            right: 1,
            cursor: "pointer",
        },
        errorMessage: {
            fontFamily: "Silika-Light",
            fontWeight: 300,
            fontSize: "14px",
            lineHeight: "19.69px",
            color: "#DC2626",
            marginTop: "12px"
        }

    })
);



function JobPostPopUp(props: Props) {
    const { isOpen, onClose } = props;
    const classes = useStyles();
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={isOpen}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className={classes.experienceInnerDiv1}>
                <DialogTitle className={classes.dialougeHeading}>
                    Post Job
                </DialogTitle>
                <img src={closeIcon} style={{ width: "20px", height: "20px", marginRight: "32px", cursor: "pointer" }} id="close-img" onClick={onClose}></img>
            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Formik
                        initialValues={[]}
                        validationSchema={Yup.object().shape({
                            projectTitle: Yup.string().required("Project Title is required"),
                            description: Yup.string().required("Description is required"),
                        })}
                        onSubmit={(values) => {

                        }}
                    >
                        {({
                            handleChange,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                            isValid
                        }) => {
                            return (
                                <Form>
                                    <Grid item container spacing={2}>
                                        <Grid container item sm={12} style={{ paddingLeft: "18px" }} spacing={2}>
                                            <Field
                                                name="roleTitle"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Role Title"
                                                placeHolder=""
                                                title1="*"
                                                id="roleTitle"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2}>
                                        <Grid container item sm={12} style={{ paddingLeft: "18px" }} spacing={2}>
                                            <Field
                                                name="description"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Description"
                                                placeHolder=""
                                                title1="*"
                                                multiline={true}
                                                rows={4}
                                                InputProps={{
                                                    style: { height: "200px", overflowY: "auto" }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2}>
                                        <Grid item container sm={6} style={{ paddingLeft: "10px" }}>
                                            <Field
                                                name="jobType"
                                                component={CustomTag}
                                                type="string"
                                                variant="outlined"
                                                title="Job Type"
                                                placeHolder=""
                                                options={[

                                                ]}
                                                isTag={true}
                                            />
                                        </Grid>
                                        <Grid item container sm={6} style={{ paddingLeft: "10px", paddingRight: "5px"}}>
                                            <Field
                                                name="location"
                                                component={CustomTag}
                                                type="string"
                                                variant="outlined"
                                                title="Location"
                                                placeHolder=""
                                                options={[

                                                ]}
                                                isTag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2}>
                                        <Grid item container sm={8} style={{ paddingLeft: "10px" }}>
                                            <Field
                                                name="professions"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Professions"
                                                placeHolder=""
                                            />
                                        </Grid>
                                        <Grid item container sm={4} style={{ paddingLeft: "18px", paddingRight: "8px" }} >
                                            <Field
                                                name="level"
                                                component={CustomTag}
                                                type="string"
                                                variant="outlined"
                                                title="Level"
                                                placeHolder=""
                                                options={[
                                                ]}
                                                isTag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={2}>
                                        <Grid item container sm={6} style={{ paddingLeft: "10px" }}>
                                            <Field
                                                name="minRate"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Min Rate"
                                                placeHolder=""
                                                id="minRate"
                                            />
                                        </Grid>
                                        <Grid item container sm={6} style={{ paddingLeft: "15px", paddingRight: "12px" }}>
                                            <Field
                                                name="maxRate"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Max Rate"
                                                placeHolder=""
                                                id="maxRate"
                                            />
                                        </Grid>
                                    </Grid>
                                    <DialogActions style={{padding: "4px"}}>
                                        <Button variant="outlined" className={classes.buttonOutline} id="jobPost-id" onClick={() => {
                                            onClose()
                                        }}>Cancel</Button>
                                        <Button id="submitting" variant="contained" type="submit" className={classes.buttonFilled} onClick={() => {
                                            onClose()
                                        }}>
                                            Post
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}


export default JobPostPopUp
