import React from "react";
import { styled, Theme, Typography } from "@material-ui/core";
import NoFavourites from "./NoFavourites";
import { FavouriteProject, FavouritesProjectsProps } from "../types";

const StyledProjectsContainer = styled("div")(({theme}: {theme : Theme}) => ({
    marginTop: "95px",
    display: "inline-flex",
    flexWrap: "wrap",
    gridTemplateColumns: "repeat(auto-fit, minmax(303px, 1fr))",
    gap: "24px",
    width: "100%",
    [theme.breakpoints.down("sm")]:{
        marginTop: "24px !important",
    },
    "& .project":{
        position: "relative",
        display: "flex",
        width: "302px",
        marginRight: "12px",
        marginBottom: "12px",
        height: "233px",
        "@media (max-width: 1487px)": {
            width: "295px",
            height: "228px"
        },
        "@media (max-width: 1459px)": {
            width: "286px",
            height: "221px"
        },
        "@media (max-width: 1423px)": {
            width: "280px",
            height: "216px",
        },
        "@media (max-width: 1399px)": {
            width: "270px",
            height: "206px",
        },
        "@media (max-width: 1359px)": {
            width: "260px",
            height: "201px"
        },
        "@media (max-width: 1319px)": {
            width: "250px",
            height: "193px"
        },
        "@media (max-width: 1279px)": {
            width: "235px",
            height: "182px"
        },
        "@media (max-width: 1219px)": {
            width: "226px",
            height: "175px"
        },
        "@media (max-width: 1183px)": {
            width: "300px",
            height: "232px"
        },
        "@media (max-width: 1143px)": {
            width: "285px",
            height: "220px"
        },
        "@media (max-width: 1098px)": {
            width: "270px",
            height: "209px"
        },
        "@media (max-width: 1053px)": {
            width: "260px",
            height: "201px"
        },
        "@media (max-width: 1023px)": {
            width: "405px",
            height: "312px"
        },
        "@media (max-width: 1018px)": {
            width: "calc(50% - 24px)",
            height: "calc(40vh - 70px)"
        },
        
        "@media (max-width: 790px)": {
            width: "100%",
            height: "auto",
            display: "flex",
            flexFlow: "column",
            "& .projectName":{
                fontSize: "16px",
                marginTop: "8px",
                marginBottom: "4px"
            },
            "& .projectOwner":{
                fontSize: "14px",
                fontWeight: 400
            }
        },
        "& img":{
            width: "100%",
            height: "auto",
            border: "1px solid black",
            borderRadius: "4px",
            "@media (max-width: 790px)": {
                height: "252px"
            }
        }
    },
    "& .hoverOverlay":{
        position: "absolute",
        top:0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        fontFamily: "Silika-Medium",
        color: "#010101"
    },
    "& .project:hover .hoverOverlay":{
        opacity: 1,
        backgroundColor: "rgba(217, 217, 217, 0.36)",
        transition: "opacity 0.3s ease",
        filter:"grayscale(0.3)"
    }
}));

class FavouriteProjectsList extends React.Component<FavouritesProjectsProps>{
    constructor(props: FavouritesProjectsProps){
        super(props);
    }

    render(){
        const {favouritesProjects, windowWidth, onClickNavigateToPostDetails} = this.props;
        const isMobile = windowWidth && windowWidth <= 599;
        if(favouritesProjects.length > 0){
            return (<StyledProjectsContainer data-test-id="favouriteProject" >
                {favouritesProjects.map((favProject: FavouriteProject) => <div className="project" onClick={() => onClickNavigateToPostDetails(favProject.favouritableId)}>
                <img src={favProject.image} alt={favProject.title} />
                <div className={isMobile ? "": "hoverOverlay"}>
                <Typography className="projectName">{favProject.title}</Typography>
                <Typography className="projectOwner">{favProject.postCreator}</Typography>
                </div>
            </div>)}
            </StyledProjectsContainer>)
        }else{
            return <NoFavourites data-test-id="no-fav-projects" title="You don’t have favourites projects yet"/>
        }
    }
}

export default FavouriteProjectsList;