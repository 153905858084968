import React from "react";
// Customizable Area Start
import {
    Button,
    IconButton, 
    Typography, 
    Grid, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    DialogContentText, 
    Avatar, 
    Box, 
    FormControlLabel,
    useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles, styled } from "@material-ui/core/styles"
import { Formik, Form, Field } from "formik"
import { PopUpSaved, S } from "../PostCreationWebController"
import CustomTextField from "../../../../components/src/CustomTextField"
import CustomTag from "../../../../components/src/CustomTags"
import { cancle, close, thumbnail, addImage , addIcon} from "../assets"
import * as Yup from "yup"
import SwitchBtn from "../../../../components/src/Switch"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
type Props = {
    state: S,
    removeImage: (event: React.MouseEvent<HTMLImageElement, MouseEvent>,value: number) => void,
    handleImageChange: (value: React.ChangeEvent<HTMLInputElement>) => void
    handlePreviewOpen: () => void
    handleClosePopUp : () =>void
    savePopUpData: (value: PopUpSaved) => void
    updatePost: (value: PopUpSaved, isEdited : boolean) => void
    handleChangeToggle:() => void
    handleKeyDown:(event: React.KeyboardEvent<HTMLDivElement>) => void
    handleKeyDownDes:(event: React.KeyboardEvent<HTMLDivElement>) => void
    isEditMode : boolean,
    onClickClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        confidentialProject: {
            fontFamily: "Silika-light",
            fontSize: "14px",
            fontWeight: 300,

        },
        experienceInnerDiv1: {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "1195px",
            background: "#000",
            borderRadius: "4px, 4px, 0px, 0px",
            fontSize: "14px !important",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            lineHeight: "19.3px",
            color: "white",
            alignItems: "center"
        },
        mobileExperienceInnerDiv1: {
            display: "flex",
            gap: "6px",
            maxWidth: "1195px",
            borderRadius: "4px, 4px, 0px, 0px",
            fontSize: "14px !important",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            lineHeight: "19.3px",
            alignItems: "center",
            height : "40px",
            margin : "24px 24px 0 24px"
        },
        buttonOutline: {
            borderRadius: "2px",
            borderColor: "black",
            marginTop: "20px",
            textTransform: "none"
        },
        dialougeHeading: {
            "& *":{
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            },
            lineHeight: "19px",

        },
        inputTitle: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            marginTop: "2%",
            color: "#010101"
        },
        buttonFilled: {
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            textTransform: "none"
        },
        switch: {
            position: "relative",
            display: "inline-block",
            width: "48px",
            height: "28px",
        },
        slider: {
            position: "absolute",
            cursor: "pointer",
            top: 0,
            left: 0,
            right: "15px",
            bottom: 0,
            backgroundColor: "#00FFA7",
            width: "48px",
            borderRadius: "50px",
        },
        confidentialDiv: {
            marginTop: "30px", 
            display : "flex",
            width  :"100%",
            flexWrap  :"wrap"
        },
        confidentialDiv2: {
            minWidth : "250px",
            display : "flex",
            gap : "12px", 
            justifyContent : "space-between"
        },
        confidentialMainTask: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            color: "#000000"
        },
        confidentialSubTask: {
            fontFamily: "Silika-ExtraLight",
            fontSize: "14px",
            fontWeight: 300,
        },
        imageGridStyle: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginBottom : "36px"
        },
        imageGridStyleText: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            color: "#010101"
        },
        imageDisplay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: 'row',
            flexWrap  :"wrap",
            width : "100%",
            height : "100%",
        },
        borderOfImage: {
            border: "1.5px dashed #9B9B9D",
            borderRadius: "8px",
            position: 'relative',
            padding:"10px",
            marginRight : "12px",
            marginBottom : "12px",
            height: "90px",
            width: "90px"
        },
        borderOfImageSmallScreen : {
            padding : "0",
            border : "none",
            marginRight : "0",
            marginBottom : "0"
        },
        crossImage: {
            position: 'absolute',
            top: 1,
            right: 1,
            cursor: "pointer",
            color : "#fff",
            zIndex: 2,
        },
        errorMessage : {
            fontFamily : "Silika-Light",
            fontWeight : 300,
            fontSize : "14px",
            lineHeight : "19.69px",
            color : "#DC2626",
            marginTop : "12px"
        },
        backButtonTxt  :{
            fontFamily : "Silika-Medium",
            fontWeight : 500,
            fontSize : "16px",
            lineHeight : "40px",
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(225, 225, 225, 0.2)', 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          },
          addMoreDiv : {
            display : "flex",
            justifyContent : "center"
          },
          addMoreBtn : {
            height : "32px",
            width  :"132px",
            display  :"flex",
            alignItems : "center",
            gap  :"8px",
            marginTop  :"16px",
            paddingLeft  :"16px"
          },
          addMoretxt : {
            fontFamily : "Silika-Medium",
            fontWeight : 500,
            fontSize : "14px",
            lineHeight : "24px",
            color : "black"
          },
          addMoreIcon : {
            height : "24px",
            width  :"24px",
            color : "black"
          },
          hiddenInput: {
            display: 'none',
          },
          btnStyle : {
            width : "100%",
            height : "48px",
            boxSizing : "border-box",
            marginTop : "16px",
          },
          btnStyle2 : {
            width : "100%",
            height : "48px",
            boxSizing : "border-box",
            marginTop : "16px",
            backgroundColor : "black",
            color: "white",
          },
          btnParent : {
            display : "flex",
            alignItems : "center", 
            marginTop : "24px",
            flexDirection : "row",
            justifyContent : "flex-end",
            gap : "24px",
            "& button":{
                fontFamily: "Silika-Medium"
              }
          },
          btnParentSmallScreen : {
            flexDirection : "column",
            justifyContent : "center",
            gap : "0"
          }

    })
);

export const StyledFieldBox = styled(Grid)({
    "& .MuiOutlinedInput-input":{
      width:"auto !important"
    }
})

function PostCreationPopUp(props: Props) {
    const {
        state, 
        removeImage, 
        handleImageChange, 
        handlePreviewOpen, 
        savePopUpData, 
        updatePost, 
        handleChangeToggle, 
        handleKeyDown, 
        handleKeyDownDes, 
        isEditMode, 
        handleClosePopUp 
    } = props;
    const classes = useStyles();
    const isSmallScreen = useMediaQuery('(max-width:550px)');
    const getImageDimensions = (index: number) => {
        const isOnlyImage = state.uploadMultipleImagesAPI.length === 1;
        const isLastImage = index === state.uploadMultipleImagesAPI.length - 1;
        const isOddNumberOfImages = state.uploadMultipleImagesAPI.length % 2 !== 0;
        const normalWidth="90px";
    
        if (isOnlyImage || isLastImage && isOddNumberOfImages) {
          return {
            width: isSmallScreen ?'100%' : normalWidth,
            height: isSmallScreen ? 'auto' : normalWidth,
          };
        } else {
          return {
            width: isSmallScreen ? '50%' : normalWidth,
            height: isSmallScreen ? '50%' : normalWidth,
          };
        }
      };
    const ImageGrid = ()=>{
        return(
            <Grid container sm={12} item className={classes.imageGridStyle}>
            {state.uploadMultipleImagesAPI.length > 0 ? (
                <Grid  className={classes.imageDisplay}>
                {state.uploadMultipleImagesAPI.map((srcImage, index) => {
                    const { width, height } = getImageDimensions(index);
                    return (
                    <Box key={index} className={`${classes.borderOfImage} ${isSmallScreen ? classes.borderOfImageSmallScreen : ''}` } style={{ width, height }}>
                        {srcImage.file.type.includes('image') ?(
                        <div>
                            <img
                            src={URL.createObjectURL(srcImage.file)}
                            style={{  width: '100%', height: '100%', objectFit: 'cover' }}
                            alt={`uploaded-img-${index}`}
                            />
                                <div className={classes.overlay}>
                                <img
                                onClick={(event) => removeImage(event, index)}
                                id="remove-image"
                                className={classes.crossImage}
                                src={cancle}
                                alt="Cross"
                                />
                            </div>
                        </div>
                        ):
                        (
                        <div>
                            <video
                            controls
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                

                            }}
                            >
                            <source src={URL.createObjectURL(srcImage.file)} type={srcImage.file.type} />
                            </video>
                            <div className={classes.overlay}>
                                <img
                                onClick={(event) => removeImage(event, index)}
                                id="remove-image1"
                                className={classes.crossImage}
                                src={cancle}
                                alt="Cross"
                                />
                            </div>
                        </div>
                        )}
                    </Box>
                    );
                })}

                {!isSmallScreen ?
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    className={classes.borderOfImage}
                >
                    <input
                    type="file"
                    hidden
                    name="uploadMultipleImages"
                    accept="video/*,image/*"
                    className="filetype"
                    id="group_image1"
                    onChange={(event) => handleImageChange(event)}
                    multiple
                    />
                    <Avatar
                    style={{ borderRadius: 0, width: 66, height: 66 }}
                    className="profile-avatar"
                    src={addImage}
                    />
                </IconButton>
                :<>
                    <br />
                    <div className={classes.addMoreDiv}>
                            <div className={classes.addMoreBtn}>
                                <Typography className={classes.addMoretxt}>
                                    Add more
                                </Typography>
                                <label>
                                <input
                                    type="file"
                                    hidden
                                    name="uploadMultipleImages"
                                    accept="video/*,image/*"
                                    onChange={(event) => handleImageChange(event)}
                                    multiple
                                    className={classes.hiddenInput}
                                />
                                <img
                                    width={14}
                                    height={14}
                                    className={classes.addMoreIcon}
                                    src={addIcon}
                                    alt="Add more"
                                />
                                </label>
                            </div>
                    </div>
                </>
                }
                </Grid>
            ) : (
                <>
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    className="icon-btn-1"
                >
                    <input
                    type="file"
                    hidden
                    name="uploadMultipleImages"
                    accept="video/*,image/*"
                    className="filetype"
                    id="group_image"
                    onChange={(event) => handleImageChange(event)}
                    multiple
                    />
                    <Avatar
                    className="profile-avatar"
                    style={{ width: 70, height: 70 }}
                    src={thumbnail}
                    />
                </IconButton>
                <br />
                {state.imageSize && <Typography style={{ color: "#DC2626", fontFamily: "Silika-Light", fontSize: "14px" }}>No upload can be greater than 5MB</Typography>}
                {state.uploadMultipleImages.length === 0 && <Typography className={classes.imageGridStyleText}>Select Media From Library<span style={{ color: "red" }}>*</span></Typography>}
                </>
            )}
            {state.imageSize && (
                <Typography className={classes.errorMessage}>
                No upload can be greater than 5MB
                </Typography>
            )}
        </Grid>
        )
    }
    return (
        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={state.displayDialoug}
            aria-describedby="alert-dialog-slide-description"
            fullScreen={isSmallScreen}
        >
             {isSmallScreen ? 
                (
                    <div className={classes.mobileExperienceInnerDiv1}>
                        <ChevronLeftIcon onClick={handleClosePopUp} style={{cursor : "pointer"}} />
                        <Typography className={classes.backButtonTxt}>
                            Back
                        </Typography>
                    </div>
                ) : (
                <div className={classes.experienceInnerDiv1}>
                    <DialogTitle className={classes.dialougeHeading}>
                        {isEditMode ? "Edit Post" : "Add Post"}
                    </DialogTitle>
                    <img src={close} style={{ width: "14px", height: "14px", marginRight: "20px", cursor: "pointer" }} id="close-img" onClick={handleClosePopUp}></img>
                </div>
             )}
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Formik
                        initialValues={state.addPost}
                        validationSchema={Yup.object().shape({
                            projectTitle: Yup.string().required("Project Title is required"),
                            description: Yup.string().required("Description is required"),
                        })}
                        onSubmit={(values) => {
                            updatePost(values, isEditMode); 
                        }}
                    >
                        {({
                            handleChange,
                            values,
                        }) => {
                            const noImages = !state.uploadMultipleImagesAPI.length;
                            const btndisabled = values.projectTitle === "" || values.description === "" || noImages;
                            return (
                                <Form>
                                    {ImageGrid()}
                                    <Grid item container sm={12}>
                                            <Field
                                                name="projectTitle"
                                                component={CustomTextField}
                                                type="string"
                                                variant="outlined"
                                                title="Project Title"
                                                placeHolder=""
                                                title1="*"
                                                handleKeyDown={handleKeyDown}
                                                stateBold={state.boldITitle}
                                                stateItalic={state.italicTitle}
                                                id="profileTitel"
                                            />
                                    </Grid>
                                    <Grid item sm={12} container>
                                        <Field
                                            name="description"
                                            component={CustomTextField}
                                            type="string"
                                            variant="outlined"
                                            title="Description"
                                            placeHolder=""
                                            title1="*"
                                            multiline={true}
                                            rows={4}
                                            stateBold={state.bolddescription}
                                            stateItalic={state.italicdescription}
                                            handleKeyDown={handleKeyDownDes}
                                            InputProps={{
                                                style: { height: "200px", overflowY: "auto" } 
                                            }}
                                        />
                                    </Grid>
                                    <StyledFieldBox  item container sm={12}>
                                        <Field
                                            name="collabarators"
                                            component={CustomTag}
                                            type="string"
                                            variant="outlined"
                                            title="Collaborators"
                                            placeHolder=""
                                            inputProps={{ maxLength: 60 }}
                                            options={state.getTagsList}
                                            value={values.collabarators}
                                            onKeyDown={handleKeyDown}
                                            extractEmailDomain={true}
                                            isCreatable={true}
                                        />
                                    </StyledFieldBox>
                                    <StyledFieldBox  item container sm={12}>
                                        <Field
                                            name="tags"
                                            component={CustomTag}
                                            type="string"
                                            variant="outlined"
                                            title="Tags"
                                            placeHolder=""
                                            options={state.tags}
                                            isTag={true}
                                        />
                                    </StyledFieldBox>
                                    <Grid item container sm={12} className={classes.confidentialDiv} >
                                        <div className={classes.confidentialDiv2} >
                                        <Typography className={classes.confidentialMainTask} >Confidential project</Typography>
                                        <FormControlLabel
                                            onChange={handleChange}
                                            control={<SwitchBtn checked={state.postData.attributes.confidential}  handleChangeToggle={handleChangeToggle} />}
                                            label=""
                                            name="confidential"
                                        />
                                        </div>
                                        <Typography className={classes.confidentialProject} style={{ alignSelf: "center" }}>Confidential projects are only visible to collaborators</Typography>
                                    </Grid>
                                    <Grid className={`${classes.btnParent} ${isSmallScreen ? classes.btnParentSmallScreen : ''}`} item container sm={12} spacing={2} >
                                        <Button  disabled={btndisabled} variant="outlined"  className={`${isSmallScreen ? classes.btnStyle : classes.buttonOutline}`} id="preview-id" onClick={() => {
                                            savePopUpData(values)
                                            handlePreviewOpen()
                                        }}>Preview</Button>
                                        <Button disabled={btndisabled} id="submitting" variant="contained" type="submit" className={`${isSmallScreen ? classes.btnStyle2 : classes.buttonFilled}`}>
                                            {isEditMode ? "Update" : "Publish"}
                                        </Button>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}



export default PostCreationPopUp
