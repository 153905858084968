import React from "react";

// Customizable Area Start
import {
    Typography, Grid, TextField,  Box, styled, Divider
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledButtonFloat, IOSSwitch } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import { uploadImg } from "./assets";
import PhoneInput from 'react-phone-input-2';
import "../../customisableuserprofiles/src/ProfileInfo.css"
import 'react-phone-input-2/lib/style.css';
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const StyledError = styled("span")({
    color: "#DC2626",
    fontFamily: "Silika-Light",
    fontSize: "12px",
    lineHeight: "18px",
    height: "18px"
});

const StyledPhoneInput = styled(PhoneInput)({
    "& input::placeholder":{
        fontFamily: "Silika-Medium !important"
    },
    "& input":{
        fontFamily: "Silika-Medium !important"
    }
})

const StyledErrorMessage = styled("span")({
    color: "#DC2626",
    fontFamily: "Silika-Medium",
    width: "100%",
    display: "block",
    textAlign: "start",
    paddingTop: "3px",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    height: "20px"
});

const StyledDivided = styled(Divider)({
    color: "#DFDFDF",
    marginBottom: "28px",
    marginTop: "47px"
});

const AutocompleteCustom = styled(Autocomplete)({
    "& *":{
        fontFamily: "Silika-Medium"
    },
    "& input":{
        height: "11px"
    },
    "& input::placeholder":{
        fontFamily: "Silika-Medium !important",
        color: "hsl(0,0%,50%) !important",
        opacity: "1 !important",
    }
})

const StyledTextField = styled(TextField)({
    "& .formHelperClass":{
        color: "#DC2626",
        marginLeft: "0px",
        fontFamily: "Silika-Medium"
    },
    "& input, input::placeholder":{
        fontFamily: "Silika-Medium !important"
    }
});

const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phone: Yup.string().required("Phone is required"),
    region: Yup.string(),
    country: Yup.string().required("Country is required"),
    town: Yup.string(),
    tradingAddress: Yup.boolean(),
    companyName: Yup.string().when("tradingAddress",{
        is: true,
        then: Yup.string().required("Company Name is required"),
        otherwise: Yup.string().notRequired()
    }),
    companyNumber: Yup.string().when("tradingAddress",{
        is: true,
        then: Yup.string().required("Company Number is required"),
        otherwise: Yup.string().notRequired()
    }),
    companyAddress: Yup.string().when("tradingAddress",{
        is: true,
        then: Yup.string().required("Company Address is required"),
        otherwise: Yup.string().notRequired()
    }),
    file: Yup.mixed(),
    profileImageSrc: Yup.string().nullable(),
    company_trading_name : Yup.string()
    
})

interface Option {
    name: string;
    isoCode: string;
    flag: string;
    phonecode: string;
    currency: string;
    latitude: string;
    longitude: string;
    timezones: TimeZone[];
  }

  interface TimeZone {
    zoneName: string;
    gmtOffset: number;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;
}
// Customizable Area End
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
export default class AddUserProfile extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    searchParams = new URLSearchParams(window.location.search);
    roleId = this.searchParams.get("roleId") || "2";
    isCreative=this.roleId==="2"

    handleFile = (file: File | undefined, setFieldError: (field: string, mmessage: string) => void, setFieldValue: (field: string, value: File | string) => void) => {
        if(file){
            if(file.size > 5 * 1024 * 1024){
                setFieldError("file","An image cannot be bigger than 5 MB");
            }else{
                const imageDataUrl = URL.createObjectURL(file);
                setFieldValue("file", file);
                setFieldValue("profileImageSrc", imageDataUrl);
            }
        }
    };

    handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation();
        event.preventDefault()
        this.fileInputRef.current && this.fileInputRef.current.click();
    };

    renderCompanyDetails(tradingAddress: boolean, companyName: string, companyNumber: string, companyAddress: string, vatNumber: string, onBlur: (event: React.FocusEvent) => void, onChange: (event: React.ChangeEvent) => void) {
        return (tradingAddress || !this.isCreative) && <Grid container spacing={2} style={{ textAlign: 'center'}}>
            <Grid item xs={12} lg={6} md={6}>
                <StyledTextField
                    fullWidth
                    helperText={<ErrorMessage name="companyName" />}
                    onBlur={onBlur}
                    onChange={onChange}
                    FormHelperTextProps={{
                        className: "formHelperClass"
                    }}
                    data-test-id='companyName'
                    name={"companyName"}
                    placeholder="Company Name"
                    variant="outlined"
                    value={companyName}
                    inputProps={{
                        maxLength: 30,
                        style: { 
                            height: 10, 
                            fontFamily: "Silika-Medium !important"
                        }, className:"customTextField"
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
                <StyledTextField
                    FormHelperTextProps={{
                        className: "formHelperClass"
                    }}
                    helperText={<ErrorMessage name="companyNumber" />}
                    onBlur={onBlur}
                    fullWidth
                    data-test-id='companyNumber'
                    name={"companyNumber"}
                    placeholder="Company Number"
                    variant="outlined"
                    value={companyNumber}
                    onChange={onChange}
                    inputProps={{ maxLength: 30, style: { height: 10 }, className:"customTextField" }}
                />
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
                <StyledTextField
                    fullWidth
                    data-test-id='companyAddress'
                    name={"companyAddress"}
                    helperText={<ErrorMessage name="companyAddress" />}
                    onBlur={onBlur}
                    placeholder="Registered Address"
                    variant="outlined"
                    value={companyAddress}
                    FormHelperTextProps={{
                        className: "formHelperClass"
                    }}
                    onChange={onChange}
                    inputProps={{ maxLength: 30, style: { height: 10 }, className: "customTextField" }}
                />
            </Grid>

            <Grid item xs={12} lg={6} md={6}>
                <StyledTextField
                    fullWidth
                    data-test-id='vatNumber'
                    name={"vatNumber"}
                    helperText={<ErrorMessage name="vatNumber" />}
                    onBlur={onBlur}
                    placeholder="VAT Number if applicable"
                    variant="outlined"
                    FormHelperTextProps={{
                        className: "formHelperClass"
                    }}
                    value={vatNumber}
                    onChange={onChange}
                    inputProps={{ maxLength: 30, style: { height: 10 }, className:"customTextField" }}
                />
            </Grid>
        </Grid>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Required for all blocks
        return (
            <ThemeProvider theme={theme}>
                <div
                    style={{ overflow: 'hidden'}}>
                    <LoginSignUp navigation={this.props.navigation} id={""} data-test-id="addUserProfileContainer">
                        <Box width={{xs : "90%", sm : "90%",  md: "672"}}>
                            <Formik
                                initialValues={{
                                    firstName: this.state.firstName,
                                    lastName: this.state.lastName,
                                    phone: this.state.phone,
                                    region: this.state.selectedRegion,
                                    town: this.state.selectedCity,
                                    companyNumber: this.state.companyNumber,
                                    country: this.state.selectedCountry,
                                    companyName: this.state.companyName,
                                    companyAddress: this.state.companyAddress,
                                    vatNumber: this.state.vatNumber,
                                    tradingAddress: this.state.tradingAddress,
                                    profileImageSrc: this.state.profileImageSrc,
                                    file: null,
                                    company_trading_name: this.state.company_trading_name
                                }}
                                data-test-id="formikForm"
                                onSubmit={(values) => { 
                                    this.handleNavigate(values)
                                }}
                                enableReinitialize
                                validationSchema={validationSchema}
                            >
                                {({
                                    touched, handleSubmit,values, setFieldValue, handleBlur, handleChange, errors, setFieldError, setFieldTouched, validateField
                                }) => {
                                    const isPhoneFieldValid = touched.phone && errors.phone;
                                    const phoneFieldColor = isPhoneFieldValid ? "#DC2626": "";
                                    return (
                                        <form style={{ height: '100%', paddingRight: 10 }} autoComplete="off" onSubmit={handleSubmit}>
    
                                            <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: '40px' }}>
                                                <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left", }}>
                                                    About Me
                                                </Typography>
                                            </div>
                                            <Grid container spacing={2} style={{ textAlign: 'center' }}>
                                                <Grid item xs={12}>
                                                    <img
                                                        style={{
                                                            height: '90px', width: '90px',
                                                            cursor: 'pointer', borderRadius: '100px',
                                                            border: values.profileImageSrc ? '2px solid black' : ""
                                                        }}
                                                        data-test-id='uploadImg1'
                                                        src={values.profileImageSrc || uploadImg}
                                                        onClick={(event: React.MouseEvent<HTMLImageElement>) => this.handleImageClick(event)}
                                                        alt="Upload Profile Picture"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{
                                                            lineHeight: 2,
                                                            fontSize: '15px',
                                                            color: values.profileImageSrc ? 'black' : '#9B9B9D',
                                                            fontFamily: 'Silika-Medium',
                                                        }}
                                                    >
                                                        {values.profileImageSrc ? 'Update' : 'Upload Profile Picture'}
                                                    </Typography>
                                                    <ErrorMessage name="file" />
                                                     <StyledError>{errors.file ? "An image cannot be bigger than 5 MB" : "" }</StyledError>
                                                        <StyledDivided/>
                                                </Grid>
                                                <input
                                                    data-test-id='updateImg'
                                                    ref={this.fileInputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(event)=>{
                                                        const file = event.currentTarget?.files?.[0];
                                                        this.handleFile(file, setFieldError, setFieldValue);
                                                    }}
                                                    name="file"
                                                />
                                            </Grid>
                                            <Grid container spacing={2} style={{ textAlign: 'center' }}>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"firstName"}
                                                        value={values.firstName}
                                                        inputProps={{ maxLength: 30, style: { height: 10 } , className:"customTextField"}}
                                                        placeholder="First Name"
                                                        variant="outlined"
                                                        data-test-id='firstName'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="firstName" />}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"lastName"}
                                                        data-test-id='lastName'
                                                        value={values.lastName}
                                                        placeholder="Last Name"
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="lastName" />}
                                                        inputProps={{ maxLength: 30, style: { height: 10 }, className:"customTextField" }}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <StyledPhoneInput
                                                        data-test-id='contactNumber'
                                                        inputClass="phoneInputInputClass"
                                                        dropdownClass="phoneInputDropdownClass"
                                                        placeholder="Contact Number"
                                                        value={values.phone}
                                                        inputStyle={{ borderColor: phoneFieldColor }}
                                                        buttonStyle={{
                                                            borderLeftColor: phoneFieldColor,
                                                            borderTopColor: phoneFieldColor,
                                                            borderBottomColor: phoneFieldColor
                                                        }}
                                                        inputProps={{ maxLength: 15 }}
                                                        onChange={(phone: string) => {
                                                            setFieldValue("phone", phone);
                                                        }} 
                                                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                                            validateField("phone");
                                                            setFieldTouched("phone", true);
                                                        }}
                                                        />
                                                        {phoneFieldColor && <StyledErrorMessage>Phone number is required</StyledErrorMessage>}
                                                </Grid>
    
                                                <Grid item xs={12} lg={6} md={6}>
                                                <AutocompleteCustom
                                                    popupIcon={<KeyboardArrowDownIcon />}
                                                    disableClearable
                                                    id="combo-box-demo"
                                                    data-test-id="CountryData"
                                                    value={values.country}
                                                    options={this.getCountry()?.map((options) => options.value)}
                                                    getOptionLabel={(option:unknown) =>  (option as Option).name}
                                                    renderInput={(params) => <StyledTextField placeholder="Country" {...params} variant="outlined" FormHelperTextProps={{
                                                        className: "formHelperClass"
                                                    }} />}
                                                    onChange={(_event, newValue) => {
                                                        setFieldValue('country', newValue)
                                                        setFieldValue('region','')
                                                        setFieldValue('town','')
                                                    }}
                                                />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                <AutocompleteCustom
                                                    popupIcon={<KeyboardArrowDownIcon />}
                                                    disableClearable
                                                    id="combo-box-demo"
                                                    data-test-id="RegionData"
                                                    value={values.region}
                                                    options={this.getStateArray(values.country?.isoCode)?.map((options) => options.value)}
                                                    getOptionLabel={(option:unknown) =>  (option as Option).name}
                                                    renderInput={(params) => <StyledTextField  placeholder="Region" {...params} variant="outlined" FormHelperTextProps={{
                                                        className: "formHelperClass"
                                                    }} />}
                                                    onChange={(_event, newValue) => {
                                                        setFieldValue('region', newValue)
                                                        setFieldValue('town','')
                                                    }}
                                                    disabled={!this.getStateArray(values.country?.isoCode)?.length}
                                                />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <AutocompleteCustom
                                                        popupIcon={<KeyboardArrowDownIcon />}
                                                        disableClearable
                                                        id="combo-box-demo"
                                                        data-test-id="TownData"
                                                        options={this.getCityArray(values.country?.isoCode, values.region?.isoCode)?.map((options) => options.value)}
                                                        value={values.town}
                                                        getOptionLabel={(option:unknown) =>  (option as Option).name}
                                                        renderInput={(params) => <StyledTextField  placeholder="Town"{...params} variant="outlined" FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }} />}
                                                        onChange={(_event, newValue) => {
                                                            setFieldValue('town', newValue)
                                                        }}
                                                        disabled={!this.getCityArray(values.country?.isoCode, values.region?.isoCode)?.length}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {this.isCreative &&
                                            <Grid style={{ marginTop: 3, marginBottom : 3, paddingTop: 2, display : "flex", alignItems : "center" }} container spacing={1}>
                                                <Grid item >
                                                    <Typography style={{ lineHeight: 3, fontSize: '13px', color: "black", fontFamily: "Silika-Medium", whiteSpace: 'nowrap' }}>
                                                        Trading via limited/umbrella company
                                                    </Typography>
                                                </Grid>
                                                <Grid item> <IOSSwitch
                                                    data-test-id='companySwitch'
                                                    onChange={(event) => {
                                                        setFieldValue("tradingAddress", event.target.checked);
                                                    }} color={'default'}
                                                    checked={values.tradingAddress}
                                                     /></Grid>
                                            </Grid>
                                            }
                                            {this.renderCompanyDetails(values.tradingAddress, values.companyName, values.companyNumber, values.companyAddress, values.vatNumber, handleBlur, handleChange)}
                                            <Grid container spacing={2} style={{ textAlign: 'center', display:"none" }}>
                                            <Grid item xs={12} lg={6} md={6}>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"company_trading_name"}
                                                        data-test-id='company_trading_name'
                                                        value={values.company_trading_name}
                                                        placeholder="Trading name (if applicable)"
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="company_trading_name" />}
                                                        inputProps={{ maxLength: 30, style: { height: 10 }, className:"customTextField" }}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                                </Grid>
                                            <StyledButtonFloat
                                                data-test-id='submitBtn'
                                                style={{ ...webStyle.logInButton, marginTop: "36px", textTransform: 'capitalize' }}
                                                variant="outlined"
                                                type="submit"
                                            >
                                                Next
                                            </StyledButtonFloat>
                                        </form>
                                    )
                                }}
                            </Formik>
                        </Box>
                    </LoginSignUp>
                </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}