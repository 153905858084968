import React, { useState } from 'react'
import { Box, Button as MuiButton, createTheme, Divider, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { Collapse } from '@mui/material';
const theme = createTheme({
    typography: {
        fontFamily: "Silka",
    },
});
const NextActionSection = styled(Box)({
    "& .boxStyle": {
        padding: "41px",
        background: "#FFFFFF",
        border: "1px solid #DFDFDF",
        borderRadius: "4px 0px 0px 0px",
        boxShadow: "0px 10px 25px 0px #00000017"
    },
    "& .heading": {
        fontFamily: "silika-Medium",
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        color: "#000000",
        paddingBottom: "30px"
    },
    "& .lineStyle": {
        margin: "10px 0px",
    },
    "& .spanStyle": {
        background: "#FF5959",
        padding: "2px 8px",
        borderRadius: "50%",
        width: "24px",
        color: "#fff",
        fontSize: "16px"
    }
});
const CloseProjectSection = styled(Box)({
    marginTop: "90px",
    border: '1px solid #DFDFDF',
    borderRadius: "4px",
    "& .projectHead": {
        paddingLeft: "41px",
        paddingRight: "41px",
        height: "61px",
        borderRadius: "4px 4px 0px 0px",
        background: '#DFDFDF',
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "silika-Medium",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "19.2px",
        color: "#000000",
    }
})
const OpenProjectSection = styled(Box)({
    marginTop: "90px",
    border: '1px solid #DFDFDF',
    borderRadius: "4px",
    "& .projectHead": {
        paddingLeft: "41px",
        paddingRight: "41px",
        height: "61px",
        borderRadius: "4px 4px 0px 0px",
        background: '#DFDFDF',
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "silika-Medium",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "19.2px",
        color: "#000000",
    }
})
const Button = styled(MuiButton)({
    width: "175px",
    height: "32px",
    padding: "16px",
    borderRadius: "2px",
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    fontFamily: "silika-Medium",
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: "#0D0C22",
        boxShadow: 'none',
    },
});
function createData(
    Project: string,
    Owner: string,
    Role: string,
    Deliverable: string,
    Action: string,
    Actionee: string,
    Due: string,

) {
    return { Project, Owner, Role, Deliverable, Action, Actionee, Due };
}
function createData1(
    name: string
) {
    return { name }
}
const rows = [
    createData('0001 Truckers', 'APEX', 'Exterior Designer', '01 Car circuits', 'Make Payment', 'Joel G.', '5 Days'),
    createData('0002 Van O5', 'APEX', 'CMF Designer', '02 Car circuits', 'Approve / Reject', 'Martha S.', '2 Days'),
    createData('0003 Van O5', 'APEX', 'Interior Designer', '03 Car circuits', 'Make Payment', 'Joan W.', '-245 Days'),
];
const openProject = [
    createData1("0001 Truckers"),
    createData1("0002 Van 05"),
    createData1("0003 Truckers"),
    createData1("0004 Van O5"),
    createData1("0005 Truckers"),
]

interface DashboradProjectProps {
    navigateToDetailSection: () => void;
}
export default function DashboradProject(props: DashboradProjectProps) {

    const [openCollapsibleTable, setOpenCollapsibleTable] = useState<{ [key: string]: boolean }>({})

    return (
        <div>
            <NextActionSection>
                <Box className='boxStyle'>
                    <Typography className='heading'>Next actions <span className='spanStyle'>3</span></Typography>
                    <Divider className='lineStyle'></Divider>
                    <TableContainer component={Paper} elevation={0}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium" }}>Project</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium" }} align="left">Owner</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium" }} align="left">Role</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium" }} align="left">Deliverable</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium" }} align="left">Action</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium" }} align="left">Actionee</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium" }} align="left">Due</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow>
                                        <TableCell align='left'>{row.Project} </TableCell>
                                        <TableCell align='left' >
                                            <div style={{ display: "flex", alignItems: "center", gap: '10px' }}>
                                                <img src='https://png.pngtree.com/png-clipart/20190614/original/pngtree-background-material-design-for-lorem-ipsum-logo-png-image_3624673.jpg' height={32} width={32} />
                                                <span>{row.Owner}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div style={{ display: "flex", alignItems: "center", gap: '10px' }}>
                                                <img src='https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png' height={32} width={32} />
                                                <span>{row.Role}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">{row.Deliverable}</TableCell>
                                        <TableCell align="left"><Button>{row.Action}</Button></TableCell>
                                        <TableCell align="left">{row.Actionee}</TableCell>
                                        <TableCell align="left"><DueTextField dueDate={row.Due} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </NextActionSection>

            <OpenProjectSection>
                <Box>
                    <Box className='projectHead'> <Typography>Open Projects</Typography> <AddIcon style={{ fontSize: "36px" }} /></Box>
                    <Box style={{ padding: '41px 50px', fontSize: "16px", fontWeight: 'bold' }}>
                        <TableContainer component={Paper} elevation={0} >
                            <Table aria-label="simple table">
                                <TableBody>
                                    {openProject.map((open, indice) => (
                                        <>
                                            <TableRow style={{
                                                width: "100%", cursor: "pointer", backgroundColor: openCollapsibleTable[indice] ? "#DFDFDF" : "#fff"
                                            }} onClick={() => {
                                                setOpenCollapsibleTable(prevState => ({ ...prevState, [indice]: !prevState[indice] }))
                                            }}>
                                                <TableCell style={{ fontSize: "16px", fontWeight: 500, color: "#9B9B9B", width: "100%" }}>
                                                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", alignItems: "center", color: "#000000" }}><FolderOpenOutlinedIcon />&nbsp;&nbsp;&nbsp;&nbsp;{open.name} </div>
                                                        <div style={{ display: "flex", alignItems: "center", color: "#000000", zIndex: 999 }} onClick={props.navigateToDetailSection}><p>Details</p> <ArrowForwardIosIcon /></div></Box>
                                                </TableCell>
                                            </TableRow>
                                            <CollapsibleTable isOpen={openCollapsibleTable[indice]} />
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </OpenProjectSection>

            <CloseProjectSection>
                <Box>
                    <Box className='projectHead'> <Typography>Closed Projects</Typography></Box>
                    <Box style={{ padding: '41px 50px', fontSize: "16px", fontWeight: 'bold' }}>
                        <TableContainer component={Paper} elevation={0} >
                            <Table aria-label="simple table">
                                <TableBody>
                                    {openProject.map((open) => (
                                        <TableRow style={{ width: "100%" }}>
                                            <TableCell style={{ fontSize: "16px", fontWeight: 500, color: "#9B9B9B", width: "100%" }}>
                                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex", alignItems: "center" }}><FolderOpenOutlinedIcon />&nbsp;&nbsp;&nbsp;&nbsp;{open.name} </div>
                                                    <div style={{ display: "flex", alignItems: "center", zIndex: 999 }} onClick={props.navigateToDetailSection}><p>Details</p> <ArrowForwardIosIcon /></div></Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </CloseProjectSection>
        </div>
    )
}


const CollapsibleTable = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Box sx={{ padding: "30px" }}>
                        <Typography style={{
                            fontSize: "26px",
                            fontWeight: 500,
                            lineHeight: "31.2px",
                            letterSpacing: "-0.022em",
                        }}>
                            0001 Truckers
                        </Typography>

                        <Box
                            style={{ height: "340px", overflow: "scroll" }}>
                            {CollapsibleTableData.length ?
                                <InnerTableSection disabled={true} />
                                :
                                <Box display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    style={{ height: "100%" }}
                                >

                                    <Typography
                                        style={{
                                            fontSize: "32px",
                                            fontWeight: 500,
                                            lineHeight: "38.4px",
                                            textAlign: "center"
                                        }}>No details to show in this projects.</Typography>
                                </Box>
                            }
                        </Box>

                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

const HeaderSection = ({ data }: { data: { title: string, value: string, disabled: boolean } }) => {
    return (
        <Box display={"flex"} flexDirection={"row"} style={{ marginRight: "40px" }}>
            <Typography style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "19.36px",
                color: "#9B9B9B",
                marginRight: "10px"
            }}>
                {data.title}
            </Typography>
            <Typography style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "19.36px",
                color: data.disabled ? "#9B9B9B" : "#000"
            }}>
                {data.value}
            </Typography>
        </Box>
    )
}

const TableHeader = ({ title, disabled }: { title: string, disabled: boolean }) => {
    return (<TableCell style={{ fontWeight: 'bold', fontSize: "16px", fontFamily: "silika-Medium", color: disabled ? "#9B9B9D" : "#000" }} align="left">{title}</TableCell>)
}

const DueTextField = ({ dueDate }: { dueDate: string }) => {
    const date = Number(dueDate.split(" ")[0]) || 0
    return (
        <p style={{ color: date < 0 ? "#FF5959" : "#000" }}>{dueDate}</p>
    )
}

const InnerTableSection = ({ disabled }: { disabled: boolean }) => {
    return (
        <>
            <Box display={"flex"} flexDirection={"row"} marginTop={3} style={{ borderBlock: "1px solid #DFDFDF", padding: "10px" }}>
                <HeaderSection data={{ title: "Dates:", value: "13 Mar 24 -25 Apr 24", disabled }} />
                <HeaderSection data={{ title: "Budget:", value: "£2,000", disabled }} />
            </Box>

            <Table size="small" aria-label="purchases" style={{ marginTop: "30px" }}>
                <TableContainer component={Paper} elevation={0}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableHeader title={"Role"} disabled={disabled} />
                                <TableHeader title={"Deliverable"} disabled={disabled} />
                                <TableHeader title={"Status"} disabled={disabled} />
                                <TableHeader title={"Action"} disabled={disabled} />
                                <TableHeader title={"Actionee"} disabled={disabled} />
                                <TableHeader title={"Due"} disabled={disabled} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {CollapsibleTableData.map((row) => (
                                <TableRow>
                                    <TableCell align='left'>{row.Role} </TableCell>
                                    <TableCell align="left">{row.Deliverable}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            style={{
                                                background: row.Status === "Paid" ? "#FEF3C7" : "#DFDFDF",
                                                color: row.Status === "Paid" ? "#D97706" : "#000",
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                width: "90%"
                                            }}
                                        >
                                            {row.Status}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left"><Button>{row.Action}</Button></TableCell>
                                    <TableCell align="left">
                                        <div style={{ display: "flex", alignItems: "center", gap: '10px' }}>
                                            <img src='https://w7.pngwing.com/pngs/831/88/png-transparent-user-profile-computer-icons-user-interface-mystique-miscellaneous-user-interface-design-smile-thumbnail.png' height={32} width={32} />
                                            <span>{row.Actionee}</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="left"><DueTextField dueDate={row.Due} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Table>
        </>
    )
}

const CollapsibleTableData = [
    {
        "Role": "Designer",
        "Deliverable": "Project Alpha",
        "Status": "Invoiced awaiting payment",
        "Action": "Make Payment",
        "Actionee": "Alice R.",
        "Due": "5 Days"
    },
    {
        "Role": "CAD Modeller",
        "Deliverable": "Project Beta",
        "Status": "Submitted for Approval",
        "Action": "Approve / Reject",
        "Actionee": "Bob T.",
        "Due": "2 Days"
    },
    {
        "Role": "CGI Artist",
        "Deliverable": "Project Gamma",
        "Status": "Paid",
        "Action": "Make Payment",
        "Actionee": "Carol M.",
        "Due": "-245 Days"
    },
    {
        "Role": "Animator",
        "Deliverable": "Project Delta",
        "Status": "In Progress",
        "Action": "Approve / Reject",
        "Actionee": "Dan P.",
        "Due": "3 Days"
    },
    {
        "Role": "Architect",
        "Deliverable": "Project Epsilon",
        "Status": "Pending Invoice",
        "Action": "Make Payment",
        "Actionee": "Emily L.",
        "Due": "1 Day"
    },
    {
        "Role": "UI Designer",
        "Deliverable": "Project Zeta",
        "Status": "Approved",
        "Action": "Make Payment",
        "Actionee": "Frank K.",
        "Due": "7 Days"
    }
]