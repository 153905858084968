import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import { Box, Button, Container, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import {restricted} from "./assets"
import HeaderNavbar from "../../../components/src/Navbar";
// Customizable Area End

import LandingPage6Controller, { Props } from "./LandingPage6Controller";
import SearchIcon from '@mui/icons-material/Search';
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Silka",
  },
});
const StyledContainer = styled(Box)({
  "& .parent": {
    disply: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    padding:"80px",
    [theme.breakpoints.down('sm')] :{
      width: "100%",
      padding: "24px"
    }
  },
  "& .headding": {
    marginBottom: 95,
    fontFamily: "silika-Medium",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    fontWeight: 500,
    fontSize: "32px",
    [theme.breakpoints.down('sm')] :{
      marginBottom : 24,
      fontSize: "20px",
    }
  },
  "& .galleryContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(302px, 1fr))",
    gap: "24px",
    width: "100%",
    marginBottom : 24,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    },
    "@media (max-width: 599px) and (min-width: 400px)": {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))", 
    },
    "@media (max-width: 399px)": {
      gridTemplateColumns: "1fr", 
    },
  },
  "& .imageWrapper": {
    width: "100%", 
    paddingBottom: "77.7%", 
    position: "relative", 
    borderRadius: "4px",
    overflow: "hidden",
    cursor : "pointer",
    "&:hover":{
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06),0px 8px 12px rgba(0, 0, 0, 0.03), 0px 12px 16px rgba(0, 0, 0, 0.09)",
      transform: "scale(1.05)"
    }
  },
  "& .image": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover", 
  },
  "& .footer": {
    marginTop : "40px"
  },
  "& .loadMoreBtn" : {
    width: "360px",
    height: "48px",
    textTransform: "none",
    border: "1px solid black",
    padding: "16px",
    borderRadius: "2px",
    fontFamily :"Silika-Medium",
    fontWeight : 500,
    fontSize:"16px",
    lineHeight:"19.2px",
    letterSpacing  :"-0.15px",
    "&:hover":{
      backgroundColor  :"black",
      color : "white"
    },
    "@media (max-width: 399px)": {
      width : "100%",
    },
    "& .loadingContainer":{
      height:"48px",
      width  :"100%",
      alignItem:"center",
      marginTop:40,
      border : "1px solid red"
     },
  },
  "& .restrictedDivMain": {
    position: "absolute",
    top: 0,
    left:0,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    zIndex : 2
  },
  "& .restrictedDiv": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .restrictedImg": {
    width: "24px",
    height: "24px",
  },
  "& .confidentialPara": {
    color: "#FFFFFF",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    margin: 0,
    maxWidth: "220px",
    fontSize: "14px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .confidential_img":{
    filter: "blur(4px)"
  },
  "& .confidentialText": {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: 500,
    marginLeft: "4px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  }
});
const StyledInput = styled('div')({
  marginBottom:"90px",
  "& .MuiOutlinedInput-root": {
    height: "48px",
    borderColor: "transparent !important",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
      border: "1px solid #DFDFDF !important",
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    border: "1px solid #DFDFDF !important",
  }
 
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor: "none !important",
  },
  "& .MuiInputBase-input":{
    fontFamily: "Silika-Medium",
    fontSize:"16px",
    fontWeight: 500,
    width:"97% !important",
    border: "transparent!important",
    "&::placeholder": {
      fontWeight: 500,
      color: "#010101 !important",
      fontFamily: "Silika-Medium",
      fontSize:"16px",
      opacity:"0.9"
    },
    "@media (max-width:680px)": {
      width: "80% !important",
    },
   
  },
  "& .MuiFormControl-root":{
    border: "transparent !important",
  }
});
// Customizable Area End

export default class LandingPage6Web extends LandingPage6Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  loadMoreBtn =()=>{
    return(
     this.state.images.length > 0 && <Grid container className="loadingContainer">
     <Grid item style={webStyle.loadingDiv}>
       <Grid item  style={{justifyContent:"center",display:"flex", width:"100%"}}>
         <Button className="loadMoreBtn"  onClick={this.handleLoadMore}>
         Load More </Button> 
       </Grid>
     </Grid>
   </Grid> 
    )
   }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const images = this.state.images;
    const searchImage = this.state.searchImage;
    const searchMassege = this.state.searchMassege;
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
          {localStorage.getItem("token")?<HeaderNavbar navigation={this.props.navigation}/>:<LandingHeader navigation={this.props.navigation} />}
          <StyledContainer>
            <Container maxWidth="xl" className="parent">
              <Typography className="headding">Gallery</Typography>
            <StyledInput>
              <TextField
                id="adSearchText"
                type="text"
                variant="outlined"
                value={this.state.searchValue}
                placeholder="Owner name, Title , Description ."
                onChange={(event) => this.searchHandle(event.target.value)}
                style={webStyle.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon/>
                    </InputAdornment>
                  ),
                }}
              />
            </StyledInput>
            <div>
              {searchMassege == "" ?
                <div className="galleryContainer">
                  {
                    (searchImage.length > 0 ? searchImage : images)?.map((item, index) => (
                      item.attributes.images.length > 0 &&
                      <div key={index} data-test-id="mediaImage" onClick={() => this.handleImageNavigation(item.id, item.attributes.confidential)} className="imageWrapper">
                        {item.attributes.confidential &&
                          <div className="restrictedDivMain">
                            <div className="restrictedDiv">
                              <img
                                className="restrictedImg"
                                data-test-id="restrictedImgDiv"
                                src={restricted}
                              />
                              <p className="confidentialText">Confidential</p>
                            </div>
                            <p className="confidentialPara">
                              You have to be a collaborator to view this
                              content.
                            </p>
                          </div>
                        }
                        {item.attributes.images[0]?.type === "image" ?
                          <img
                            src={item.attributes.images[0]?.url}
                            alt="image"
                            className={`image ${item.attributes.confidential ? "confidential_img":""}`}
                          />
                          :
                          <video
                            src={item.attributes.images[0]?.url}
                            className="image"
                            autoPlay
                            playsInline
                          />
                        }
                      </div>
                    ))
                  }
                </div>
                : <div style={{ textAlign: "center" }}>
                  <span style={{color:"#000000",fontSize:"32px"}}>{searchMassege}</span><br/><br/>
                  <span style={{fontSize:"16px",color:"#9B9B9D"}}>Unfortunately no results meet these <br /> search criteria</span>
                </div>
                }
              </div>
              {this.state.isLoadingMore && <p>Loading ...</p>}
              {!this.state.isLogedIn && this.loadMoreBtn()}
            </Container>
          </StyledContainer>
          <LandingFooter  navigation={this.props.navigation} />
      </ThemeProvider>
      // Customizable Area End  
    );
  }
}
// Customizable Area Start\
const webStyle={
   loadingDiv:{
    height:"48px",
    width : "100%",
    alignContent: "center",
    display: "flex",
    justifyContent:"space-between",
    marginLeft:"auto"
   },
  loadSpinner:{
    width:"20px",
    height:"20px",
    color:"black",
    marginLeft:"20px"
  },
  input: {
    width: "100%",
    heigth: "40px",
    alignItem: "center",
    border:"1px solid #DFDFDF",
    borderRadius:"2px"
  },
}
// Customizable Area End
