import React, { Component } from "react";
import { Box, Button, Grid, Typography, styled, WithWidth, IconButton, isWidthDown, withWidth, Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@mui/icons-material/Close';
const imgLogo = require("./image_logo.svg");

interface Props extends WithWidth {
  navigation?: {};
}

interface S {
  isDrawerOpen: boolean,
}

const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  "& .logoForge": {
    Width: "19.41px",
    Height: "25.67px",
  },
  "& .headLeftDiv": {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    height: "30px",
    marginTop: "20px",
    marginBottom: "20px",
    width: "auto",
    flexShrink: 1
  },
  "& .linkText": {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "18.1px",
  },
  "& .headRightDiv": {
    display: "flex",
    alignItems: "center",
    height: "30px",
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "flex-end"
  },
  "& .headContainer": {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "34px",
    marginLeft: "34px",
  },
  "& .loginBtn": {
    border: "1px solid grey",
    textTransform: "none",
    width: "55px",
    fontSize: "14px",
    fontWeight: 500,
    height: "30px",
    borderRadius: "2px",
    fontFamily: "Silika-Medium"
  },
  "& .signUpBtn": {
    textTransform: "none",
    backgroundColor: "#0D0C22",
    color: "white",
    width: "78px",
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: "20px",
    height: "30px",
    borderRadius: "2px",
    fontFamily: "Silika-Medium"
  }
});

class LandingHeader extends Component<Props, S> {
  constructor(props: any) {
    super(props);

    this.state = {
      isDrawerOpen: false,
    };
  }

  handleNavigation = (screenName: string) => {

    if (screenName.length > 0) {
      console.log("screen name", screenName);
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      runEngine.sendMessage(message.id, message);
    }
  }

  handleisDrawerOpen = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen
    });
  };

  render() {
    const { width } = this.props;
    const isMobile = isWidthDown("sm", width);
    const isGalleryRoute = location.pathname === "/Gallery";
    return (
      <StyledContainer>
        <Grid container className="headContainer">
          <Grid item xl={4} lg={6} md={6} sm={8} className="headLeftDiv">
            <Box
              style={webStyle.logoContainer}
            >
              {
                isMobile ? <IconButton onClick={this.handleisDrawerOpen} style={webStyle.menuButton}><MenuIcon /></IconButton> : <></>
              }
              <img
                src={imgLogo}
                alt="logo"
                style={webStyle.logoImage}
                onClick={()=>this.handleNavigation("LandingPageWeb")}
              />
              {!isMobile ? <div style={webStyle.leftMenu}>
                <Typography onClick={() => this.handleNavigation("LandingPageWeb")} style={webStyle.discoverHyperLink}>
                  For Creatives
                </Typography>
                <Typography onClick={() => this.handleNavigation("LandingPageWeb6")}
                  style={{
                    ...webStyle.discoverHyperLink,
                    fontWeight : isGalleryRoute? "bold" : "normal"
                  }}>
                  Gallery
                </Typography>
                <Typography onClick={() => this.handleNavigation("Pricing")} style={webStyle.discoverHyperLink}>
                  Pricing
                </Typography>
              </div> : <></>}
            </Box>
            <Drawer open={this.state.isDrawerOpen} onClose={this.handleisDrawerOpen}>
              <List>
                <Box
                  style={{...webStyle.logoContainer, marginLeft : "16px"}}
                >
                  <IconButton onClick={this.handleisDrawerOpen} style={webStyle.menuButton}><CloseIcon /></IconButton>
                  <img
                    alt="logo"
                    src={imgLogo}
                    style={webStyle.logoImage}
                  />
                </Box>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("")} style={webStyle.discoverHyperLink}>
                      For Creatives
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("LandingPageWeb6")} style={{...webStyle.discoverHyperLink, fontWeight : isGalleryRoute? "bold" : "normal"}}>
                      Gallery
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("")} style={webStyle.discoverHyperLink}>
                      Pricing
                    </div>
                  </ListItemText>
                </ListItem>
              </List>
            </Drawer>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={4} className="headRightDiv">
            <Button
              className="loginBtn"
              onClick={() => this.handleNavigation("AccountLogin")}
            >
              Log In
            </Button>
            <Button
              className="signUpBtn"
              onClick={() => this.handleNavigation("SignUpThanks")}
            >
              Sign up
            </Button>
          </Grid>

        </Grid>
      </StyledContainer>
    );
  }
}


const webStyle = {
  menuButton: {
    padding: "4px",
    marginRight: "17px"
  },
  logoContainer: {
    width: "67px",
    display: "flex",
    alignItems: "center",
  },
  logoImage: {
    width: "19.412px",
    height: "25.674px",
    flexGrow: 0,
    alignSelf: "center",
    marginRight: 45,
  },
  discoverHyperLink: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "9px 20px 9px 16px",
    gap: "8px",
    cursor: 'pointer'
  },
  leftMenu: {
    display: "flex",
  }
};

export default withWidth()(LandingHeader)