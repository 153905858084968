export const measureInternetSpeed = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      const testUrl = window.location.href;
      const startTime = performance.now();
     
      fetch(testUrl)
        .then(() => {
          const endTime = performance.now();
          const speed = endTime - startTime; 
          resolve(speed);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };