import React from "react";
import {Favourite, Profile, FavouriteTabsEventsProps} from "../types";
import NoFavourites from "./NoFavourites";
import {CircularProgress, Avatar, IconButton, Grid, styled, Theme, Card, CardMedia, CardActions, CardContent, Typography} from "@material-ui/core"
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { AvatarGroup } from "@material-ui/lab";
import ShareIcon from '@material-ui/icons/Share';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { ShareLinkDialog, ShareMenuDialog } from "../../../../components/src/ShareDialogs.web";
import dayjs from "dayjs";
import utcTime from 'dayjs/plugin/utc';
dayjs.extend(utcTime);

const LoadMoreDiv = styled("div")({
    border: "1px solid rgba(0, 0, 0, 0.87)",
    borderRadius: "4px",
    padding: "16px",
    marginTop: "12px",
    textAlign: "center",
    cursor: "pointer",
    "& .isLoading":{
        height: "16px !important",
        width: "16px !important",
        marginLeft: "16px",
        "& svg":{
            color: "rgba(0, 0, 0, 0.87)"
        }
    }
});

const StyledEventsContainer = styled(Grid)(({theme}: {theme: Theme}) => ({
    marginTop: "95px",
    "& .MuiPaper-elevation1":{
        boxShadow: "none",
        border: "1px solid #DFDFDF",
        borderRadius: "4px",
        height: "100%",
        display: "flex",
        flexFlow: "column"
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: "24px"
    },
    "& .eventCard":{
        [theme.breakpoints.down('sm')]: {
            margin: "0px 25px 32px 25px",
            padding: "0px",
        }
    },
    "& .media":{
        height: "279px",
        position: "relative"
    },
    "& .status":{
        position: "absolute",
        height: "24px",
        background: "#0D0C22",
        borderRadius: "100px",
        right: "20px",
        top: "20px",
        padding: "4px 12px",
        "& p":{
            fontSize: "16px",
            color: "#fff",
            [theme.breakpoints.down('sm')]: {
                fontSize: "13px",
            }
        }
    },
    "& .eventTitle":{
        fontSize: "20px",
        lineHeight: "24px",
        marginBottom: "6px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
            lineHeight: "19px"
        }
    },
    "& .eventPostingTime":{
        fontSize: "16px",
        lineHeight: "19.2px",
        marginBottom: "6px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "13px",
            lineHeight: "15px"
        }
    },
    "& .location":{
        display: "inline-flex",
        alignItems: "center",
        fontSize: "12px",
        "& svg":{
            height: "20px",
            width: "20px"
        },
        "& span":{
            marginLeft: "4px"
        }
    },
    "& .map":{
        "& path":{
            fill: "#fff",
            stroke: "#010101"
        }
    },
    "& .actions":{
        justifyContent: "space-between",
        padding: "0 8px 8px",
        alignItems: "inherit",
        marginTop: "auto",
        marginBottom: "0px",
        minHeight: "74px"
    },
    "& .actionBtns":{
        display: "inline-flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        "& button":{
            padding: "5px",
            height: "34px"
        }
    },
    "& .network":{
        display: "flex",
        flexFlow: "column",
        paddingRight: "12px",
        paddingBottom: "12px",
        paddingTop: "4px",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "end"
        },
        "& .MuiAvatarGroup-root":{
            justifyContent: "flex-end"
        },
        "& .MuiAvatarGroup-avatar":{
            border: "none !important",
            [theme.breakpoints.down('sm')]: {
                height: "23px",
                width: "23px"
            }
        },
        "& p":{
            fontSize: "12px",
            color: "#9B9B9D",
            fontWeight: 300,
            [theme.breakpoints.down('sm')]: {
                fontSize: "10px"
            }
        }
    }
}));

class FavouriteEventsList extends React.Component<FavouriteTabsEventsProps> {

    constructor(props: FavouriteTabsEventsProps){
        super(props);
    }    

    renderEvent = (favourite: Favourite) => {
        const userId = sessionStorage.getItem("profileId");
        const favouriteable = favourite.attributes.favouriteable.data;
        const attendees = favourite.attributes.favouriteable.data.attributes.attendees.data;
        const attending = attendees.findIndex((data: Profile) => data.id === userId);
        const isOwner = favouriteable.attributes.account.data.id === userId;
        const renderAttendee = () => {
            if(isOwner){
                return <div className="status"><Typography>Owner</Typography></div>
            }else if(attending !== -1){
                return <div className="status"><Typography>Attending</Typography></div>
            }
        }

        const renderLocation = () => {
            if(favouriteable.attributes.is_in_person === "in_person" || favouriteable.attributes.is_in_person === "both"){
                return <div className="location"><Typography variant="body2">Online</Typography></div>
            }else{
                return <div className="location">
                <LocationOnIcon className="map"/> 
                <span>{favouriteable.attributes.country}, {favouriteable.attributes.county}</span>
            </div>
            }
        }

        return (
            <Grid item xs={12} sm={12} md={4} lg={4} data-test-id="eventCard" className="eventCard" onClick={() => this.props.onNavigateToDetailsPage(favouriteable.id)}>
            <Card> 
                <CardMedia
                    className="media"
                    image={favouriteable.attributes.banner_image}
                    title="Paella dish"
                >
                    {renderAttendee()}
                </CardMedia>
                <CardContent>
                    <Typography className="eventTitle">{favouriteable.attributes.event_name}</Typography>
                    <Typography className="eventPostingTime">{dayjs(favouriteable.attributes.start_date).format('ddd, D MMM')}, {favouriteable.attributes.start_time !== null && <>{dayjs(favouriteable.attributes.start_date).utc().format('h:mm a')}</>} (GMT)</Typography>
                    {renderLocation()}
                </CardContent>
                <CardActions className="actions">
                    <div className="actionBtns">
                        <IconButton data-test-id="shareEvent" onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.props.onClickShareButton(event, favourite.id)}><ShareIcon/></IconButton>
                        <IconButton data-test-id="deleteFavEvent" onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.props.onClickDeleteFavourite(event, favourite.id)}><BookmarkIcon/></IconButton>
                    </div>
                    <div className="network">
                        <AvatarGroup max={4}>
                            {attendees.slice(0, 4).map((data: Profile) => <Avatar alt={data.attributes.first_name} src={data.attributes.photo} />) }
                        </AvatarGroup>
                        <Typography>{attendees.length > 0 ? `${attendees.length} from your network`: ""}</Typography>
                    </div>
                </CardActions>
            </Card>
        </Grid>
        )
    }

    renderEvents = () => {
    const { favourites, count } = this.props;
    if(favourites.length > 0){
        return <StyledEventsContainer container spacing={5} data-test-id="favouriteEvents">
        {favourites.slice(0, count).map((favourite: Favourite) => this.renderEvent(favourite))}
        </StyledEventsContainer> 
    }
    return <NoFavourites data-test-id="noFavourites" title="You don’t have any favourite events yet"/>;
    }

    render(){
        const {needLoadMoreBtn, isLoading, favourites, count} = this.props;
        const isLoadMoreVisible =  needLoadMoreBtn && (count < favourites.length); 
        return (
            <>
            {this.renderEvents()}
            {isLoadMoreVisible ? <LoadMoreDiv data-test-id="loadMore" onClick={() => {
                this.props.onLoadMoreClick();
            }}>
                {isLoading ? <>Loading <CircularProgress className="isLoading"/></>: "Load More"}
            </LoadMoreDiv> : <></>}
            <ShareLinkDialog isChatSend={this.props.isChatSend} shareLink={this.props.shareLink} data-test-id="shareLinkDialog" onClickChatButton={this.props.onClickChatButton} onClose={this.props.onClose} isOpen={this.props.isShareModalOpen} />
            <ShareMenuDialog onClickBackButton={this.props.onClickBackButton} onClose={this.props.onClose} onClickSendChat={this.props.onClickSendChat} isOpen={this.props.isChatModalOpen} profiles={this.props.profiles} shareData={this.props.shareData} chatMessage={this.props.chatMessage} onChangeChatMessage={this.props.onChangeChatMessage} onShareDataChange={this.props.onShareDataChange} />
        </>
        )
    }
}

export default FavouriteEventsList;