Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";

exports.Category = "Category";
exports.Description = "Description";
exports.Price = "Price";
exports.ProductName = "Product Name";
exports.CreatePost = "Create Post";
exports.UpdatePost = "Update Post";
exports.Error = "Error";
exports.FieldsErrorMassage = "Please enter all mandatory fields";
exports.Warning = "Warning";
exports.MessageForDelete = "Are you sure for delete this post?";
exports.PostName = "Post Name";
exports.Cost = "Cost";
exports.PostID = "Post ID";
exports.Delete = "Delete";
exports.Edit = "Edit";
exports.NewPost = "Add new Post";
exports.PostUpdated = "Post is updated!";
exports.PostCreated = "Post is created!";
exports.GetTags = "bx_block_posts/posts/all_tags"
exports.ceatePost = "bx_block_posts/posts"
exports.getPostsList = "bx_block_posts/posts"
exports.getUserPostsList = "bx_block_posts/posts/user_post"
exports.getProfileEndPoint="bx_block_profile/profiles"
exports.addCollabarators = "bx_block_posts/posts/add_collaborator?post_id="
exports.getList = "account_block/accounts"
exports.addCollab = "bx_block_posts/posts/add_collaborator"
exports.listCollabs = "/bx_block_posts/posts/list_of_collaborators?post_id="
exports.deleteCollab = "/bx_block_posts/posts/remove_collaborator?"
exports.deletePostEndPoint = "bx_block_posts/posts"
exports.postDetailsEndPoint = "bx_block_posts/posts/"
exports.deletePostListEndPoint = "bx_block_posts/posts/"
exports.updatePostEndPoint = "bx_block_posts/posts/"
exports.getAllApiEndPoint = "/bx_block_like/likes";
exports.favouriteEventEndPont="bx_block_favourites/favourites";
exports.getAllSavedProfileEndPoint="bx_block_favourites/favourites?favouriteable_type=BxBlockProfile::Profile";
exports.likePostEndPoint="/bx_block_like_a_post/like_posts"
exports.alllikePostsEndPoint="/bx_block_like_a_post/like_posts"
exports.allCreatorPostEndPoint="/bx_block_posts/posts/collaborator_post?id=";
exports.visibleImageEndPoint = "bx_block_posts/posts/set_visible_option"

exports.getAllSavedPostEndPoint="/bx_block_favourites/favourites?favouriteable_type=BxBlockPosts::Post";
// Customizable Area End