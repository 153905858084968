import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CityInfo, Event, FavEvent, ICreateEvent,CountryInfo, DefaultCountry, DefaultCounty, DefaultTown, TownInfo  } from "./AllEventsController.web";
import { City, Country, ICity, ICountry, IState, State } from "country-state-city";
import dayjs from "dayjs";
import { UpdatedValues } from "../../../../packages/blocks/customisableuserprofiles/src/ProfileInfoController";

export interface OptionType {
  firstName: string;
  lastName: string;
  photo: string;
};

export interface Profile {
  getProfile: {
    attributes:ProfileAttributes
  };
  id: string;
  type: string;
  attributes: ProfileAttributes;
}

export interface ProfileAttributes {
  id: number;
  availability: string|null; 
  thumbs_up_count: number;
  visibility_option: string;
  country: string;
  county: string;
  city: string;
  postal_code: string;
  account_id: number;
  first_name: string;
  seniority_level: string;
  work_location: string;
  work: string[];
  hourly_rate: string; 
  collaborators: string[];
  tags: string[];
  description: string;
  photo: string;
  media: string|null; 
  unavailable_dates: string[];
  is_like: boolean;
  last_name: string;
  bio: string;
  skill: string[];
  phone_number: string;
  profession: string[];
  headline: string;
  company_name: string;
  company_number: string;
  registered_address: string;
  vat_number: string;
  dates: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    eventId:string;
    eventDetail:Event;
    searchText:string;
    anchorEl: null | HTMLElement;
    isOpenedEditEventPopUp: boolean;
    inPersonOnlineBoth: string;
    uploadedFile: File | null;
    uploadedFileUrl: string;
    validFile: boolean;
    startDate: Date;
    endDate: Date;
    editEventInitialValues: {
      eventName: string,
      description: string,
      inPerson: string,
      bannerImage: string|null,
      eventWeb: string,
      linkToMeet: string,
      country:CountryInfo ,
      town:TownInfo,
      address: string,
      postcode: string,
      startDate: string,
      startTime: string|object,
      endDate: string,
      endTime: string|object,
      county: CityInfo;
      timeZone:string;
    },
    isOpenedDeleteEventPopUp: boolean;
    favEventList:FavEvent[];
    isCopied:boolean|null;
    msgSend: boolean;
    openModel:boolean;
    isSendLinkToChatModalOpen:boolean;
    linkShare:string;
    shareData:OptionType[],
    sendChatMessage:string,
    getAllData:Profile[],
    allValue:UpdatedValues,
    country:CountryInfo,
    county:CityInfo,
    cover:string,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class EventViewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    eventDetailApiCallId: string | null = "";
    eventEditApiCallId: string | null = "";
    eventDeleteApiCallId: string | null = "";
    eventAttendApiCallId: string | null = "";
    eventFavouriteApiCallId: string | null = "";
    favouriteEventApiId: string | null="";
    getAllDataProfileApiCallId: string | null="";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            eventId:"",
            eventDetail:{} as Event,
            searchText:"",
            anchorEl: null,
            isOpenedEditEventPopUp: false,
            inPersonOnlineBoth: "",
            uploadedFile: null,
            uploadedFileUrl: "",
            validFile: true,
            startDate: new Date(),
            endDate: new Date(),
            editEventInitialValues: {
            eventName: "",
            description: "",
            inPerson: "",
            bannerImage: null,
            eventWeb: "",
            linkToMeet: "",
            country: DefaultCountry,
            town: DefaultTown,
            address: "",
            postcode: "",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            county: DefaultCounty,
            timeZone:""
            },
            isOpenedDeleteEventPopUp:false,
            favEventList:[],
            openModel: false,
            isCopied:null,
            msgSend:false,
            isSendLinkToChatModalOpen:false,
            linkShare:"",
            shareData: [],
            sendChatMessage:'',
            getAllData: [],
            allValue:{
              FirstName: "",
              LastName: "",
              Profession: [],
              Seniority: "",
              Headline: "",
              Country: {} as CountryInfo, 
              Region: {} as CityInfo, 
              Town: {} as CityInfo,
              PreferredWork: [], 
              image: "",
              imageError:"",
            },
            country:{} as CountryInfo,
            county:{} as CityInfo,
            cover:""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        if (!sessionStorage.getItem("accountEmail")){
          this.navigateToLoginPage();        
        }
        if (sessionStorage.getItem("eventDetailId")) {
            this.handleEventDetail(this.getIdURL()? parseInt(this.getIdURL() as string):parseInt(sessionStorage.getItem("eventDetailId") as string))
        }
        this.handleFavouriteListEvent();
        if(window.location.href.includes("=")){
        this.handleEventDetail(parseInt(this.getIdURL() as string));}
       // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            runEngine.debugLog("TOKEN", token);
            this.setState({ token: token });
        }
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const data = message.getData(
              getName(MessageEnum.NavigationPayLoadMessage)
            );   
            sessionStorage.setItem("eventDetailId",data)
            this.setState({eventId:data},()=>{this.handleEventDetail(parseInt(this.state.eventId))})
          }

      const responseType = getName(MessageEnum.RestAPIResponceMessage);

      if (responseType === message.id) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        switch (apiRequestCallId) {
          case this.eventDetailApiCallId:
            this.handleDetailEventsResponse(responseJson.data, errorResponse);
            break;

          case this.eventEditApiCallId:
              this.handleEditEventsResponse(responseJson.data, errorResponse);
              break;
          
          case this.eventDeleteApiCallId:
              this.handleDeleteEventsResponse(responseJson.message, errorResponse);
              break;

          case this.eventAttendApiCallId:
                this.handleAttendEventsResponse(responseJson.data, errorResponse);
                break;
          
          case this.favouriteEventApiId:
                  this.handlefavEventsResponse(responseJson.data, errorResponse);
                  break;

          case this.eventFavouriteApiCallId:
                this.handleEventfavEventsResponse(responseJson.data, errorResponse);
                break;

          case this.getAllDataProfileApiCallId:
                this.handleProfileResponse(responseJson.data, errorResponse);
                break;

          default:
            break;
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start

    handleDetailEventsResponse = (responseJson:Event,errorReponse:object)=> {
      if (responseJson && !errorReponse) {
        this.setState({ eventDetail: responseJson});
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    handleEditEventsResponse= (responseJson:Event,errorReponse:object)=> {
      if (responseJson && !errorReponse) {
        this.setState({ eventDetail: responseJson,isOpenedEditEventPopUp:false});
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    handleDeleteEventsResponse =(responseJson:string,errorReponse:object)=> {
      if (responseJson && !errorReponse) {
        this.setState({isOpenedDeleteEventPopUp:false});
        this.navigateToListEventPage();
        sessionStorage.setItem("eventDetailId","");
      }
    }

    handleAttendEventsResponse =(responseJson:object,errorReponse:object)=> {
      if (responseJson && !errorReponse) {
        this.handleEventDetail(parseInt(this.state.eventDetail.id));
      }
    }

    handlefavEventsResponse =(responseJson:FavEvent[],errorReponse:object)=> {
    if (responseJson && !errorReponse) {
      this.setState({favEventList:responseJson});
    }
  }
    handleEventfavEventsResponse =(responseJson:FavEvent[],errorReponse:object)=> {
      if (responseJson && !errorReponse) {
        this.handleFavouriteListEvent();
      }
    }

    handleProfileResponse=(responseJson:Profile[],errorReponse:object)=> {
      if (responseJson && !errorReponse) {
        this.setState({
          ...this.state,
          getAllData: responseJson,
        });
      }
    }

    handleEventDetail = (eventId: number) => {
        const headers = {
          token: localStorage.getItem("token"),
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.eventDetailApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.eventDetailEndPoint}`+`${eventId}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };  
      
      handleDayDiffrence = (startDateStr:string) => {
        const startDate = new Date(startDateStr);
        const differenceMs = new Date().getTime() - startDate.getTime();
        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
        return differenceDays-1;
      }

      handleAttendeeSearch = (searchAttendeeText:string) => {
        this.setState({searchText:searchAttendeeText})
      }

      handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
      handleMenuClose = () => {
        this.setState({ anchorEl: null });
      };

      getCountry = () => {
        let CounntryArray: ICountry[] = Country.getAllCountries();
        let newArray = CounntryArray.map((countryVal: ICountry) => ({
          value: countryVal,
          label: countryVal.name,
        }));
        return newArray;
      };
    
      getStateArray = (iosCode: string) => {
        let newArray = State.getStatesOfCountry(iosCode).map(
          (countryVal: IState) => ({ value: countryVal, label: countryVal.name })
        );
        return newArray;
      };
    
      getCityArray = (iosCodeCountry: string, iosCodeCounty: string) => {
        let newArray = City.getCitiesOfState(iosCodeCountry, iosCodeCounty).map(
          (countryVal: ICity) => ({ value: countryVal, label: countryVal.name })
        );
        return newArray;
      };

      handleCreateEventPopUp = () => {
        this.handleMenuClose();
        const iosCodeArray=Country.getAllCountries();
        const isocode=iosCodeArray.find((data)=>data.name==this.state.eventDetail.attributes.country);
        const codeCountry=isocode?.isoCode;
        const states= State.getStatesOfCountry(codeCountry?codeCountry:"");
        const stateCounty=states.find((data)=>data.name==this.state.eventDetail.attributes.county);
        const cityArrayall=City.getCitiesOfState(codeCountry?codeCountry:"",stateCounty?.isoCode?stateCounty.isoCode:"");
        const city=cityArrayall.find((data)=>data.name===this.state.eventDetail.attributes.town)

        this.setState({ isOpenedEditEventPopUp: true,editEventInitialValues:{   
          eventName: this.state.eventDetail.attributes.event_name,
          description: this.state.eventDetail.attributes.description,
          inPerson: this.state.eventDetail.attributes.is_in_person,
          bannerImage: this.state.eventDetail.attributes.banner_image,
          eventWeb: this.state.eventDetail.attributes.event_website,
          country: 
            isocode as CountryInfo  
          ,
          town: 
            city as TownInfo
          ,
          address: this.state.eventDetail.attributes.address,
          postcode: this.state.eventDetail.attributes.postcode,
          startDate: this.formatDate(this.state.eventDetail.attributes.start_date),
          startTime: dayjs(this.state.eventDetail.attributes.start_time).utc(),
          endDate: this.formatDate(this.state.eventDetail.attributes.end_date),
          linkToMeet:this.state.eventDetail.attributes.link,
          endTime: dayjs(this.state.eventDetail.attributes?.end_time).utc(),
          timeZone:"GMT",
          county:  
          stateCounty as TownInfo
        },inPersonOnlineBoth:this.state.eventDetail.attributes?.is_in_person,uploadedFileUrl:this.state.eventDetail.attributes?.banner_image?this.state.eventDetail.attributes?.banner_image:""
      } );
    };
  
      hideCreateEventModal = () => {
        this.setState({ isOpenedEditEventPopUp: false });
        this.setState({
          uploadedFile: null,
          uploadedFileUrl: "",
          inPersonOnlineBoth: "",
        });
      };   

      handleInpersonOnline = (value: string) => {
        this.setState({ inPersonOnlineBoth: value });
      };
    
      handleRemoveFile = () => {
        this.setState({ uploadedFile: null, uploadedFileUrl: "",validFile:true });
      };
    
      handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
          const fileSize = files[0].size / (1024 * 1024);
          if (fileSize <= 5 && files[0].type.split("/").includes("image")) {
            this.setState({ uploadedFile: files[0],uploadedFileUrl: URL.createObjectURL(files[0]),validFile:true});
          }
          else{
              this.setState({validFile:false,uploadedFileUrl: URL.createObjectURL(files[0])});
          }
        }
      };
    
      setStartDate = (date: Date) => {
        this.setState({ startDate: date });
      };
    
      setEndDate = (date: Date) => {
        this.setState({ endDate: date });
      };

      formatDate = (dateString: string | undefined): string => {
        if (!dateString) return '';
    
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
      };
    
      handleEditEvent(values: ICreateEvent): boolean {
        let formdata = new FormData(); 
        formdata.append("event_name", values.eventName);
        formdata.append("description", values.description);
        formdata.append("event_website", values.eventWeb);
        formdata.append("time_zone", values.timeZone);
        formdata.append("start_date", values.startDate);
        formdata.append("end_date", values.endDate);
        if(values.bannerImage)
        {
          formdata.append("banner_image", values.bannerImage);
        }
        if(values.country?.name && values.country?.name!=="Country")
        {
          formdata.append("country", values.country?.name);
        }
        formdata.append("is_in_person", values.inPerson);
        formdata.append("address", values.address);
        formdata.append("county", values?.county?.name==undefined?" ":values.county?.name);
        formdata.append("town", values?.town?.name==undefined?" ": values.town?.name);
        formdata.append("postcode", values.postcode);
        formdata.append("start_time", values.startTime);
        formdata.append("end_time", values.endTime); 
        formdata.append("link",values.linkToMeet)
        const header = {
          token: localStorage.getItem("token"),
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.eventEditApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.eventDetailEndPoint + `${this.state.eventDetail.id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.attendEventMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

      handleDeletePopUp = ()=>{
        this.handleMenuClose();
        this.setState({isOpenedDeleteEventPopUp:true});
      }

      handleHideDeletePopUp = ()=>{
        this.setState({isOpenedDeleteEventPopUp:false});
      }
      
      handleDeleteEventApi =() => {
        const headers = {
          token: localStorage.getItem("token"),
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.eventDeleteApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.eventDetailEndPoint}`+`${this.state.eventDetail.id}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.delete
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

      navigateToListEventPage = () => {
        this.props.navigation.goBack();
      };

      handleAttendEvent = () => {
        const header = {
          token: localStorage.getItem("token"),
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.eventAttendApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.attendEventEndPoint + `${this.state.eventDetail.id}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.attendEventMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

      handleFavouriteEvent = () => {
        const favoriteEventData={
          data:{
            favouriteable_id: parseInt(this.state.eventDetail.id),
            favouriteable_type: "BxBlockEvents::Event"
          }
        }   

        const header = {
          token: localStorage.getItem("token"),
          "Content-Type":configJSON.validationApiContentType
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.eventFavouriteApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.favouriteEventEndPont
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.createApiMethodType
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(favoriteEventData)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }

      handleFavouriteListEvent = () => {
        const headers = {
          token: localStorage.getItem("token"),
        };
        const requestMessageFavEvList = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.favouriteEventApiId = requestMessageFavEvList.messageId;
    
        requestMessageFavEvList.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.favouriteEventAPI}`
        );
    
        requestMessageFavEvList.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        
        requestMessageFavEvList.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
    
        runEngine.sendMessage(requestMessageFavEvList.id, requestMessageFavEvList);
        return true;
      };

      handleClose =()=>{
        this.setState({
          openModel: false,
          isCopied:null,
          msgSend:false,
         })
      }
    
      handleClickCopy = () => {
        this.setState({msgSend:false})
        navigator.clipboard.writeText(window.location.href.includes("=")? window.location.href:
        window.location.href+"?id="+`${parseInt(sessionStorage.getItem("eventDetailId") as string)}`)
          .then(() => {
            this.setState({ isCopied: true });
          })
          .catch((error) => { this.setState({ isCopied: false });});
      }

      handleChat=()=>{
        this.setState({isSendLinkToChatModalOpen:true, msgSend:false, isCopied:null})
        this.handleGetAllUserProfile();
      }

      openPopUp =()=>{
        this.setState({
         openModel: true,
         linkShare:window.location.href.includes("=")? window.location.href:
         window.location.href+"?id="+`${sessionStorage.getItem("eventDetailId")}`
        })
       }

      handleGetAllUserProfile = () => {
        const headers = {
          token: localStorage.getItem("token"),
        };
        const requestMessageGetUser = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getAllDataProfileApiCallId = requestMessageGetUser.messageId;
    
        requestMessageGetUser.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        requestMessageGetUser.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getProfileEndPoint}`
        );
    
        requestMessageGetUser.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessageGetUser.id, requestMessageGetUser);
    
        return true;
      };

      getIdURL = ():string|undefined =>{
       return window.location.href.split("=").at(1);
      }

      handleNavigateBack=()=>{
        this.setState({
          isSendLinkToChatModalOpen:false,
          openModel: true
         })
      }

      handleCloseChatModel = () => {
        this.setState({isSendLinkToChatModalOpen: false,shareData:[],sendChatMessage:''});
      }

      handleSend=()=>{
        this.setState({isSendLinkToChatModalOpen: false,shareData:[],sendChatMessage:''});
        this.setState({ msgSend: true }); 
      }

      handlesendChatMessage=(event:{target:{value:string}})=>{
        this.setState({ sendChatMessage: event.target.value });
      }

      handleshareDataChatChange = (_event:  React.ChangeEvent<{}>,value: OptionType[]) => {
        this.setState({ shareData: value });
      };

      navigateToLoginPage = () => {
        const userNavMsg: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "AccountLogin");
        userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(userNavMsg);
      };

      handleResponsePass = (passedValue: string|null|object) => {
       return passedValue ? passedValue : ""
      }

      handleResponseWithDash = (passedValue: string|null) => {
        return passedValue ? passedValue : "-"
      } 

      handleNewLinkOpen = (linkUrl:string) =>{
        const url = this.handleResponseWithDash(linkUrl);
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
        window.open(formattedUrl, '_blank');
      }

      handleCheckInperson = () => {
        return (this.state.inPersonOnlineBoth == "in person" || this.state.inPersonOnlineBoth == "both")
      }

      handleCheckInpersonWithOnline = () => {
        return (this.state.inPersonOnlineBoth == "online" || this.state.inPersonOnlineBoth == "both")
      }
      handleStateCov =(passedVal:string)=>{
        this.setState({cover:passedVal})
      }
      handleStatesCov =(passedValues:string)=>{
        this.setState({cover:passedValues})
      }
      handleStateCases=()=>{
        this.setState({cover:"coverage"})
      }
      handleStateCasesView=()=>{
        this.setState({cover:"under"});
        this.handleEventsDetailUpdate(parseInt(this.state.eventDetail.id));
      }

      handleEventsDetailUpdate = (eventId: number) => {
        const headers = {
          token: localStorage.getItem("token"),
        };
        const requestMessagers = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.eventDetailApiCallId = requestMessagers.messageId;
    
        requestMessagers.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.eventDetailEndPoint}`+`${eventId}`
        );
    
        requestMessagers.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessagers.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessagers.id, requestMessagers);
        return true;
      };  
    // Customizable Area End
}

