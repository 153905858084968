import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import { toast } from 'react-toastify';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  forgotPasswordEmail:string;
  pswrdEmailError:string;
  forgotPwdError:string;
  confirmPwdError:string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  errorMsg:string;
  signInFormData: {
    password: string;
    email: string;
  };
  newPassword: string;
  confirmNewPwd:string;
  showPassword: boolean;
  timer:number;
  showConfirmPassword:boolean;
  showForgotPwd:boolean;
  isEmailUpdated: boolean;
  accountRecoveryModalOpen: boolean;
  isAccountReactivated: boolean;
  // Customizable Area End
}
 // Customizable Area Start
interface LoginValues{
  email:string;
  password:string;
}
interface LoginResponse{
  meta?:MetaResponse;
  errors?:(ErrorMessages)[];
  message?:string;
 }
 interface VerifyResponse{
  meta:MetaResponse;
  message:string;
  errors?:string;
 }
 interface ProfileResponse{
  id:string;
  type:string;
  attributes:Attributes;
 }
 interface Role{
  id:number,
  name:string
 }
 interface Attributes{
  activated:boolean;
  country_code:string|null;
  email:string;
  first_name:string;
  full_phone_number:string;
  last_name:string;
  phone_number:string|null;
  type:string;
  created_at:string;
  updated_at:string;
  device_id:string|null;
  unique_auth_id:string;
  profile:number,
  role:Role,
  profile_photo:string
 }
 interface VerifyResponse{
  meta:MetaResponse;
  message:string;
 }
 interface MetaResponse{
  token:string;
  refreshToken?:string;
  id?:string;
  }
  interface ErrorMessages{
    failed_login?:string;
    email?:string;
    password?:string;
  }
 // Customizable Area End
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  sendVerificationMailApiCallId:string="";
  resendVerificationMailApiCallId:string="";
  setNewPasswordApiCallId: string = "";
  showProfileApiCallId:string="";
  labelTitle: string = "";
  signInApiCallId: string = "";
  confirmNewEmailApiCallId: string="";
  reactivateAccountApiCallId: string = "";
  timerInterval:ReturnType<typeof setInterval> | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      errorMsg:"",
      timer:60,
      signInFormData: {
        email: "",
        password: "",
      },
      forgotPasswordEmail:'',
      pswrdEmailError:'',
      forgotPwdError:'',
      confirmPwdError:'',
      newPassword: '',
      confirmNewPwd:'',
      showPassword: false,
      showConfirmPassword:false,
      showForgotPwd:false,
      placeHolderEmail: configJSON.placeHolderEmail,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      isEmailUpdated: false,
      accountRecoveryModalOpen: false,
      isAccountReactivated: false
    };

    this.labelTitle = configJSON.labelTitle;
    this.timerInterval = setInterval(() => {}, 0);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.setTimeInterval();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    const searchParams = new URLSearchParams(window.location.search);
    const isEmailUpdated = searchParams.get("isEmailUpdated");
    if(isEmailUpdated){
      this.setState({isEmailUpdated: Boolean(isEmailUpdated)});
    }
    // Customizable Area Start
        // Customizable Area End
  }

  // Customizable Area Start

  handleEmailUpdated = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let formdata = new FormData();
    const token = searchParams.get("token") || "";
    const newEmail = searchParams.get("new_email") || "";
    formdata.append("token",token)
    formdata.append("new_email", newEmail)
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.confirmNewEmailApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.verifyNewEmail);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNavigate(){
      this.props.navigation.navigate("ForgotPasswordEmail");
  }
  handleNavigateMainPage(){
      this.props.navigation.navigate("LandingPageWeb");
  }
  setTimeInterval(){
    this.timerInterval = setInterval(() => {
        this.setState(prevState => ({
          timer: prevState.timer > 0 ? prevState.timer - 1 : 0
        }));
      }, 1000);
}
  handleSignUpClick(){
    this.props.navigation.navigate("SignUpThanks");
  }
  resetTimer() {
    clearInterval(this.timerInterval); 
    this.setState({ timer: 60 }); 
    this.setTimeInterval(); 
}
  handleBtnClick(){
    const validEmail=this.validateEmail();
   if(!validEmail){
    return;
   }
   else if(validEmail){
    this.sendVerificationMail()
   }
  }
  handlePasswordChange(value:string){
    this.setState({newPassword:value})    
  }
  validatePasswrd(){
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
    if(this.state.newPassword === "" ){
      this.setState({forgotPwdError:"Password can't be empty"}) 
    }
    else if (!passwordRegex.test(this.state.newPassword)) {
       this.setState({forgotPwdError:"Password doesn't match the criteria above"})
    }
    else{
      this.setState({forgotPwdError:''})
    }
  }
  handleConfirmPasswordChange(value:string){
   this.setState({confirmNewPwd:value});
  }
  valdateConfirmPwd(){
    if(this.state.confirmNewPwd === "" ){
      this.setState({confirmPwdError:"Confirm Password can't be empty"}) 
    }
    else
     if (this.state.confirmNewPwd !== this.state.newPassword) {
      this.setState({confirmPwdError:'Password not matching'})
  }
   else{
    this.setState({confirmPwdError:''})
   }
  }
  handleSubmit = (values: LoginValues) => {


    this.setState({
      signInFormData: {
        email: values.email,
        password: values.password,
      }
    }, () => {
      this.doEmailLogIn();
    })
    sessionStorage.setItem("email", values.email)
  
  };
  async componentWillUnmount() {
    clearInterval(this.timerInterval);
  }
  handlePassShow = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };
  handleClick(){
    if(this.state.confirmPwdError||this.state.forgotPwdError){
      return ;
    }
    if(!this.state.newPassword && !this.state.confirmNewPwd){
      this.setState({forgotPwdError:"Password can't be empty",confirmPwdError:"Confirm Password can't be empty"})
    }
    else if(!this.state.newPassword){
      this.setState({forgotPwdError:"Password can't be empty"})
    }
    else if(!this.state.confirmNewPwd){
      this.setState({confirmPwdError:"Confirm Password cant be empty"})
    }
    else{
      this.setNewPassword()
    }
  }
  handleForgotPwdShow = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    });
  };

  handlePwdShow = () => {
    this.setState({
      showForgotPwd: !this.state.showForgotPwd
    });
  };
 handleEmailChange(event:{target:{value:string}}){
  this.setState({forgotPasswordEmail:event.target.value})
  
 }
 validateEmail(){
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   if(!this.state.forgotPasswordEmail){
    this.setState({pswrdEmailError:"Email can't be empty"});
    return false;
   }
   else if (!emailRegex.test(this.state.forgotPasswordEmail)) {
        this.setState({pswrdEmailError:'Invalid Email'})
        return false;
    }
    else{
      this.setState({pswrdEmailError:''})
      sessionStorage.setItem('userEmail',this.state.forgotPasswordEmail)
      return true;
    }
 }
  saveVerifyResposne(responseJson:VerifyResponse){
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      let token = responseJson.meta.token;
      localStorage.setItem("forgotPwdToken", token);
      this.props.navigation.navigate("ForgotPwdVerification");
    }
    else if(responseJson && responseJson.errors){
        this.setState({pswrdEmailError:responseJson.errors}) 
    }
  }
  saveResendResposne(responseJson:VerifyResponse){
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      let token = responseJson.meta.token;
      localStorage.setItem("forgotPwdToken", token);
    }
  }
 async saveProfileResponse(responseJson:ProfileResponse){
    const profileNum=responseJson?.attributes?.profile?.toString() ??''
    sessionStorage.setItem('profileUserId',responseJson?.id??"");
    sessionStorage.setItem('countryCode',responseJson?.attributes?.country_code??"");
    sessionStorage.setItem('firstName',responseJson?.attributes?.first_name??"");
    sessionStorage.setItem('accountEmail',responseJson?.attributes?.email??"");
    sessionStorage.setItem('lastName',responseJson?.attributes?.last_name??"");
    sessionStorage.setItem('fullPhoneNumber',responseJson?.attributes?.full_phone_number??'');
    sessionStorage.setItem('createdAt',responseJson?.attributes?.created_at??"");
    sessionStorage.setItem('updatedAt',responseJson?.attributes?.updated_at??"");
    sessionStorage.setItem('deviceId',responseJson?.attributes?.device_id??'');
    sessionStorage.setItem('phoneNumber',responseJson?.attributes?.phone_number??'');
    sessionStorage.setItem('profileId',profileNum);
    sessionStorage.setItem('authId',responseJson?.attributes?.unique_auth_id??'');
    sessionStorage.setItem('profileAvatar',responseJson?.attributes?.profile_photo??'');
    const availableData:{id: string} | undefined = await getStorageData("imageRedirect", true);
   if (availableData) {
     this.props.navigation.navigate("ListPost", availableData);
     removeStorageData("imageRedirect");
   } else this.props.navigation.navigate('ProfileInfo');
  }
  saveLoggedInUserData(responseJson: LoginResponse) {   
    if(responseJson && responseJson.message) {
      this.setState({accountRecoveryModalOpen: true});
    } 
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      let token =responseJson.meta.token;
      let userId = JSON.stringify(responseJson.meta.id);
      localStorage.setItem("token", token);
      sessionStorage.setItem("userId", userId);
      this.showProfile() 
    }
    if (responseJson?.meta) {
      this.setState({
        signInFormData: {
          password: '', email: '',
        },
        showPassword: false
      });
    }
    
    if(responseJson?.errors?.[0]?.failed_login?.includes('email')){
      this.setState({pswrdEmailError:responseJson?.errors?.[0]?.failed_login})
    }
    if(responseJson?.errors?.[0]?.failed_login?.includes('password')){
      this.setState({errorMsg:responseJson?.errors?.[0]?.failed_login})
    }

  }
  handleClose = () => {
    this.setState({accountRecoveryModalOpen: false});
  }
  handleOk = () => {
    this.setState({isAccountReactivated: false})
    this.doEmailLogIn();
  }

  handleReactivateAccount = () => {
    const formdata = new FormData();
    formdata.append("email", this.state.signInFormData.email);
    const header = {};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reactivateAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reactivateAccount);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.loginAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (this.apiEmailLoginCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.saveLoggedInUserData(responseJson);
    }
    if (this.setNewPasswordApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.props.navigation.navigate("PasswordProcessComplete");
    }
  
    if (this.showProfileApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.saveProfileResponse(responseJson?.data)
    }
    if (this.sendVerificationMailApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.saveVerifyResposne(responseJson)
      
    }
    if (this.resendVerificationMailApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      let responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.saveResendResposne(responseJson)
      this.resetTimer();
    }

    if(this.confirmNewEmailApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      let responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setState({isEmailUpdated: false});
      if(responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].link){
        toast.error(responseJson.errors[0].link);
      }
    }

    if(this.reactivateAccountApiCallId ===  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      let responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson && responseJson.message){
        this.setState({isAccountReactivated: true, accountRecoveryModalOpen: false});
      }
    }
    // Customizable Area End
  }


  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.signInFormData.email,
      password: this.state.signInFormData.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = reqMsg.messageId;
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(reqMsg.id, reqMsg);

    return true;
  }
  setNewPassword(){
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const searchParams = new URLSearchParams(window.location.search);

    const data = {
      new_password: this.state.confirmNewPwd,
      token: searchParams.get("token"),
    };

    const httpBody = {
      data: data,
    };
    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setNewPasswordApiCallId = reqMsg.messageId;
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pswrdResetEndPoint
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
    runEngine.sendMessage(reqMsg.id, reqMsg);

    return true;

  }
  sendVerificationMail(){
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const data = {
     email:sessionStorage.getItem('userEmail')};
    const httpBody = {
      data: data, };
    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage));

    this.sendVerificationMailApiCallId = reqMsg.messageId;
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),'/forgot_password/otps/forgot_password_mail'
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody));
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST');
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    runEngine.sendMessage(reqMsg.id, reqMsg);
  }


  resendVerificationMail(){
    const header = {
      "Content-Type": configJSON.loginApiContentType,};
    const data = {
     email:sessionStorage.getItem('userEmail')};
    const httpBody = {data: data, };
    const reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.resendVerificationMailApiCallId = reqMsg.messageId;
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/forgot_password/otps/forgot_password_mail');
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),'POST');
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    runEngine.sendMessage(reqMsg.id, reqMsg);
  }
  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsger = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsger.messageId;

    getValidationsMsger.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsger.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );
    
    getValidationsMsger.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsger.id, getValidationsMsger);
  }
  showProfile(){
    const token=localStorage.getItem('token');
    const header = {
      token: token,
    };
    const reqMsgNew = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showProfileApiCallId = reqMsgNew.messageId;
    reqMsgNew.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showProfileEndPoint
    );
    reqMsgNew.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    reqMsgNew.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(reqMsgNew.id, reqMsgNew);
    return true;
  }
}
