import React from "react";
// Customizable Area Start
import {
    styled,
    Button,
     IconButton,
    Typography,  Grid, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,Box,TextField,Chip,MenuItem,Select
} from "@material-ui/core";
import { makeStyles, Theme, createStyles,withStyles } from "@material-ui/core/styles";
import { Formik, Form, FormikErrors } from "formik";
import { EditProfileDefault, closeImage } from "../assets"
import "react-datepicker/dist/react-datepicker.css";
import Avatar from '@mui/material/Avatar';
import * as Yup from 'yup';
import {  S, UpdatedValues } from "../ProfileInfoController";
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import { checkCondition } from "../../../../components/src/HelperUtils";

type Props = {
    state: S
    handleEditDialoug: () => void
    UpdateProfile: () => void
    handleImageChange:(event:React.ChangeEvent<HTMLInputElement> | null,setFields: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<{ FirstName: string; LastName: string; Profession: string; Seniority: string; Headline: string; Country: CountryInfo; Region: CityInfo; Town: CityInfo; PreferredWork: string[]; image: string; }>>) => void
    getStateArray:(value:any) => {value:any,label:any}[]
    getCityArray:(iosCodeCountry: any, iosCodeCounty: any) => {value:any,label:any}[]
    getCountry:() => {value:any,label:any}[]
    handleSaveModalOpen: (value: UpdatedValues) => void
    handleSaveModalClose: () => void
    handleDataSet:(value:UpdatedValues) => void
    handleEditDialougClose:() => void
    handleChangeData:(event:React.ChangeEvent<HTMLInputElement |HTMLTextAreaElement| HTMLSelectElement | unknown>,setFieldValue: (name: string, value: string) => void) => void
    handleAutoComplateChange:(name:string,value:string[],setFieldValue: (fieldName: string, newValue: string[]) => void )=>void
}

interface Option {
    name: string;
    isoCode: string;
    flag: string;
    phonecode: string;
    currency: string;
    latitude: string;
    longitude: string;
    timezones: TimeZone[];
  }
interface TimeZone {
    zoneName: string;
    gmtOffset: number;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;
}

interface CountryInfo {
    isoCode: string;
    name: string;
}

type CountryInfoArray = CountryInfo[];

interface CityInfo {
    name: string;
    isoCode: string;
}

type StateInfoArray = CityInfo[];
const validationSchemaProfile = Yup.object().shape({
    FirstName: Yup.string().trim().required('First Name is required'),
    LastName: Yup.string().trim().required('Last Name is required'),
    Profession: Yup.string().required('Profession is required'),
    Seniority: Yup.string().required('Seniority is required'),
    Headline: Yup.string().trim().required('Headline is required'),
    Country: Yup.string().required('Country is required'),
    Region: Yup.string(),
    Town: Yup.string(),
    PreferredWork: Yup.string().required('PreferredWork is required'),
    // Define validation rules for other fields here
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({       
        buttonOutline: {
            borderRadius: "2px",
            borderColor: "black",
        },
        buttonFilled: {
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
        },
    })
);

export const StyledAutocomplete = withStyles({
    inputRoot: {
        '& .MuiInputBase-input': {
            height: '23px !important' ,
            fontSize:'14px'
        },
    },
})(Autocomplete);

const StyledDialogActions = styled(DialogActions)(({theme}: {theme: Theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
    padding: "0 40px",
    "@media (max-width:530px)": {
        flexFlow: "column",
        padding: "16px 25.5px"
    },
    "& button:last-child":{
        marginLeft: "16px !important"
    },
    "& button":{
        fontWeight: 500,
        border: ' 1px solid black',
        fontSize: "16px",
        "@media (max-width:530px)": {
            width: "100% !important"
        }
    },
    "& .buttronWithoutsaving":{
        color: 'black',
        padding: '10px'
    },
    "& .saveChangeButton":{
        color: '#fff',
        backgroundColor: 'black',
        padding: '10px 50px',
        "@media (max-width:530px)": {
           margin: "16px 0px 0px !important"
        }
    }
}));

const StyledTextField = styled(TextField)({
    width: "100%",
    marginTop: '4px',
    "& input":{
        fontFamily: "Silika-Medium !important",
        fontSize: "16px",
        color: "#010101 !important",
    },
    "& .MuiInputBase-input": {
        width: "100% !important",
        height: "48px !important",
        fontFamily: "Silika-Light",
        fontSize: "16px",
        color: "#010101",
        "&::placeholder": {
            fontFamily: "Silika-Medium !important",
            fontSize: "16px",
            color: "#9B9B9D !important",
        },
    },
})

const CustomSelect = styled(Select)({
    marginTop: "4px",
    width: "100%",
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center"
    },
    "& .MuiInputBase-input": {
      width: "100% !important",
      height: "48px !important",
      fontSize: "16px",
    },
    "& input":{
        fontFamily: "Silika-Medium !important",
        color: "#010101 !important",
        fontSize: "16px",
    }
  })

export const CustomButton = styled(Button)({

    "&.blackBackground": {
        backgroundColor: "black",
        color: "white"
    },
    "&:hover": {
        backgroundColor: "black !important",
    },
    "& .MuiButton-contained.Mui-disabled":{
        backgroundColor: "lightgray",
        color:'white',
        boxShadow:'none'
    }
})

const conditionRender=(values:string,placeholder:string)=>{
    return values ? undefined : () => <div style={webStyle.placeholderStyle}>{placeholder}</div>
}

function EditProfileModal(props: Props) {
    const { state, getCountry,handleChangeData,handleAutoComplateChange, handleEditDialougClose,UpdateProfile,handleImageChange,getStateArray,handleDataSet,getCityArray,handleSaveModalOpen,handleSaveModalClose } = props;
    const classes = useStyles();
   

    return (
        <>
        <ModelStyle
            maxWidth="md"
            open={state.editProfile}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
        >            
                    <Formik
                          initialValues={{
                            FirstName: state.getProfile?.attributes?.first_name || '',
                            LastName: state.getProfile?.attributes?.last_name || '',
                            Profession: state.getProfile?.attributes?.profession || [],
                            Seniority: (state.getProfile?.attributes?.seniority_level ?? '').replace(/^./, (str:string) => str.toUpperCase())                            ,
                            Headline: state.getProfile?.attributes?.headline || '',
                            Country: state.getProfile?.attributes?.country || '',
                            Region: state.getProfile?.attributes?.county || '',
                            Town: state.getProfile?.attributes?.city || '',
                            PreferredWork: state.getProfile?.attributes?.work || [],
                            image: state.getProfile?.attributes?.photo || EditProfileDefault,
                            imageError: "",

                        }}
                        onSubmit={(values) => {
                            handleDataSet(values)
                        }}
                        data-testId="Formik"
                        validationSchema={validationSchemaProfile}
                    >
                        {({
                            handleChange,
                            values,
                            handleSubmit,
                            errors,
                            touched,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form style={{marginBottom:8}}>
                                    <ModalHeaderWrapper>
                                        <ModalTitle>{"Edit Profile"}</ModalTitle>
                                        <img onClick={() => handleSaveModalOpen(values)} data-testId="closeIcon" style={webStyle.closeIconStyleDialog} src={closeImage}></img>
                                    </ModalHeaderWrapper>
                                    <ModalContentWrapper>
                                    <FieldsWrapper>
                                    <ImageGrid container>
                                        <IconButton
                                            style={{padding:0}}
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            className="icon-btn-1"
                                        >
                                            <input
                                                type="file"
                                                hidden
                                                accept="image/jpeg,image/png,image/jpg,image/webP"
                                                className="filetype"
                                                id="group_image"
                                                name="image"
                                                data-testId="uploadPhoto"
                                                onChange={(event) => {handleImageChange(event, setFieldValue)
                                                }}
                                            />
                                            <Avatar
                                                src={typeof values.image === "string" ? values.image : URL.createObjectURL(values.image)}
                                                sx={{ width: 70, height: 70 }}
                                            />
                                            <br />
                                        </IconButton>
                                        <FormLabelFont style={{ marginTop: "20px", textAlign: "center" }}>Upload Profile Picture</FormLabelFont>
                                        {values.imageError && <Typography color="error">{values.imageError}</Typography>}
                                    </ImageGrid>
                                    <SubGridWrapper container spacing={2}>
                                        <GridItem item sm={6} >
                                            <FormLabelFont>First Name<span>*</span></FormLabelFont>
                                            <StyledTextField
                                                name="FirstName"
                                                variant="outlined"
                                                placeholder="Eg. Smith"
                                                data-testId="FirstName"
                                                value={ values.FirstName}
                                                onChange={(event) => {
                                                    handleChangeData(event,setFieldValue)
                                                }}

                                            />
                                        </GridItem>

                                        <GridItem item sm={6}>
                                        <FormLabelFont>Last Name<span>*</span></FormLabelFont>
                                            <StyledTextField
                                                name="LastName"
                                                variant="outlined"
                                                data-testId="LastName"
                                                placeholder="Eg. Smith"
                                                value={values.LastName}
                                                onChange={(event) => handleChangeData(event,setFieldValue)}
                                            />
                                        </GridItem>
                                    </SubGridWrapper>

                                    <SubGridWrapper container spacing={2}>
                                        <GridItem item sm={6}>
                                        <FormLabelFont>Profession<span>*</span></FormLabelFont>
                                            <AutocompleteCustom
                                                popupIcon={<KeyboardArrowDownIcon />}
                                                options={state.getProfessionData}
                                                multiple
                                                id="tags-standard"
                                                getOptionLabel={(option) => option as string}
                                                onChange={(_event, newValue) => {
                                                    setFieldValue("Profession", newValue);
                                                    handleAutoComplateChange("Profession",newValue as string[],setFieldValue)
                                                }}
                                                value={values.Profession}
                                                data-testId="Profession"
                                                renderInput={(params) => (

                                                    <TextField
                                                        {...params}
                                                        placeholder={checkCondition(Boolean(values.Profession.length), "", "Eg. Exterior Design") as string}
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            disabled: values.Profession && values.Profession.length >= 3
                                                        }}
                                                    />
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            key={index}
                                                            label={
                                                                <Box component={'span'}>
                                                                    <ChipText noWrap>{option as string}</ChipText>
                                                                </Box>
                                                            }
                                                            style={webStyle.ChipStyle}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }

                                            />
                                        </GridItem>
                                        <GridItem item sm={6}>
                                        <FormLabelFont>Seniority<span>*</span></FormLabelFont>
                                            <CustomSelect
                                                data-testId='Seniority'
                                                IconComponent={KeyboardArrowDownIcon}
                                                value={values.Seniority}
                                                displayEmpty
                                                renderValue={conditionRender(values.Seniority,"Mid Level")}
                                                disableUnderline
                                                name='Seniority'
                                                variant="outlined"
                                                onChange={(event)=>{
                                                handleChange(event)
                                                handleChangeData(event,setFieldValue)
                                                }}
                                                MenuProps={{
                                                    getContentAnchorEl: null
                                                }}
                                            >
                                                {state.SeniorityData.map((menuItem) => <MenuItemFont key={menuItem} value={menuItem}>{menuItem}</MenuItemFont>)}
                                            </CustomSelect>
                                        </GridItem>
                                    </SubGridWrapper>
                                    <Grid container style={{marginBottom:"20px"}}>
                                        <Box style={webStyle.boxStyle as React.CSSProperties} >
                                            <FormLabelFont>Headline<span>*</span></FormLabelFont>
                                            <StyledTextField
                                                name="Headline"
                                                variant="outlined"
                                                data-testId="Headline"
                                                placeholder="Eg. Experienced Exterior Designer with a passion for..."
                                                value={values.Headline}
                                                InputProps={{inputProps: { maxLength: 60 } }}
                                                onChange={(event) => handleChangeData(event,setFieldValue)}
                                            />

                                            <Typography style={webStyle.typoStyle as React.CSSProperties}>{values.Headline ? values.Headline.length : '0'}/60</Typography></Box>
                                    </Grid>
                                    <SubGridWrapper container spacing={2}>
                                        <GridItem item sm={4}>
                                        <FormLabelFont>Country<span>*</span></FormLabelFont>
                                            <AutocompleteCustom
                                                popupIcon={<KeyboardArrowDownIcon />}
                                                disableClearable
                                                id="combo-box-demo"
                                                data-testId="CountryData"
                                                value={values.Country || ""}
                                                options={getCountry()?.map((options) => options.value)}
                                                getOptionLabel={(option:unknown) =>  (option as Option).name}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                onChange={(_event, newValue) => {
                                                    setFieldValue('Country', newValue === null ? "" : newValue)
                                                    setFieldValue('Region','')
                                                    setFieldValue('Town','')
                                                    handleAutoComplateChange("Country",newValue as string[],setFieldValue)
                                                }}
                                            />

                                        </GridItem>
                                        <GridItem item sm={4}>
                                        <FormLabelFont>Region</FormLabelFont>
                                            <AutocompleteCustom
                                                popupIcon={<KeyboardArrowDownIcon />}
                                                disableClearable
                                                id="combo-box-demo"
                                                data-testId="RegionData"
                                                value={values.Region || ""}
                                                options={getStateArray(values.Country.isoCode)?.map((options) => options.value)}
                                                getOptionLabel={(option:unknown) =>  (option as Option).name}
                                                 renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                onChange={(_event, newValue) => {
                                                    handleAutoComplateChange("Region",newValue as string[],setFieldValue)
                                                    setFieldValue('Region', newValue === null ? "" : newValue)
                                                    setFieldValue('Town','')
                                                }}
                                                disabled={!getStateArray(values.Country.isoCode)?.length}
                                            />
                                        </GridItem>
                                        <GridItem item sm={4}>
                                        <FormLabelFont>Town</FormLabelFont>
                                            <AutocompleteCustom
                                                popupIcon={<KeyboardArrowDownIcon />}
                                                disableClearable
                                                id="combo-box-demo"
                                                data-testId="TownData"
                                                value={values.Town || ""}
                                                options={getCityArray(values.Country.isoCode, values.Region.isoCode)?.map((options) => options.value)}
                                                getOptionLabel={(option:unknown) =>  (option as Option).name}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                onChange={(_event, newValue) => {
                                                    handleAutoComplateChange("Town",newValue as string[],setFieldValue)
                                                    setFieldValue('Town', newValue === null ? "" : newValue)
                                                }}
                                                disabled={!getCityArray(values.Country.isoCode, values.Region.isoCode)?.length}
                                            />
                                        </GridItem>
                                    </SubGridWrapper>
                                     <Grid container>
                                        <GridItem item lg={12} md={12} sm={12}>
                                        <FormLabelFont>Preferred Type of Work<span>*</span></FormLabelFont>
                                        <AutocompleteCustom
                                            limitTags={4}                                        
                                            popupIcon={<KeyboardArrowDownIcon />}
                                            id="tags-standard"
                                            getOptionLabel={(options) => options as string}
                                            multiple
                                            onChange={(_event, newValue1) => {
                                                setFieldValue('PreferredWork', newValue1);
                                                handleAutoComplateChange("PreferredWork",newValue1 as string[],setFieldValue)
                                            }}
                                            value={values.PreferredWork}
                                            options={state.PreffredWork}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, indexx) => (
                                                    <Chip
                                                        variant="outlined"
                                                        key={indexx}
                                                        label={
                                                            <Box component={'span'}>
                                                                <ChipText noWrap>{option as string}</ChipText>
                                                            </Box>
                                                        }
                                                        style={webStyle.ChipStyle}
                                                        {...getTagProps({ index: indexx })}
                                                    />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="outlined"
                                                    placeholder={checkCondition(Boolean(values.PreferredWork.length), "", "Select all that apply") as string}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                            data-testId="PreferredWork"
                                        />
                                        </GridItem>
                                        </Grid>
                                    </FieldsWrapper>
                                    <ButtonWrapper>
                                        <Button variant="outlined" className={classes.buttonOutline} onClick={handleEditDialougClose}>Cancel</Button>
                                        <CustomButton variant="contained" type="submit" className={`${classes.buttonFilled} ${isValid ? 'blackBackground' : ''}`} disabled={!isValid}>Save</CustomButton>
                                    </ButtonWrapper>
                                    </ModalContentWrapper>
                                </Form>
                            );
                        }}
                    </Formik>
        </ModelStyle>
            <ModelStyle
                maxWidth="sm"
            BackdropProps={{
                className:"backdrop-class"
            }}
            PaperProps={{
                className: "backdrop-paperClass"
            }}
                open={state.openSaveModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={webStyle.closeIconStyle}>
                    <CloseIcon onClick={handleSaveModalClose} />
                </DialogTitle>
                <DialogContent style={webStyle.textStyleDiv}>
                    <DialogContentText style={webStyle.textStyle as React.CSSProperties}>
                        There are some unsaved changes.
                    </DialogContentText>
                </DialogContent>
                <StyledDialogActions>
                    <Button onClick={() => {
                        handleSaveModalClose();
                        handleEditDialougClose();
                    }} className="buttronWithoutsaving"   data-testId="continueWithout">
                        Continue Without Saving
                    </Button>
                    <Button onClick={UpdateProfile} className="saveChangeButton" data-testId="saveChanges">
                        Save Changes
                    </Button>
                </StyledDialogActions>
            </ModelStyle>
     </>
    )
}

export default EditProfileModal

const webStyle={
    boxStyle:{
         position: 'relative', 
         width: '100%' 
    },
    typoStyle: {
        position: 'absolute',
        top: 8,
        right: 0,
        fontFamily: "Silika-Light",
        fontSize: "12px",
        lineHeight: "8px",
        color: "#000000"
    },
    gridStyle:{
        marginTop:"6px"
    },
    marginStyle:{
        marginTop:"13px"
    },
    typographyStyle:{
        marginBottom:"-4px",
        color:'#000'
    },
    ChipStyle:{
        borderRadius:'3px'
    },
    placeholderStyle:{
        color:"#b7b6b6",
        fontSize:'14px'
    },
    PreferredStyle:{
        marginTop:"13px",
        maxWidth:'98.3%'
    },
    countryContainer:{
        maxWidth:'99%'
    },
    closeIconStyle: {
        display: 'flex',
        justifyContent: 'end',
        cursor: 'pointer',
        padding: '25px'
    },
    textStyleDiv: {
        padding: '15px 50px 30px'
    },
    textStyle: {
        color: 'black',
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '28.96px',
        letterSpacing: '-0.15000000596046448px',
        textAlign: 'center',
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
        "@media (max-width:530px)": {
            flexFlow: "column",
            background: "red"
        }
    },
    buttronWithoutsaving: {
        color: 'black',
        border: ' 1px solid black',
        padding: '10px',
        fontWeight: 500,
        "@media (max-width:530px)": {
            width: "100%"
        }
    },
    saveChangeButton: {
        color: '#fff',
        border: ' 1px solid black',
        backgroundColor: 'black',
        padding: '10px 50px',
        fontWeight: 500,
        "@media (max-width:530px)": {
            width: "100%"
        }
    },
    uploadImageStyle: {
        marginBottom: "30px",
        marginTop: "60px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    closeIconStyleDialog: {
        width: "20px",
        cursor: "pointer"
    },
    positionStyle:{
        position:"relative",
        overflowX: 'hidden'
    },

}

const ModelStyle = styled(Dialog)({
    
    "& *":{
        fontFamily: "Silika-Medium"
    },
    "& .backdrop-class": {
        backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    "& .backdrop-paperClass":{
        minWidth: "548px !important",
    }
})
const ModalHeaderWrapper = styled(Box)({
    backgroundColor: "#0D0C22",
    justifyContent: "space-between",
    display: "flex",
    padding: "20px 40px",
    "@media (max-width:600px)": {
        padding: "20px",
    }
})
const ModalTitle = styled(Typography)({
    color: "#fff",
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "19px",
})
const ButtonWrapper = styled(Box)({
    columnGap: "16px",
    display: "flex",
    justifyContent: "end",
    padding: "16px 40px",
    "& *":{
        fontFamily: "Silika-Medium"
    },
    "@media (max-width:600px)": {
        padding: "16px 20px",
    },
    "& button":{
        fontSize: "16px",
        width: "87px",
        boxShadow: "none"
    }
})
const FieldsWrapper = styled(Box)({
    padding: "35px 40px",
    "@media (max-width:600px)": {
        padding: "20px",
    }
})
const ModalContentWrapper = styled(DialogContent)({
    padding: "0 !important"
})
const ImageGrid = styled(Grid)({
    marginBottom: "56px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
})
const FormLabelFont = styled(Typography)({
    lineHeight: "19px",
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    color: "#000000",
    letterSpacing: "-0.15px",
    "& span": {
        color: "red"
    }
})
const SubGridWrapper = styled(Grid)({
    marginBottom: "12px"
})
const GridItem = styled(Grid)({
    width: "100%"
})
const AutocompleteCustom = withStyles({
    inputRoot: {
        marginTop: "4px !important",
        minHeight: "48px !important",
        paddingTop: "4px !important",
        paddingBottom: "4px !important",
        '& .MuiInputBase-input': {
            fontSize: "16px",
            height: '30px !important',
            minWidth: '0px !important',
            fontFamily: "Silika-Light",
            "&::placeholder": {
                fontFamily: "Silika-Medium !important",
                color: "#9B9B9D !important",
                fontSize: "16px",
            },
        },
        "& input":{
            fontFamily: "Silika-Medium !important",
                color: "#010101 !important",
                fontSize: "16px",
        }
    },
    option: {
        fontFamily: "Silika-Medium",
        fontSize: "14px",
        padding: "7px 16px !important",
        color: "#0D0C22",
    },
    popupIndicator: {
        marginRight: "-4px"
    }
})(Autocomplete);
const ChipText = styled(Typography)({
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#010101",
})
const MenuItemFont = styled(MenuItem)({
    fontFamily: "Silika-Medium",
    fontSize: "14px",
    color: "#0D0C22",
    padding: "7px 16px !important"
})