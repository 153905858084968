import { Box, Typography, styled, Drawer, List, ListItem, Avatar, ListItemText, WithWidth, isWidthDown, IconButton, withWidth, Dialog, DialogContent, DialogActions, DialogTitle, Button } from "@material-ui/core";
import React, { Component } from "react";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import MenuIcon from '@material-ui/icons/Menu';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

const imgLogo = require('./image_logo.svg');
const profileImg = require('./viewcircle.svg');

const StyledBox = styled(Box)({
  "& .expendArrow": {
    transform: "rotate(180deg)",
    transitionDelay: "25s !important",
    transitionDuration: "5s !important",
  },
  "&.expendArrowReturn": {
    transform: "rotate(0deg)",
    transitionDelay: " 25s !important",
    transitionDuration: " 5s !important",
  },
  paddingRight:"20px"
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& *': {
    fontSize: "16px"
  },
  '& .MuiPaper-root': {
    backgroundColor: '#fff',
    borderRadius: 4,
    width: '600px',
    boxShadow: 'none',
    height:"243px"
  },
}));
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#0D0C22',
  color: '#fff',
  padding: '18px 37px 18px 40px',
  fontWeight: 'bold',
  "& *": {
    fontFamily: "Silika-Medium",
  }
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '44px 39.5px 24px 39.5px',
  "& p": {
    fontWeight: 400,
    fontFamily: "Silika-Light",
    textAlign: "center"
  },
 "@media(max-width:959px)": {
   paddingTop:"0px !important",
   paddingRight:"20px !important",
   paddingBottom:"0px !important",
   paddingLeft:"20px !important",
   display:"flex",
   justifyContent:"center",
   alignItems:"center"
  }
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '16px 40px 24px 40px',
  "@media(max-width:959px)": {
    paddingTop:"0px !important",
    paddingRight:"20px !important",
    paddingBottom:"20px !important",
    paddingLeft:"20px !important",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
   },
  display: 'flex',
  '& button': {
    fontFamily: "Silika-Medium",
    textTransform: "none",
    borderRadius: "0",
    boxShadow: "none",
    height: "48px"
  },
  "& .cancelBtn": {
    border: "1px solid #010101",
    color: "#010101"
  },
  "& .logoutBtn": {
    background: "#0D0C22",
    marginLeft: "16px"
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '100px',
  borderRadius:"2px !important",
  "& .logOutBtn":{
    padding:"14.5px 16px 14.5px 16px !important"
  }
}));
interface Props extends WithWidth {
  navigation?: {}
}
interface S {
  profileModal: boolean,
  discoverDropdown: boolean,
  isDropdownOpen: boolean,
  isOpenArrow: boolean,
  isDrawerOpen: boolean,
  isLogoutDialogeOpen: boolean,
  showMessage:boolean,
  selectedScreen: string;
}

class Navbar extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      profileModal: false,
      discoverDropdown: false,
      isDropdownOpen: false,
      isOpenArrow: false,
      isDrawerOpen: false,
      isLogoutDialogeOpen: false,
      showMessage:false,
      selectedScreen : localStorage.getItem('selectedScreen') || ''
    };
  }

  
  handleOpen = (p0?: string) => {
    this.setState({ showMessage: true });
  };
  handleClose = () => {
    this.setState({ showMessage: false });
  };
  

  handleHeaderProfileOpen = () => {
    this.setState({
      profileModal: !this.state.profileModal,
    });
  };

  handleHeaderProfileClose = () => {
    this.setState({
      profileModal: false,
    });
  };

  handleisDropdownOpen = () => {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
      isOpenArrow: !this.state.isOpenArrow,
    });
  };

  handleNavigation = (screenName: string) => {
    
    if (screenName.length > 0) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      runEngine.sendMessage(message.id, message);
      localStorage.setItem("selectedScreen", screenName);
    }
  }

  handleisDrawerOpen = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen
    });
  };

  handleIsLogoutDialogeOpen = () => {
    this.setState({
      isLogoutDialogeOpen: !this.state.isLogoutDialogeOpen
    });
  };

  handleLogout = () => {
    window.localStorage.clear();
    this.handleNavigation("LandingPage")
  };

  
  handleClickAwayDiscover = () => {
    this.setState({ isDropdownOpen: false, isOpenArrow: !this.state.isOpenArrow });
  };

  handleClickAwayProfile = () => {
    this.setState({ profileModal: false, isOpenArrow: !this.state.isOpenArrow });
  };



  renderLogoutDialogue = () => {
    return (
      <StyledDialog open={this.state.isLogoutDialogeOpen} onClose={this.handleIsLogoutDialogeOpen}>
        <StyledDialogTitle>Log out</StyledDialogTitle>
        <StyledDialogContent>
          <Typography>Are you sure you want to log out?</Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton onClick={this.handleIsLogoutDialogeOpen} color="primary" className="cancelBtn">
            Cancel
          </StyledButton >
          <StyledButton  className="logOutBtn" onClick={this.handleLogout} color="primary" variant="contained">
            Yes, log out
          </StyledButton >
        </StyledDialogActions>
      </StyledDialog>
    )
  };

  dropdownLinks = (navText: string, screen: string) => {
    return (
      <>
        <div>
          <div onClick={() => this.handleNavigation(screen)}
           style={webStyle.discoverHyperLink}>
            {navText}
          </div>
        </div>
        <Box style={webStyle.discoverDropDownWidth}>
          <Typography
            style={webStyle.discoverOptionBorder}
          />
        </Box>
      </>
    )

  }

  render() {
    const { width } = this.props;
    const isMobile = isWidthDown("sm", width);
    let selectedLink = localStorage.getItem("selectedScreen");
    const profileimg=sessionStorage.getItem("profileAvatar") || "gfhf";
    return (
      <> 
      {this.state.showMessage && (
        <Box style={webStyle.popupOverlay as React.CSSProperties}>
          <Box style={webStyle.popup as React.CSSProperties}>
            <p>This feature is not available yet.</p>
            <button onClick={this.handleClose} style={webStyle.buttonpop as React.CSSProperties}>Close </button>
          </Box>
        </Box> )}
        <StyledBox
          style={webStyle.headOuterContainer}
        >
          <Box
            style={webStyle.leftAlignOption}
          >
            <Box
              style={webStyle.logoContainer}
            >
              {
                isMobile ? <IconButton onClick={this.handleisDrawerOpen} style={webStyle.menuButton}><MenuIcon /></IconButton> : <></>
              }
              <img
                src={imgLogo}
                alt="logo"
                style={webStyle.logoImage}
                onClick={() => this.handleNavigation("ProfileInfo")}
              />
              {!isMobile ? <>
                <div onClick={() => this.handleNavigation("ProfileInfo")} style={webStyle.discoverHyperLink}>
                  Dashboard
                </div>
                <div onClick={() => this.handleNavigation("FindTalentCatalogue")} 
                style={{...webStyle.discoverHyperLink,
                  fontWeight : selectedLink === 'FindTalentCatalogue' ? 'bolder' : 'normal'
                }}>
                  Find Talent
                </div>
                <div onClick={() => this.handleOpen("JobListing")} style={webStyle.discoverHyperLink}>
                  Opportunities
                </div>
                <Box
                  onClick={this.handleisDropdownOpen}
                  style={webStyle.discoverAnimation}
                >
                  <Typography style={webStyle.discoverHyperLink}>Discover</Typography>
                  <ExpandMoreIcon
                    style={webStyle.expendIconDescover}
                    className={
                      this.state.isOpenArrow ? "expendArrow" : "expendArrowReturn"
                    }
                  />
                  {this.state.isDropdownOpen && (
                    <ClickAwayListener onClickAway={this.handleClickAwayDiscover}>
                    <Box
                      style={webStyle.discoverDropDown}
                    >
                      {this.dropdownLinks("Gallery", "LandingPageWeb6")}
                      <Box onClick={()=> this.handleOpen()} style={{width: "80px"}}>
                      {this.dropdownLinks("Companies", "")}
                      </Box>
                      {this.dropdownLinks("Events", "AllEventsWeb")}
                      <Box onClick={()=> this.handleOpen()} style={{width: "80px"}}>
                      {this.dropdownLinks ("Media", "")}
                      </Box>
                    </Box>
                    </ClickAwayListener>
                  )}
                </Box>
              </> : <></>}
            </Box>
            <Drawer open={this.state.isDrawerOpen} onClose={this.handleisDrawerOpen}>
              <List>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleOpen} style={webStyle.discoverHyperLink}>
                      Dashboard
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("FindTalentCatalogue")} style={webStyle.discoverHyperLink}>
                      Find Talent
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("JobListing")} style={webStyle.discoverHyperLink}>
                      Opportunities
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Box
                      onClick={this.handleisDropdownOpen}
                      style={webStyle.drawerBox}
                    >
                      <Typography style={{ ...webStyle.discoverHyperLink, fontWeight: 700 }}>Discover</Typography>
                      <ExpandMoreIcon
                        style={webStyle.expendIconDescover}
                        className={
                          this.state.isOpenArrow ? "expendArrow" : "expendArrowReturn"
                        }
                      />
                      {this.state.isDropdownOpen && (
                        <Box
                          style={webStyle.discoverDropDown}
                        >
                          {this.dropdownLinks("Gallery", "LandingPageWeb6")}
                          {this.dropdownLinks("Companies", "")}
                          {this.dropdownLinks("Events", "AllEventsWeb")}
                          {this.dropdownLinks("Media", "")}
                        </Box>
                      )}
                    </Box>
                  </ListItemText>
                </ListItem>
              </List>
            </Drawer>
          </Box>

          <Box style={webStyle.rightAlignOption}>

            <NotificationsOutlinedIcon style={webStyle.notificationOption}  onClick={() => this.handleNavigation("NotificationWeb")}/>

            <ChatBubbleOutlineIcon
              style={webStyle.chatOption}
            />
            <Avatar
              onClick={()=>this.handleHeaderProfileOpen()}
              src={profileimg}
              alt="profile"
              style={webStyle.headerImage}
              data-test-id="headerImg">
              {sessionStorage.getItem("firstName")?.toString()[0]}{sessionStorage.getItem("lastName")?.toString()[0]}
            </Avatar>
            {this.state.profileModal && (
              <ClickAwayListener onClickAway={this.handleClickAwayProfile}>
              <Box
                onClick={this.handleHeaderProfileClose}
                style={webStyle.profileHyperLinkDiv}
              >
                {this.dropdownLinks("My Profile", "ProfileInfo")}
                {this.dropdownLinks("Settings", "SettingsProfile")}
                {this.dropdownLinks("Favourites", "Favourites")}
                <div>
                  <Typography style={webStyle.discoverHyperLink} onClick={this.handleIsLogoutDialogeOpen}>
                    Log out
                  </Typography>
                </div>
                <Box style={webStyle.borderDiv}>
                  <Typography
                    style={webStyle.bottomBorder}
                  />
                </Box>
              </Box>
              </ClickAwayListener>
            )}
          </Box>
          {this.renderLogoutDialogue()}
        </StyledBox>
      </>
    );
  }
}

const webStyle = {
  hyperLink: {
    textDecoration: "none",
    textAlign: "center" as "center",
    fontFamily: "Silika-Medium",
    fontSize: "15px",
    fontStyle: "normal" as "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#000000",
    letterSpacing: "-0.225px",
    whiteSpace: "nowrap" as 'nowrap',
    padding: "10px",
    marginRight: "12px",
    "&:last-child": {
      margin: "0 !important"
    }
  },
  discoverHyperLink: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    textAlign: "center" as "center",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "10px",
    gap: "8px",
    cursor: 'pointer'
  },
  profileHyperLinkDiv: {
    position: "absolute" as "absolute",
    top: "50px",
    right: "5px",
    zIndex: 99999,
    height: "201px",
    width: "192px",
    background: "#FFF",
    borderRadius: "8px",
    padding: "4px",
    border: "1px solid #9B9B9D",
    gap: "2px",
    display: "grid" as "grid",
    flexDirection: "column" as "column",
    justifyContent: "start" as "start",
    alignItems: "center" as "center",
    justifyItems: "start" as "start",
  },
  expendIconDescover: {
    height: "24px",
    width: "24px",
  },
  headerImage: {
    height: "32px",
    width: "32px",
    flexShrink: 0,
    borderRadius:"50%",
    objectFit: 'cover' as 'cover',
  },
  chatOption: {
    height: "24px",
    width: "24px",
  },
  notificationOption : {
    fontSize  :'30px',
    stroke: "#ffffff",
     strokeWidth: 0.6
  },
  rightAlignOption: {
    display: "flex" as "flex",
    height : '100%',
    justifyContent: "space-evenly",
    alignItems: "center",
    gap : "24px",
    position: "relative" as "relative",
  },
  discoverOptionBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "160px",
    opacity: "16%",
    margin: "auto",
  },
  discoverDropDownWidth: {
    width: "190px"
  },
  discoverDropDown: {
    position: "absolute" as "absolute",
    top: "45px",
    left: "0px",
    height: "194x",
    width: "192px",
    background: "#FFF",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "start" as "start",
    gap: "7px",
    padding: "4px 4px 4px 5px",
    border: "1px solid #DFDFDF",
    borderRadius: "4px",
    zIndex : 2
  },
  discoverAnimation: {
    display: "flex",
    width: 'Hug (110px)',
    height: 'Hug(39px)',
    padding: '10px',
    gap: '10px',
    justifyContent: "center",
    alignItems: "center",
    position: "relative" as "relative",
    maxHeight:"20px !important"
  },
  drawerBox: {
    display: "flex",
    alignItems: "center",
    position: "relative" as "relative",
    padding: "0 10px",
  },
  logoImage: {
    width: "19.412px",
    height: "25.674px",
    flexGrow: 0,
    alignSelf: "center",
    marginRight: 25,
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  menuButton: {
    padding: "4px",
    marginRight: "17px"
  },
  leftAlignOption: {
    display: "flex",
    justifyContent: "space-evenly",
    textAlign: "center" as 'center',
  },
  headOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "32px",
    margin:"25px 25px 0px 25px"
  },
  bottomBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "160px",
    opacity: "16%",
    margin: "auto",
  },
  borderDiv: {
    width: "190px"
  },
  popupOverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000"
  },
  popup: {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    boxshadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
  },
  buttonpop: {
    margintop: "10px",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#243642",
    color: "white",
    cursor: "pointer",
    margin: "auto",
    display: "flex"
  
  }
};

export default withWidth()(Navbar)