import React from "react";
// Customizable Area Start
import {
  Button,
  Typography, Dialog, DialogContent,  DialogTitle, styled, Box
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik, Form,  Field } from "formik";
import {  closeImage, instagram, linkedin, behance, viom, yt, tt } from "../assets"
import "react-datepicker/dist/react-datepicker.css";
import { S } from "../ProfileInfoController";
import CustomTextField from "../../../../components/src/CustomTextField";
import { isObjectEmpty } from "../../../../components/src/HelperUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "2px",
      "& .MuiOutlinedInput-input": {
        "padding": "14px",
        borderRadius: "2px",
        border: "1px solid #9C9C9C",
        background: "#FFF",
        color: "#DFDFDF",
        "fontFamily": "Silika-Medium",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "&:hover": {
          border: "1px solid #DFDFDF",
        },
        "&::placeholder": {
          "color": "#64748B",
          "fontFamily": "Silika-Medium",
          "fontSize": "14px",
          "fontStyle": "normal",
          "fontWeight": "lighter",
          "lineHeight": "22px"
        },
        "&:focus": {
          border: "1px solid red",
        },
       
      },
      "& .MuiOutlinedInput-root": {
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#9C9C9C",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#9C9C9C",
        },
      },
    },
    divExprience1: {
      display: "flex",
      maxWidth: "1195px",
      justifyContent: "space-between",
      borderRadius: "4px, 4px, 0px, 0px",
      background: "#000",
      fontSize: "14px !important",
      fontWeight: 500,
      fontFamily: "Silika-Light",
      lineHeight: "19.3px",
      color: "white",
    },
    headDialoug: {
      fontFamily: "Silika-Medium",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "19px",

    },
    FormikInput: {
      alignItems: "center",
      display: "flex",
    },
    socialText: {
      fontFamily: "Silika-Medium",
      fontSize: "16px !important",
      fontWeight: 500,
      marginRight: "35px"
    },
    socialInput: {
      border: "1px solid ##DFDFDF",
      borderRadius: "4px", marginTop: "1%", width: 700
    },
    buttonFilled: {
      borderRadius: "2px",
      backgroundColor: "black",
      color: "white",
      marginTop: "16px",
    },
  })
);

type Props = {
  state: S
  handleEditSocial: () => void
  onValueSubmit:(value:any) => void
}
function EditSocial(props:Props) {
  const {state,handleEditSocial,onValueSubmit} = props
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={state.editSocial}
      onClose={handleEditSocial}
      aria-describedby="alert-dialog-slide-description"
    >
      <ModalHeaderWrapper>
        <ModalTitle>{"Add Socials"}</ModalTitle>
        <img onClick={handleEditSocial} style={{ width: "20px", cursor: "pointer" }} src={closeImage}></img>
      </ModalHeaderWrapper>
      <ModalContentWrapper>
            <Formik
              initialValues={state.editSocialIntialValues}
              onSubmit={(editSocial) => {
                onValueSubmit(editSocial)
              }}
            
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  <Form >
                    <FormikInputWrapper>
                      <div className={classes.FormikInput} >
                        <img src={linkedin} style={{ marginRight: "10px" }}></img>
                        <div style={{ width: "100px" }}>

                          <Typography className={classes.socialText}>linkedIn</Typography>
                        </div>
                      </div>
                      <FormInputWrapper>

                      <Field
                          name="linkedin"
                          component={CustomTextField}
                          type="string"
                          variant="outlined"
                          title=""
                          placeHolder="Please add your Profile URL here"
                        />
                      </FormInputWrapper>
                    </FormikInputWrapper>
                    <CustomHR />
                    <FormikInputWrapper>
                      <div className={classes.FormikInput} >
                        <img src={instagram} style={{ marginRight: "10px" }}></img>
                        <div style={{ width: "100px" }}>

                          <Typography className={classes.socialText}>Instagram</Typography>
                        </div>
                      </div>
                      <FormInputWrapper>
                        <Field
                          name="instagram"
                          component={CustomTextField}
                          type="string"
                          variant="outlined"
                          title=""
                          placeHolder="Please add your Profile URL here"
                        />
                      </FormInputWrapper>
                    </FormikInputWrapper>
                    <CustomHR />
                    <FormikInputWrapper>
                      <div className={classes.FormikInput} >
                        <img src={behance} style={{ marginRight: "10px" }}></img>
                        <div style={{ width: "100px" }}>

                          <Typography className={classes.socialText}>Behance</Typography>
                        </div>
                      </div>
                      <FormInputWrapper>
                        <Field
                          name="behance"
                          component={CustomTextField}
                          type="string"
                          variant="outlined"
                          title=""
                          placeHolder="Please add your Profile URL here"
                        />
                      </FormInputWrapper>
                    </FormikInputWrapper>
                    <CustomHR />
                    <FormikInputWrapper>
                      <div className={classes.FormikInput} >
                        <img src={viom} style={{ marginRight: "10px" }}></img>
                        <div style={{ width: "100px" }}>

                          <Typography className={classes.socialText}>Vimeo</Typography>
                        </div>
                      </div>
                      <FormInputWrapper>
                      <Field
                          name="vimeo"
                          component={CustomTextField}
                          type="string"
                          variant="outlined"
                          title=""
                          placeHolder="Please add your Profile URL here"
                        />
                      </FormInputWrapper>
                    </FormikInputWrapper>
                    <CustomHR />
                    <FormikInputWrapper>
                      <div className={classes.FormikInput} >
                        <img src={yt} style={{ marginRight: "10px" }}></img>
                        <div style={{ width: "100px" }}>

                          <Typography className={classes.socialText}>Youtube</Typography>
                        </div>
                      </div>
                      <FormInputWrapper>
                      <Field
                          name="youtube"
                          component={CustomTextField}
                          type="string"
                          variant="outlined"
                          title=""
                          placeHolder="Please add your Profile URL here"
                        />
                      </FormInputWrapper>
                    </FormikInputWrapper>
                    <CustomHR />
                    <FormikInputWrapper>
                      <div className={classes.FormikInput} >
                        <img src={tt} style={{ marginRight: "15px" }}></img>
                        <div style={{ width: "100px" }}>

                          <Typography className={classes.socialText}>Tiktok</Typography>
                        </div>
                      </div>
                      <FormInputWrapper>
                      <Field
                          name="tikTok"
                          component={CustomTextField}
                          type="string"
                          variant="outlined"
                          title=""
                          placeHolder="Please add your Profile URL here"
                        />
                      </FormInputWrapper>
                    </FormikInputWrapper>
                    <ButtonBox>
                      <Button variant="contained" className={classes.buttonFilled} onClick={handleEditSocial} type="submit"
                      disabled={isObjectEmpty(values)}
                      >Save</Button>
                    </ButtonBox>
                  </Form>
                );
              }}
            </Formik>
      </ModalContentWrapper>

    </Dialog>
  )
}

export default EditSocial

const ModalContentWrapper = styled(DialogContent)({
  padding: "0 40px",
  "@media (max-width:600px)": {
    padding: "0 20px",
  }
})
const FormInputWrapper = styled(Box)({
  width: "100%",
  marginTop: "5px",
  marginBottom: "24px",
  "@media (max-width:400px)": {
    marginTop: "0px",
  }
})
const CustomHR = styled(Typography)({
  borderBottom: "1px solid",
  color: "#DFDFDF",
})

const ButtonBox = styled(Box)({
  display: "flex",
  justifyContent: "end",
  alignItems: "center"
})
const ModalHeaderWrapper = styled(Box)({
  backgroundColor: "#0D0C22",
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 40px",
  "@media (max-width:600px)": {
    padding: "20px",
  }
})
const FormikInputWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexWrap: "nowrap",
  "@media (max-width:400px)": {
    flexWrap: "wrap",
    justifyContent: "left",
    marginTop: "24px",
  }
})

const ModalTitle = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Silika-Medium",
  fontWeight: 500,
  lineHeight: "19px",
  color: "#fff"
})