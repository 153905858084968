import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { measureInternetSpeed } from "../../../components/src/measureInternet.web";
import _ from "lodash";
export interface PostImage {
  id: number;
  image_url: string;
  image_id: string;
  is_like: boolean;
  is_favourite: boolean;
  is_visible: boolean;
  account_id: number;
  post_id: number;
  project_title: string;
  confidential: boolean;
}
export interface Profile {
  id: string;
  type: "profile";
  attributes: {
    id: number;
    country: string;
    county: string;
    city: string;
    postal_code: string | null;
    account_id: number;
    first_name: string;
    last_name: string;
    bio: string | null;
    skill: string[];
    phone_number: string;
    profession: string[];
    headline: string;
    company_name: string;
    company_number: string;
    registered_address: string;
    vat_number: string;
    availability: "open" | "closed";
    thumbs_up_count: number;
    preferred_type_of_work: string | null;
    visibility_option: string | null;
    hourly_rate_option: string | null;
    online_option: string | null;
    dates: string | null;
    seniority_level: string;
    work_location: string | null;
    work: string[];
    hourly_rate: number | null;
    collaborators: string[];
    tags: string[];
    description: string;
    photo: string;
    unavailable_dates: string;
    hourly_rate_from?: string | number | null;
    hourly_rate_to?: string | number | null;
    email: string;
    is_like: boolean;
    post_images: PostImage[]
  };
}

interface OptionType {
  account_id?: number;
  firstName: string;
  lastName: string;
  photo: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  profileData: Profile[];
  allLikes : any[];
  advanceSearchText: string;
  professions: string[];
  selectedProfessions: string[];
  workTypes: string[];
  selectedWorkTypes: string[];
  skills: string[];
  selectedSkills: string[];
  selectedCountries: string[];
  seniorityLevels: string[];
  selectedSeniorityLevels: string[];
  favPeople: {id:number, favouriteable_id: number}[];
  minRate: number | undefined;
  maxRate: number | undefined;
  shareData:OptionType[];
  chatMessage: string;
  isShareModalOpen: boolean;
  shareLink: string;
  isChatSend: boolean;
  isChatModalOpen: boolean;
  isLoading: boolean;
  count: number;
  needLoadMoreBtn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  token: string = "";
  getProfileDataApiCallId: string | null = "";
  giveFeedbackAPI : string | null = "";
  getAllLikesAPICallID  :string | null = "";
  getSeniorityLevelAPICallID  :string | null = "";
  getWorkTypesAPICallID  :string | null = "";
  getAllSkillsAPICallID  :string | null = "";
  getProfessionsAPICallID  :string | null = "";
  getFavouritePeopleApiCallId: string | null = "";
  addFavouriteApiCallId: string | null ="";
  postLikeApiCallId: string | null = "";
  postFavouriteApiCallId: string | null = "";
  shareChatApiCallId: string | null = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      arrayHolder: [],
      token: "",
      profileData: [],
      allLikes : [],
      advanceSearchText: "",
      professions: [],
      selectedProfessions: [],
      workTypes: [],
      selectedWorkTypes: [],
      skills: [],
      selectedSkills: [],
      selectedCountries: [],
      seniorityLevels: [],
      selectedSeniorityLevels: [],
      favPeople: [],
      minRate: undefined,
      maxRate: undefined,
      shareData: [],
      chatMessage: "",
      isShareModalOpen: false,
      shareLink: "",
      isChatSend: false,
      isChatModalOpen: false,
      isLoading: false,
      count: 6,
      needLoadMoreBtn: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.handleScroll = this.handleScroll.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start
    super.componentDidMount();
    this.getToken();
    this.listProfileData();
    this.fetchProfessionsList();
    this.fetchSeniorityLevels();
    this.fetchSkills();
    this.fetchWorkTypes();
    this.getAllLikes();
    this.getFavouritePeopleList();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    window.addEventListener("scroll", this.handleScroll);
    // Customizable Area End
  }
  // Customizable Area Start 
  handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({advanceSearchText: event.target.value}, this.handleSearchKeyDebounce);
  }

  handleSearchKeyDebounce = _.debounce(() => {
    this.listProfileData();
  },1000);

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const messageId = getName(MessageEnum.RestAPIResponceMessage);
    
    
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token });
    }

    if (messageId === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(!responseJson.errors && !!responseJson){

      switch(apiRequestCallId){
        case this.getProductApiCallId :{
          this.setState({ arrayHolder: responseJson.data });
          runEngine.debugLog("arrayHolder", this.state.arrayHolder);
          break;
        }

        case this.getProfileDataApiCallId:{
          this.setState({ profileData: responseJson.data });
          break;
        } 

        case this.giveFeedbackAPI:{
          this.getAllLikes();
          break;
        } 

        case this.getAllLikesAPICallID:{
          this.setState({ allLikes: responseJson.data });
          break;
        } 

        case this.getProfessionsAPICallID:{
          this.setState({ professions: responseJson.professions });
          break;
        } 

        case this.getFavouritePeopleApiCallId:{
          let favPeople = responseJson.data.map((d: {id:number,attributes: {favouriteable_id: number}}) => ({favouriteable_id: d.attributes.favouriteable_id, id: d.id}));
          this.setState({favPeople});
          break;
        } 

        case this.addFavouriteApiCallId: {
          this.getFavouritePeopleList();
          break;
        }

        case this.postFavouriteApiCallId:{
          this.listProfileData();
          break;
        }

        case this.getWorkTypesAPICallID:{
          this.setState({ workTypes: responseJson.prefrred_type_of_works });
          break;
        } 
        case this.getAllSkillsAPICallID:{
          this.setState({ skills: responseJson.skills });
          break;
        } 
        case this.getSeniorityLevelAPICallID:{
          this.setState({ seniorityLevels: responseJson.seniority_level });
          break;
        }
        case this.postLikeApiCallId:{
          this.listProfileData();
          break;
        }

        case this.shareChatApiCallId:{
          this.setState({isChatModalOpen: false, isShareModalOpen: true, isChatSend: true, chatMessage: "", shareData: []});
          break;
        }
        default:{
          break;
        }
      }
      }else{
        if(responseJson.errors && responseJson.errors?.[0]?.token === "Invalid token"){
          const Cataloguemessage = new Message(getName(MessageEnum.NavigationMessage));
          Cataloguemessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          Cataloguemessage.addData(getName(MessageEnum.NavigationTargetMessage), "AccountLogin");
          runEngine.sendMessage(Cataloguemessage.id, Cataloguemessage);
        }else{
          const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleFavouritePeople = (isFavourite: boolean, favouriteable_id: number) => {
  this.handleFavourite(isFavourite, favouriteable_id, "BxBlockProfile::Profile");
  }
  handleFavourite = (isFavourite: boolean, favouriteable_id: number, favouriteable_type: string) => {
    const headers = {
      token: localStorage.getItem("token"),
      "Content-Type":configJSON.productApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavouriteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    if(isFavourite){
      const data = {
        favouriteable_id: favouriteable_id,
        favouriteable_type: favouriteable_type,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );   

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.favouritePeopleApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data: data })
      );
    }else{
      let id = favouriteable_id;
      if(favouriteable_type.toLowerCase().includes("profile")){
        const favPeople = this.state.favPeople.find(x => x.favouriteable_id === id);
        id = favPeople?.id || favouriteable_id;
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeDelete
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.favouritePeopleApiEndPoint}/${id}`
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  listProfileData = () => {
    const { minRate, maxRate, advanceSearchText, selectedCountries, selectedProfessions, selectedSeniorityLevels, selectedSkills, selectedWorkTypes } = this.state;
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileDataApiCallId = requestMessage.messageId;
    let endpoint = `${configJSON.profileListApiEndPoint}?`
    if(!!advanceSearchText){
      endpoint = `${endpoint}query=${advanceSearchText}`
    }

    if(selectedCountries.length > 0) {
      selectedCountries.forEach((country: string) => {
        endpoint = `${endpoint}&country[]=${country}`
      });
    }

    if(selectedProfessions.length > 0) {
      endpoint = `${endpoint}&profession=${selectedProfessions.join(",")}`
    }

    if(selectedSeniorityLevels.length > 0) {
      selectedSeniorityLevels.forEach((seniority_level: string) => {
        endpoint = `${endpoint}&seniority_level[]=${seniority_level}`
      });
    }

    if(selectedSkills.length > 0) {
      endpoint = `${endpoint}&skill=${selectedSkills.join(",")}`
    }

    if(selectedWorkTypes.length > 0) {
      endpoint = `${endpoint}&work=${selectedWorkTypes.join(",")}`
    }

    if((minRate !== undefined) && (maxRate !== undefined)){
      endpoint = `${endpoint}&hourly_rate_from=${minRate}&hourly_rate_to=${maxRate}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  fetchProfessionsList = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfessionsAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfessionsListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  fetchSeniorityLevels = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSeniorityLevelAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSeniorityListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  fetchSkills = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSkillsAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSkilsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  fetchWorkTypes = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWorkTypesAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWorkTypesListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  giveFeedback = (id : string) => {
    const headers = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json" 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
        data:{
            attributes: {
                likeable_id: parseInt(id),
                likeable_type: "BxBlockProfile::Profile"
            }
        }
    };

    this.giveFeedbackAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.giveFeedbackApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getAllLikes = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllLikesAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getFavouritePeopleList = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFavouritePeopleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouritePeopleApiEndPoint}?favouriteable_type=BxBlockProfile::Profile`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleThumbsUp = (id : string) => {
    this.giveFeedback(id);
  }
  
  handleMinRateChange = (value: number | undefined) => {
    this.setState({minRate: !Number.isNaN(value) ? value: undefined}, () => this.checkMinMaxRate());
  }

  handleMaxRateChange = (value: number | undefined) => {
    this.setState({maxRate: !Number.isNaN(value) ? value: undefined}, () => this.checkMinMaxRate());
  }

  checkMinMaxRate = () => {
    const { minRate, maxRate } = this.state;
    if((minRate !== undefined) && (maxRate !== undefined) && (minRate < maxRate)){
      this.listProfileData();
    }
  }

  handleClearRateRange = () => {
    this.setState({minRate: undefined, maxRate: undefined}, () => this.listProfileData())
  }

  handleClickClearAll = (refetch?: boolean) => {
    this.setState({
      selectedCountries: [],
      selectedProfessions: [],
      selectedSeniorityLevels: [],
      selectedSkills: [],
      selectedWorkTypes: [],
      minRate: undefined,
      maxRate: undefined
    }, () => refetch ? this.listProfileData() : {});
  }

  handleClickClearFilter = (values: string[], type: string) => {
      this.setState(prevState => ({
        ...prevState,
        [type]: values
      }), () => this.listProfileData())
  }

  handleFavouritePost = (isFavourite: boolean, favouriteable_id: number, profileId: string) => {
    let profileData = [...this.state.profileData];
    let index = profileData.findIndex(x => x.id === profileId);
    let postIndex = profileData[index].attributes.post_images.findIndex(x => x.id === favouriteable_id)
    let updatedPosts = [...profileData[index].attributes.post_images];
    updatedPosts[postIndex]={
      ...updatedPosts[postIndex],
      is_favourite: isFavourite
    }
    profileData[index] = {
      ...profileData[index],
      attributes: {
        ...profileData[index].attributes,
        post_images: [...updatedPosts]
      }
    }
    this.setState({profileData});
    const header = {
      token: localStorage.getItem("token")
    };
    const formData = new FormData();
    formData.append("id", favouriteable_id.toString());
    const requestMessager = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postFavouriteApiCallId = requestMessager.messageId;
  
    requestMessager.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessager.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessager.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
  
    requestMessager.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouritePostApiEndPoint
    );
  
    runEngine.sendMessage(requestMessager.id, requestMessager);
  }

  handleLikePost = async (isLike:boolean, postId: number, profileId: string)=> {
    let profileData = [...this.state.profileData];
    let index = profileData.findIndex(x => x.id === profileId);
    let postIndex = profileData[index].attributes.post_images.findIndex(x => x.id === postId)
    let updatedPosts = [...profileData[index].attributes.post_images];
    updatedPosts[postIndex]={
      ...updatedPosts[postIndex],
      is_like: isLike
    }
    profileData[index] = {
      ...profileData[index],
      attributes: {
        ...profileData[index].attributes,
        post_images: [...updatedPosts]
      }
    }
    this.setState({profileData});
    const header = {
      token: localStorage.getItem("token")
    };
    const formData = new FormData();
    formData.append("id", postId.toString());
    const requestMessager = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postLikeApiCallId = requestMessager.messageId;
  
    requestMessager.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessager.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessager.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
  
    requestMessager.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likePostEndPoint
    );
  
    runEngine.sendMessage(requestMessager.id, requestMessager);
  }

  handleChangeFilter = (values: string[], fieldName: string) =>{
      this.setState(prevState => ({
        ...prevState,
        [fieldName]: values
      }),() => this.listProfileData())
  }

  handleShareData = (event: React.ChangeEvent<{}>, value: OptionType[]) => {
    this.setState({ shareData: value });
  }

  handleChangeChatMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({chatMessage: event.target.value});
  }
  handleBackButton = () => {
    this.setState(prevState => ({...prevState, isShareModalOpen: !prevState.isShareModalOpen, isChatModalOpen: !prevState.isChatModalOpen}));
  }

  handleClickShareButton = (event: React.MouseEvent<SVGSVGElement | HTMLButtonElement>, type: string, id: string , profileId?: string) => {
    event.stopPropagation();
    this.setState(prevState => {
      let updatedState = {
        ...prevState, 
        isShareModalOpen: !prevState.isShareModalOpen
      }
      if(id){
        if(type === "post"){
          updatedState.shareLink = window.location.href.split("/",3).join("/")+`/Profiles/${profileId}?postId=${id}`
        }else{
          updatedState.shareLink = window.location.href.split("/",3).join("/")+`/Profiles/${id}`
        }
      }
      return updatedState;
    });
  }

  handleClickChatButton = () => {
    this.setState(prevState => ({...prevState, isChatModalOpen: !prevState.isChatModalOpen, isShareModalOpen: !prevState.isShareModalOpen}));
  }

  handleSendChatButton = () => {
    this.handleChatSend();
  }

  handleChatSend = () => {
    const shareLink = this.state.shareLink;
    const parsedUrl = new URL(this.state.shareLink);
    const profileId = parsedUrl.pathname.split("/").pop();
    const postId = parsedUrl.searchParams.get("postId");
    const ids = this.state.shareData.map(user => user.account_id);
    const type = shareLink.toLowerCase().includes("post") ? "post": "profile"
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    let body =  {
        user_id: ids,
        message: this.state.chatMessage,
        ...(type === "post" ? {post_id: postId}: {profile_id: profileId}),
      }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if(type === "post"){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sharePostEmailApiEndPoint
      );
    }else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.shareProfileEmailApiEndPoint
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.shareChatApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseButton = () => {
    this.setState({isChatModalOpen: false, isShareModalOpen: false, shareData: [], shareLink:"", isChatSend: false, chatMessage: ""});
  }

  handleScroll = async () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    const touchedBottom = windowHeight + scrollTop >= scrollHeight;
    if (touchedBottom) {
      const speed = await measureInternetSpeed();
      const speedInSeconds =  speed / 1000;
      const fileSizeInBytes = 1024 * 1024;
      const estimatedSpeedMbps = fileSizeInBytes / speedInSeconds / 125000;
      const slowInternet = estimatedSpeedMbps && estimatedSpeedMbps <= 30;
      if(slowInternet){
        this.setState({needLoadMoreBtn:true});
      }
      else{
        this.handleLoadMoreClick();
      }
    }
  };

  handleLoadMoreClick = () => {
    this.setState({isLoading: true})
    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        count: prevState.count + 6 ,
        isLoading: false,
        needLoadMoreBtn: false
      }));
    }, 1000);
  };

  handleNavigateToProfile = (profileId: string) => {
    this.props.navigation.navigate("Profiles", {id: profileId})
  }
  // Customizable Area End
}
