import React, { useState } from "react";
// Customizable Area Start
import {
  Typography, IconButton, Input, InputAdornment,
  Box
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import "../../../customisableuserprofiles/src/ProfileInfo.css";
import Select, { components } from "react-select";
import { Country } from 'country-state-city';
import { searchIcon, poundIcon } from "../../../customisableuserprofiles/src/assets";
import Control from "react-select/src/components/Control";
const seniorityTypes = [
  { label: "Entry", value: "entry" },
  { label: "Junior", value: "junior" },
  { label: "Mid-Level", value: "mid_level" },
  { label: "Senior", value: "senior" },
];
const workTypes = [
  { label: "Permanent", value: "permanent" },
  { label: "Internship", value: "internship" },
  { label: "Contract", value: "contract" },
  { label: "Full-Time", value: "full_time" },
  { label: "Part-Time", value: "part_time" },
  { label: "On site", value: "on_site" },
  { label: "Remote", value: "remote" },
  { label: "Freelance", value: "freelance" },
];
const professionTypes = [
  { label: "Artist", value: "artist" },
  { label: "Exterior Design", value: "exterior_design" },
  { label: "Interior Design", value: "interior_design" },
  { label: "Product Design", value: "product_design" },
  { label: "Industrial Design", value: "industrial_design" },
  { label: "UI/UX Design", value: "ui/ux design" },
  { label: "Project Management", value: "project_management" },
  { label: "CMF Design", value: "cmf_design" },
  { label: "Graphic", value: "graphic" },
  { label: "CGI", value: "cgi" },
  { label: "Brand", value: "brand" },
  { label: "Strategy", value: "strategy" },
  { label: "Animation", value: "animation" },
  { label: "Visualisation", value: "visualisation" },
];
const skillTypes = professionTypes;
const customControl = (props: any) => {
  return (
    <div style={{ position: 'relative' }}>
     <img
        src={searchIcon}
        style={{
          position: 'absolute',
          left: 10,
          top: '50%',
          transform: 'translateY(-50%)',
          height: 20,
          width: 20,
          zIndex: 1,
        }}
      />
     <components.Control
        {...props}
        styles={{
          control: (base:any) => ({
            ...base,
            paddingLeft: '40px'
          }),
        }}
      />
    </div>
  );
};
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledInput = styled(Input)({
"& .MuiInputBase-input":{
  width:"auto !important"
}
})

interface ILabel{
  label:string,value:string
}

interface advanceSearchObj{
  professional:ILabel
  seniority:ILabel;
  workType:string;
  skill:string;
  country:string;
  minRate:string;
  maxRate:string;
}
// Customizable Area End

export const AdvanceSearch = () => {
  const countries = Country.getAllCountries().map(state => ({ value: state.isoCode, label: state.name }));
  const [advanceSearchValues,setAdvanceSearchValues]=useState<advanceSearchObj>();
  const handleProfessionalChange = (selectedOptions:any) => {
    setAdvanceSearchValues((prevValues:any) => ({
      ...prevValues,
      professional: selectedOptions
    }));
  };
  const handleSeniorityChange = (selectedOptions:any) => {
    setAdvanceSearchValues((prevValues:any) => ({
      ...prevValues,
      seniority: selectedOptions
    }));
  };

  // Customizable Area Start
  return (
    <ThemeProvider theme={theme}>
      <div  style={{width: "100%"}}>
        {/* Customizable Area Start  */}
        <StyledInput style={{ border: '1px solid #DFDFDF', padding: 12 }}
          data-test-id="advanceSearchId"
          startAdornment={
            <InputAdornment
              position="start"
              style={{
                width: 40,
                marginLeft: -9,
              }}
            >
              <IconButton>
                <img
                  src={searchIcon}
                  style={{
                    height: 20,
                    width: 20,
                    padding: 2
                  }}
                />
              </IconButton>
            </InputAdornment>
          } disableUnderline fullWidth placeholder="Name, company, position"></StyledInput>
        <Typography style={{ fontFamily: 'Silika-Medium', marginTop: 20 }}>Filters:</Typography>
        <StyledBox style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: 20, gap: '10px', marginBottom: 80 }}>
          <Select
             placeholder="Profession"
             components={{ Control: customControl }}
                  data-test-id="professionMenu"
                    styles={{
                      menu: (base) => {
                        return {
                          ...base,
                          borderTopRightRadius: '0px',
                          paddingRight: 20,
                          borderTopLeftRadius: '0px',
                          border: 'none',
                          borderTop: 'none',
                          marginTop: 8,
                          color: 'black',
                          boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                          paddingTop: 5,
                          paddingLeft: 20,
                        };
                      },
                      placeholder: (provided) => ({
                        ...provided,
                        marginLeft: 10,
                      }),
                      singleValue: (base) => {
                        return {
                          ...base, color: 'black',
                        }
                      },
                      option: (provided, state) => {
                        return {
                          ...provided,
                          backgroundColor: state.isSelected ? 'black' : 'inherit',
                          '&:hover': {
                            backgroundColor: '#9B9B9D',
                          },
                        };
                      },
                      dropdownIndicator: (base) => {
                        return {
                          ...base, color: 'lightgrey',
                        };
                      },
                      indicatorSeparator: (styles) => ({
                        ...styles,
                        display: "none",
                      }),
                      menuList: (base) => {
                        return {
                          ...base, height: 120,
                          marginTop: 15,
                          zIndex: -1,
                          fontFamily: 'Silika-Light',
                          fontSize: '14px',
                          position: 'relative',
                          textAlign: 'left',
                          top: '-5px',
                          overflowY: 'scroll',
                        };
                      },
                      control: (base) => {
                        return {
                          ...base,
                          backgroundColor: 'white',
                          fontFamily: 'Silika-Light',
                          height: 48,
                          width: 236,
                          borderColor: '#BEBEBE',
                          paddingLeft:"25px"
                        }
                      },
        
        
                    }}
                    options={professionTypes}
                    isSearchable={false}
                    isClearable={false}
                    isRtl={false}
                    value={professionTypes.find(
                      (option) => option.value === advanceSearchValues?.professional?.value
                    )}
                    onChange={(selectedOption) =>
                        handleProfessionalChange(
                        selectedOption as { label: string; value: string }
                      )
                    }
                  />
                  <Select
             placeholder="seniority"
             components={{ Control: components.Control }}
                  data-test-id="seniorityMenu"
                  styles={{
                    menu: (base) => {
                      return {
                        ...base, paddingTop: 5,
                        borderTopRightRadius: '0px',
                        paddingRight: 20,
                        color: 'black', border: 'none',
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                        borderTop: 'none', marginTop: 8,
                        borderTopLeftRadius: '0px', paddingLeft: 20,
                      };
                    },
                    singleValue: (base) => {
                      return { ...base, color: 'black', }
                    },
                    dropdownIndicator: (base) => {
                      return {
                        ...base, color: 'lightgrey',
                      };
                    },
                    control: (base) => {
                      return {
                        ...base, backgroundColor: 'white',
                        fontFamily: 'Silika-Light',
                        height: 48,
                        width: 118,
                        borderColor: '#BEBEBE'
                      }
                    },
                    option: (provided, state) => {
                      return {
                        ...provided,
                        backgroundColor: state.isSelected ? 'black' : 'inherit',
                        '&:hover': {
                          backgroundColor: '#9B9B9D',
                        },
                      };
                    },
                    indicatorSeparator: (styles) => ({
                      ...styles,
                      display: "none",
                    }),
                    menuList: (base) => {
                      return {
                        ...base,
                        marginTop: 15,
                        zIndex: -1,
                        height: 120,
                        fontFamily: 'Silika-Light',
                        fontSize: '14px',
                        overflowY: 'scroll',
                        textAlign: 'left',
                        position: 'relative',
                        top: '-5px',
                      };
                    },
                  }}
                    options={seniorityTypes}
                    isSearchable={false}
                    isClearable={false}
                    isRtl={false}
                    value={seniorityTypes.find(
                      (option) => option.value === advanceSearchValues?.seniority?.value
                    )}
                    onChange={(selectedOption) =>
                   handleSeniorityChange(
                        selectedOption as { label: string; value: string }
                      )
                    }
                  />
          <Select
            data-test-id="workTypeMenu"
            placeholder="Work Type"
            options={workTypes}
            hideSelectedOptions
            isMulti
            controlShouldRenderValue={false}
            styles={{
              singleValue: (base) => {
                return { ...base, color: 'black', }
              },
              menu: (base) => {
                return {
                  ...base, borderTopRightRadius: '0px',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                  borderTop: 'none',
                  marginTop: 8,
                  paddingRight: 20,
                  color: 'black',
                  paddingTop: 5,
                  paddingLeft: 20,
                  borderTopLeftRadius: '0px',
                  border: 'none',
                };
              },
              option: (provided, state) => {
                return {
                  ...provided,
                  backgroundColor: state.isSelected ? 'black' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#9B9B9D',
                  },
                };
              },

              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none"
              }),
              dropdownIndicator: (base) => {
                return {
                  ...base, color: 'lightgrey',
                };
              },
              control: (base) => {
                return {
                  ...base,
                  backgroundColor: 'white',
                  fontFamily: 'Silika-Light',
                  height: 48,
                  width: 168,
                  borderColor: '#BEBEBE'
                }
              },

              menuList: (base) => {
                return {
                  ...base,
                  overflowY: 'scroll',
                  textAlign: 'left',
                  marginTop: 15,
                  zIndex: -1,
                  height: 120,
                  fontFamily: 'Silika-Light',
                  fontSize: '14px',
                  position: 'relative',
                  top: '-5px',
                };
              },
            }}
            name="country"
            isClearable={false}
            isSearchable={false}
            isRtl={false}
          />
          <Select
            isMulti
            data-test-id="skillsSelect"
            controlShouldRenderValue={false}
            options={skillTypes}
            components={{ Control: customControl }}
            placeholder="Skills"
            styles={{
              menu: (base) => {
                return {
                  ...base, paddingRight: 20,
                  color: 'black',
                  borderTopRightRadius: '0px',
                  borderTopLeftRadius: '0px',
                  border: 'none',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                  borderTop: 'none',
                  marginTop: 8,
                  paddingTop: 5,
                  paddingLeft: 20,
                };
              },
              placeholder: (provided) => ({
                ...provided, marginLeft: 35,
              }),
              singleValue: (base) => {
                return {
                  ...base,
                  color: 'black',
                }
              },
              option: (provided, state) => {
                return {
                  ...provided,
                  backgroundColor: state.isSelected ? 'black' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#9B9B9D',
                  },
                };
              },
              dropdownIndicator: (base) => {
                return {
                  ...base, color: 'lightgrey',
                }
              },
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none",
              }
              ),
              control: (base) => {
                return {
                  ...base,
                  backgroundColor: 'white',
                  borderColor: '#BEBEBE',
                  fontFamily: 'Silika-Light',
                  height: 48,
                  width: 218,
                }
              },

              menuList: (base) => {
                return {
                  ...base, marginTop: 15,
                  textAlign: 'left',
                  position: 'relative',
                  fontFamily: 'Silika-Light',
                  fontSize: '14px',
                  zIndex: -1,
                  height: 120,
                  overflowY: 'scroll',
                  top: '-5px',
                };
              },
            }}
            name="skills"
            isSearchable={false}
            isClearable={false}
            isRtl={false}
          />
          <Select
            data-test-id='selectCountry'
            placeholder="Country"
            controlShouldRenderValue={false}
            options={countries}
            styles={{

              singleValue: (base) => {
                return { ...base, color: 'black', }
              }, control: (base) => {
                return {
                  ...base,
                  width: 219,
                  borderColor: '#BEBEBE',
                  fontFamily: 'Silika-Light',
                  height: 48,
                  backgroundColor: 'white',
                }
              },

              menu: (base) => {
                return {
                  ...base,
                  marginTop: 8,
                  paddingTop: 5,
                  borderTopLeftRadius: '0px',
                  border: 'none',
                  paddingLeft: 20,
                  borderTopRightRadius: '0px',
                  paddingRight: 20,
                  color: 'black',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 8px',
                  borderTop: 'none',
                };
              },
              option: (provided, state) => {
                return {
                  ...provided,
                  backgroundColor: state.isSelected ? 'black' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#9B9B9D',
                  },
                };
              },
              dropdownIndicator: (base) => {
                return {
                  ...base, color: 'lightgrey',
                };
              },
              indicatorSeparator: (styles) => ({
                ...styles,
                display: "none",
              }),


              menuList: (base) => {
                return {
                  ...base,
                  height: 120,
                  fontFamily: 'Silika-Light',
                  fontSize: '14px',
                  overflowY: 'scroll',
                  marginTop: 15,
                  top: '-5px',
                  zIndex: -1,
                  textAlign: 'left',
                  position: 'relative',
                };
              },
            }}
            name="country"
            isSearchable
            isClearable={false}
            isRtl={false}
          />
          <Input style={{ border: '1px solid #BEBEBE', padding: 5, borderRadius: 5, height:48, width: 135 }}
            startAdornment={
              <InputAdornment
                position="start"
                style={{
                  width: 40,
                  marginLeft: 0,
                }}
              >
                <img
                  src={poundIcon}
                  style={{
                    height: 25,
                    width: 25,
                    padding: 2
                  }}
                />
              </InputAdornment>
            } disableUnderline
            placeholder="Min-Rate"></Input>
          <Input style={{ border: '1px solid #BEBEBE', padding: 5, borderRadius: 5,height:48, width: 135 }}
            startAdornment={
              <InputAdornment
                position="start"
                style={{
                  width: 40,
                  marginLeft: 0,
                }}
              >
                <img
                  src={poundIcon}
                  style={{
                    height: 25,
                    width: 25,
                    padding: 2
                  }}
                />
              </InputAdornment>
            } disableUnderline
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*', // Allows only numbers
            }}

            placeholder="Max-Rate"></Input>
        </StyledBox>
         {/* Customizable Area End  */}
      </div>
    </ThemeProvider>
  );
  // Customizable Area End
}

// Customizable Area Start

// Customizable Area End
