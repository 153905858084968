import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import _ from "lodash";
export const configJSON = require("./config");
import { measureInternetSpeed } from "../../../components/src/measureInternet.web";
import { FavouritePeople, FavouritePeopleResponse, FavouriteProject, FavouriteProjectResponse, Favourite, OptionType, Profile, IListFavourites } from "./types";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  activeTab: string;
  favourites: Favourite[];
  favouritesProjects: FavouriteProject[];
  favouritesPeople: FavouritePeople[];
  favouritesJobs: any[];
  profiles: OptionType[];
  shareData:OptionType[];
  chatMessage: string;
  isShareModalOpen: boolean;
  shareLink: string;
  isChatSend: boolean;
  isChatModalOpen: boolean;
  searchKey: string;
  isLoading: boolean;
  count: number;
  needLoadMoreBtn: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FavouritesControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  listFavouritesApi: string | null = "";
  listProfilesApiCallId: string | null = "";
  deleteFavouriteEventApiCallId: string | null = "";
  listFavouriteProjectsApiCallId: string | null = "";
  listFavouritePeopleApiCallId: string | null= "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      activeTab: "projects",
      favourites: [],
      favouritesProjects: [],
      favouritesJobs: [],
      favouritesPeople: [],
      profiles: [],
      shareData: [],
      chatMessage:"",
      isShareModalOpen: false,
      isChatModalOpen: false,
      isChatSend: false,
      searchKey: "",
      shareLink: "",
      isLoading: false,
      count: 6,
      needLoadMoreBtn: false
    }
    this.handleScroll = this.handleScroll.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.listFavouriteEvents();
    this.fetchProfiles();
    this.fetchFavouriteProjects();
    this.fetchFavouritePeople();
    window.addEventListener("scroll", this.handleScroll);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseType = getName(MessageEnum.RestAPIResponceMessage);
    if(responseType === message.id){
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if(!errorResponse){
        switch(apiRequestCallId){
          case this.listFavouritesApi: {
            this.handleListFavouritesResponse(responseJson, errorResponse);
            break;
          }

          case this.listProfilesApiCallId:{
            this.setState({profiles: responseJson.data.map((profile: Profile) => ({firstName: profile.attributes.first_name, lastName: profile.attributes.last_name, photo: profile.attributes.photo }))});
            break;
          }

          case this.deleteFavouriteEventApiCallId:{
            this.listFavouriteEvents();
            break;
          }

          case this.listFavouriteProjectsApiCallId:{
            this.handleListProjectsResponse(responseJson);
            break;
          }

          case this.listFavouritePeopleApiCallId:{
            this.handleListPeopleResponse(responseJson);
            break;
          }

          default: {
            return;
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleListProjectsResponse = (response : {data: FavouriteProjectResponse[], errors?: Array<Record<string, any>>;}) => {
    if(!response.errors){
      let favouritesProjects = [] as FavouriteProject[];
      for(const item of response.data){
        let updatedItem = {
          postId: item.id,
          image : item.attributes.favouriteable.data.attributes.images.at(0)?.url,
          title: item.attributes.favouriteable.data.attributes.project_title,
          postCreator: item.attributes.favouriteable.data.attributes.profile.data.attributes.first_name,
          favouritableId: item.attributes.favouriteable_id
        }
        favouritesProjects.push(updatedItem);
      }
      this.setState({favouritesProjects: favouritesProjects});
    }
  };


  handleListPeopleResponse = (response : {favourites_people: FavouritePeopleResponse[], errors?: Array<Record<string, any>>;}) => {
    if(!response.errors){
      let favouritesPeople = [] as FavouritePeople[];
      for(const item of response.favourites_people.filter((fp:FavouritePeopleResponse) => fp.favourite.data.attributes.favouriteable_type === "BxBlockProfile::Profile")){
        let updatedItem = {
          galleryImages: item.gallery_images.map(img => img.image_url),
          firstName: item.favourite.data.attributes.favouriteable.data.attributes.first_name,
          lastName: item.favourite.data.attributes.favouriteable.data.attributes.last_name,
          favouritableId: item.favourite.data.attributes.favouriteable_id,
          photo: item.favourite.data.attributes.favouriteable.data.attributes.photo,
          headline: item.favourite.data.attributes.favouriteable.data.attributes.headline,
          hourly_rate_from: item.favourite.data.attributes.favouriteable.data.attributes.hourly_rate_from,
          hourly_rate_to: item.favourite.data.attributes.favouriteable.data.attributes.hourly_rate_to,
          country: item.favourite.data.attributes.favouriteable.data.attributes.country,
          county: item.favourite.data.attributes.favouriteable.data.attributes.county,
          city: item.favourite.data.attributes.favouriteable.data.attributes.city,
        }
        favouritesPeople.push(updatedItem);
      }
      this.setState({favouritesPeople: favouritesPeople});
    }
  };


  handleScroll = async () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    const touchedBottom = windowHeight + scrollTop >= scrollHeight;
    if (touchedBottom) {
      const speed = await measureInternetSpeed();
      const speedInSeconds =  speed / 1000;
      const fileSizeInBytes = 1024 * 1024;
      const estimatedSpeedMbps = fileSizeInBytes / speedInSeconds / 125000;
      const slowInternet = estimatedSpeedMbps && estimatedSpeedMbps <= 30;
      if(slowInternet){
        this.setState({needLoadMoreBtn:true});
      }
      else{
        this.handleLoadMoreClick();
      }
    }
  };
  handleChangeTab = (tab: string) => {
    this.setState({activeTab: tab, favourites: [], searchKey: ""});
    if(tab === "events"){
      this.listFavouriteEvents();
    }
  }

  listFavouriteEvents = () => {
    const { searchKey } = this.state;
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listFavouritesApi = requestMessage.messageId;

    let endpoint = `${configJSON.getFavouritesApiEndPoint}${configJSON.eventsType}`;

    if(searchKey){
      endpoint = `${endpoint}&query=${searchKey}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  fetchFavouriteProjects = () => {
    const { searchKey } = this.state;
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listFavouriteProjectsApiCallId = requestMessage.messageId;

    let endpoint = `${configJSON.getFavouritesApiEndPoint}${configJSON.projectsType}`;

    if(searchKey){
      endpoint = `${endpoint}&query=${searchKey}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleListFavouritesResponse = async (responseJson: IListFavourites, errorResponse? : object) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ favourites: responseJson.data });
    }else if(responseJson.errors){
      if(responseJson.errors.length > 0 && responseJson.errors[0].token && responseJson.errors[0].token.toLowerCase().includes("token")){
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.loginRoute);
        runEngine.sendMessage(message.id, message);
      }
    }
    else{
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  fetchProfiles = () => {
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listProfilesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchProfiles
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShareData = (event: React.ChangeEvent<{}>, value: OptionType[]) => {
    this.setState({ shareData: value });
  }

  handleChangeChatMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({chatMessage: event.target.value});
  }
  handleBackButton = () => {
    this.setState(prevState => ({...prevState, isShareModalOpen: !prevState.isShareModalOpen, isChatModalOpen: !prevState.isChatModalOpen}));
  }

  handleClickShareButton = (event: React.MouseEvent<HTMLButtonElement>, eventId?: string) => {
    event.stopPropagation();
    this.setState(prevState => {
      let updatedState = {
        ...prevState, 
        isShareModalOpen: !prevState.isShareModalOpen
      }
      if(eventId){
        updatedState.shareLink = window.location.href.split("/",3).join("/")+"/EventView?id="+`${eventId}`
      }
      return updatedState;
    });
  }

  handleClickChatButton = () => {
    this.setState(prevState => ({...prevState, isChatModalOpen: !prevState.isChatModalOpen, isShareModalOpen: !prevState.isShareModalOpen}));
  }

  handleSendChatButton = () => {
     this.setState({isChatModalOpen: false, isShareModalOpen: true, isChatSend: true});
  }
  handleCloseButton = () => {
    this.setState({isChatModalOpen: false, isShareModalOpen: false, shareData: [], shareLink:""});
  }

  handleDeleteFavourite = (event: React.MouseEvent<HTMLButtonElement>, id:string) => {
    event.stopPropagation();
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFavouriteEventApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFavouriteEvent}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchKeyDebounce = _.debounce(() => {
    const { activeTab } = this.state;
    switch(activeTab){
      case "events":{
        this.listFavouriteEvents();
        break;
      }

      case "projects":{
        this.fetchFavouriteProjects();
        break;
      }

      case "people":{
        this.fetchFavouritePeople();
        break;
      }

      default:{
        break;
      }
    }
  },1000);

  handleChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchKey: event.target.value}, this.handleSearchKeyDebounce);
  };

  navigateToPage = (idEvent: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EventView");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), idEvent);
    userNavMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(userNavMsg);
  };

  navigateToPostDetailsPage = (id: number) => {
    this.props.navigation.navigate("ListPost", { id });
  };

  handleLoadMoreClick = () => {
    this.setState({isLoading: true})
    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        count: prevState.count + 6 ,
        isLoading: false,
        needLoadMoreBtn: false
      }));
    }, 1000);
  };
  fetchFavouritePeople = () => {
    const { searchKey } = this.state;
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listFavouritePeopleApiCallId = requestMessage.messageId;

    let endpoint = configJSON.fetchFavouritePeopleApiEndPoint;

    if(searchKey){
      endpoint = `${endpoint}&query=${searchKey}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}