import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as IMG_CONST from "./assets";
import { ChangeEvent } from "react";
interface FileValue {
  name: string;
  file: File;
  type: string;
}

interface TableRowData {
  name: string;
  status: string;
  actions: string;
  actioneeName: string;
  actioneeImg: string;
  deadline: string;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  showMessage:boolean;
  selectedFile: File | null;
  previewUrl: string | null;
  files: Array<FileValue>;
  showDetailSection:boolean
  roleAttachmentFiles: Array<FileValue>;
  roleInvoiceFiles: Array<FileValue>;
  isCreate: boolean;
  tableRowData: Array<TableRowData>;
  expandedRowIndex: number | null;
  openTerminateModal: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      showMessage:false,
      selectedFile: null,
      previewUrl: null,
      files: [],
      showDetailSection:false,
      roleAttachmentFiles: [],
      roleInvoiceFiles: [],
      isCreate: false,    
      expandedRowIndex: null,
      openTerminateModal: false,
      tableRowData: [
        {
        name: "01 Car Circuits",
        status: "Paid",
        actions: "Approve / Reject",
        actioneeName: "Joel G.",
        actioneeImg: IMG_CONST.userImg,
        deadline: "10 Feb 2024",
      },
      {
        name: "02 Frontal Lights",
        status: "Paid",
        actions: "Make Payment",
        actioneeName: "Martha S",
        actioneeImg: IMG_CONST.userImg,
        deadline: "08 Aug 2024",
      }
    ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    this.setState((prevState) => ({
      files: [...prevState.files, ...filesWithTypes],
    }));
  };

  navigateToDetailSection=()=>{
    this.setState({showDetailSection:true})
  }
  onCreateClick = () => {
    this.setState({ isCreate: !this.state.isCreate });
  };

  handleRoleAttachmentFile = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    this.setState((prevState) => ({
      roleAttachmentFiles: [...prevState.roleAttachmentFiles, ...filesWithTypes],
    }));
  };

  handleRoleInvoiceFile = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    this.setState((prevState) => ({
      roleInvoiceFiles: [...prevState.roleInvoiceFiles, ...filesWithTypes],
    }));
  };

  handleRowClick = (index: number) => {
    this.setState((prevState) => ({
      expandedRowIndex: prevState.expandedRowIndex === index ? null : index,
    }));
  };

  handleTerminateModal = () => {
    this.setState((prevState) => ({
      openTerminateModal: !prevState.openTerminateModal,
    }));
  }
  // Customizable Area End
}
