import React from "react";
// Customizable Area Start
import HeaderNavbar  from '../../../components/src/Navbar'
import DashboradProject from "../../../components/src/DashboardProject";
import { AccordionDetails, Box, Button, Modal,createTheme,Container, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, Table } from "@material-ui/core";
import * as IMG_CONST from "./assets";
import AddIcon from "@material-ui/icons/Add";
const theme = createTheme({
  typography: {
    fontFamily: "Silka",
  },
});
const StyledContainer = styled(Box)({
  "& .parent": {
    disply: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    padding:"80px",
    [theme.breakpoints.down('sm')] :{
      width: "100%",
      padding: "24px"
    }
  },
  "& .headding": {
    marginBottom: 95,
    fontFamily: "silika-Medium",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    fontWeight: 500,
    fontSize: "32px",
    [theme.breakpoints.down('sm')] :{
      marginBottom : 24,
      fontSize: "20px",
    }
  },
  '& .pera':{
    fontFamily: "silika-Medium",
    fontWeight: 500,
    fontSize: "32px",
    textAlign:"center"
  },
  '& Button':{
    fontFamily: "silika-Medium",
    fontSize: "16px",
    width:"200px",
    height:"32px",
    backgroundColor:"#0D0C22",
    borderRadius:"2px",
    padding:"16px",
    textTransform: 'capitalize',
    color: "#FFFFFF",
   '&:hover': {
        backgroundColor: "#0D0C22",
        boxShadow: 'none',
    },  
  }
});

import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ShareIcon from "@mui/icons-material/Share";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
  }

  renderOverView = () => {
    return (
      <OverViewBox>
        <Box className="overHeadingBox">
          <Typography className="overViewTxt">{webConfigJSON.overviewTitle}</Typography>
          <img src={IMG_CONST.editImage} alt={webConfigJSON.editImageAlt} />
        </Box>
        <Box style={{
          margin: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }}>
          <Typography className="jobTitle">{webConfigJSON.jobTitle}</Typography>

          <Box className="infoBox">
            <Box className="infoItem">
              <Typography className="label">{webConfigJSON.datesLabel}</Typography>
              <Typography className="value">{webConfigJSON.datesValue}</Typography>
            </Box>
            <Box className="infoItem">
              <Typography className="label">{webConfigJSON.budgetLabel}</Typography>
              <Typography className="value">{webConfigJSON.budgetValue}</Typography>
            </Box>
          </Box>

          <Typography className="description">
            {webConfigJSON.dummyText}
          </Typography>
          <Box>
            <Typography className="attachmentsTitle">{webConfigJSON.attachmentsTitle}</Typography>
            <AttachmentContainer>
              {this.state.files.map((file, index) => (
                <Box className="fileBox" key={index}>
                  <Box className="filePreview">
                    <img src={file.type === "pdf" ? IMG_CONST.pdfType : IMG_CONST.imageType} />
                  </Box>
                  <Typography className="fileName">{file.name}</Typography>
                </Box>
              ))}

              <label className="uploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={this.handleFileChange}
                  style={{ display: "none" }}
                  data-test-id="fileChangeTextId"
                />
                <AddIcon className="addIcon" />
              </label>
            </AttachmentContainer>
          </Box>
        </Box>
      </OverViewBox>
    );
  };

  renderRoles = () => {
    return (
      <RolesBox>
        <Box className="roleHeadingBox">
          <Typography className="roleViewTxt">{webConfigJSON.rolesTitle}</Typography>
          <AddIcon className="addIcon" />
        </Box>

        <Box className="roleInnerBox">
          <Typography className="createRoleText">{webConfigJSON.createRoleText}</Typography>
          <Typography className="roleDescription">{webConfigJSON.dummyText}</Typography>
          <Button data-test-id="createTestId" className="createBtn" onClick={this.onCreateClick}>{webConfigJSON.createButtonText}</Button>
        </Box>
      </RolesBox>
    );
  };

  renderCreateRole = () => {
    return (
      <CreateRolesBox>
        <Box className="createHeadingBox">
          <Box className="headingIconText">
            <FolderOpenIcon />
            <Typography className="createViewTxt">{webConfigJSON.roleName}</Typography>
          </Box>
          <img src={IMG_CONST.editImage} alt={webConfigJSON.editImageAlt} />
        </Box>
        <Box className="mainInnerBox">
          <Box className="contentBox">
            <img src={IMG_CONST.userImg} alt="userImg" className="userImg" />
            <Box className="userInfoBox">
              <Box className="roleInfoBox">
                <Box className="roleInfoText">
                  <Typography className="userName">{webConfigJSON.userName}</Typography>
                  <Box className="roleDetails">
                    <Typography className="roleSubText">{webConfigJSON.role}</Typography>
                    <Typography className="roleSubText">{webConfigJSON.timeAgo}</Typography>
                  </Box>
                </Box>
                <Box className="actionBox">
                  <ShareIcon />
                  <Button className="terminateBtn" data-test-id="modalCloseTestId" onClick={this.handleTerminateModal}>Terminate</Button>
                </Box>
              </Box>

              <Box className="infoSection">
                <Box className="infoRow">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.levelLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.levelValue}</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.locationLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.locationValue}</Typography>
                  </Box>
                </Box>

                <Box className="infoRow">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.typeLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.typeValue}</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.professionsLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.professionsValue}</Typography>
                  </Box>
                </Box>

                <Box className="infoRow borderBottom">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.datesLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.datesValue}</Typography>
                  </Box>
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.rateLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.rateValue}</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">{webConfigJSON.budgetLabel}</Typography>
                    <Typography className="infoValue">{webConfigJSON.budgetValue}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography className="descriptionRole">
            {webConfigJSON.dummyText}
          </Typography>

          <Box style={{ margin: "50px 0px", borderTop: "1px solid #DFDFDF" }}>
            <Typography className="roleAttachmentsTitle">{webConfigJSON.attachmentsTitle}</Typography>
            <RoleAttachmentContainer>
              {this.state.roleAttachmentFiles.map((file, index) => (
                <Box className="roleAttachmentFileBox" key={index}>
                  <Box className="roleAttachmentFilePreview">
                    <img src={file.type === "pdf" ? IMG_CONST.pdfType : IMG_CONST.imageType} />
                  </Box>
                  <Typography className="roleAttachmentFileName">{file.name}</Typography>
                </Box>
              ))}

              <label className="roleAttachmentUploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={this.handleRoleAttachmentFile}
                  style={{ display: "none" }}
                  data-test-id="roleAttachmentFileChangeTextId"
                />
                <AddIcon className="addIcon" />
              </label>
            </RoleAttachmentContainer>
          </Box>

          <Box style={{ borderTop: "1px solid #DFDFDF" }}>
            <Typography className="roleInvoiceTitle">{webConfigJSON.invoicesTitle}</Typography>
            <RoleInvoiceContainer>
              {this.state.roleInvoiceFiles.map((file, index) => (
                <Box className="roleInvoiceFileBox" key={index}>
                  <Box className="roleInvoiceFilePreview">
                    <img src={file.type === "pdf" ? IMG_CONST.pdfType : IMG_CONST.imageType} />
                  </Box>
                  <Typography className="roleInvoiceFileName">{file.name}</Typography>
                </Box>
              ))}

              <label className="roleInvoiceUploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={this.handleRoleInvoiceFile}
                  style={{ display: "none" }}
                  data-test-id="roleInvoiceFileChangeTextId"
                />
                <AddIcon className="addIcon" />
              </label>
            </RoleInvoiceContainer>
          </Box>
        </Box>
      </CreateRolesBox>
    );
  };

  renderDeliverable = () => {
    return (
      <DeliverableBox>
        <Box className="createHeadingBox">
          <Typography className="createViewTxt">{webConfigJSON.deliverablesHeading}</Typography>
        </Box>
        <Box style={{ margin: "20px 40px" }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{webConfigJSON.deliverable}</TableCell>
                  <TableCell>{webConfigJSON.status}</TableCell>
                  <TableCell>{webConfigJSON.action}</TableCell>
                  <TableCell>{webConfigJSON.actionee}</TableCell>
                  <TableCell>{webConfigJSON.deadline}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableRowData.map((rowValue, index) => (
                  <React.Fragment key={index}>
                    <TableRow onClick={() => this.handleRowClick(index)} data-test-id={`rowCLickTestid_${index}`}>
                      <TableCell className="rowsValue">{rowValue.name}</TableCell>
                      <TableCell className="rowsValue">
                        <Box className="statusBox">
                          <Typography>{rowValue.status}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box className="actionBox">
                          <Typography>{rowValue.actions}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box className="actioneeBox">
                          <img style={{ width: "30px", height: "30px" }} src={rowValue.actioneeImg} alt="Actionee" />
                          <Typography>{rowValue.actioneeName}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography>{rowValue.deadline}</Typography>
                          <img src={IMG_CONST.editImage} alt={webConfigJSON.editImageAlt} />
                        </Box>
                      </TableCell>
                    </TableRow>

                    {this.state.expandedRowIndex === index && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <AccordionDetails>
                            <Typography>{webConfigJSON.dummyTextAccordion}</Typography>
                          </AccordionDetails>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DeliverableBox>
    );
  };

  renderTerminateModal = () => {
    const { openTerminateModal } = this.state;
    return (
      <TerminateModal open={openTerminateModal} onClose={this.handleTerminateModal}>
        <Box className="modalInsideBox">
          <Box className="header">
            <Typography className="headerText">{webConfigJSON.terminateText}</Typography>
          </Box>
          <Box className="content">
            <Typography className="contentText">{webConfigJSON.areYouSureText}</Typography>
          </Box>
          <Box className="actionButtons">
            <Button className="cancelBtn" onClick={this.handleTerminateModal}>
              {webConfigJSON.cancelTxt}
            </Button>
            <Button className="yesBtn" onClick={this.handleTerminateModal}>
              {webConfigJSON.yesTerminateText}
            </Button>
          </Box>
        </Box>
      </TerminateModal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <HeaderNavbar navigation={this.props.navigation} data-test-id="headerNavbar" />
        {!this.state.showDetailSection?
        <StyledContainer>
          <Container maxWidth="xl" className="parent">
            <Typography className="headding">Dashboard</Typography>
            <Box style={{ textAlign: "center" }}>
              <p className="pera">You currently have no active projects.</p>
              <Button>Create new project</Button>
            </Box>
            <Box>
              <DashboradProject navigateToDetailSection={this.navigateToDetailSection}/>
            </Box>
          </Container>
        </StyledContainer>
        : <Box>
        <HeadingBox>
          <Typography className="hideText">Dashboard</Typography>
          <Typography className="hideText">{">"}</Typography>
          <Typography className="showText">0002 Truckers</Typography>
        </HeadingBox>
        {this.state.isCreate ? 
        <>
          {this.renderCreateRole()}
          {this.renderDeliverable()}
          {this.renderTerminateModal()}
        </>
          :
          (
            <>
              {this.renderOverView()}
              {this.renderRoles()}
            </>
          )
        }
      </Box>}
      </>

     
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const HeadingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  margin: "20px",
  "@media (min-width: 600px)": {
    margin: "30px"
  },
  "@media (min-width: 960px)": {
    margin: "50px"
  },
  "@media (min-width: 1280px)": {
    margin: "70px"
  },

  "& .hideText": {
    color: "#9B9B9D",
    fontWeight: 500,
    fontSize: "16px",

    "@media (min-width: 600px)": {
      fontSize: "18px"
    },
    "@media (min-width: 960px)": {
      fontSize: "22px"
    },
    "@media (min-width: 1280px)": {
      fontSize: "30px"
    }
  },

  "& .showText": {
    color: "#0D0C22",
    fontWeight: 500,
    fontSize: "16px",
    "@media (min-width: 600px)": {
      fontSize: "18px"
    },
    "@media (min-width: 960px)": {
      fontSize: "22px"
    },
    "@media (min-width: 1280px)": {
      fontSize: "30px"
    }
  },
});

const OverViewBox = styled(Box)({
  margin: "50px 70px 0px 70px",
  border: "1px solid #DFDFDF",
  "& .overHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .overViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
  "& .jobTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium"
  },
  "& .infoBox": {
    display: "flex",
    flexDirection: "row",
    gap: "60px",
    borderBottom: "1px solid #DFDFDF",
    borderTop: "1px solid #DFDFDF",
    padding: "10px 0px"
  },
  "& .infoItem": {
    display: "flex",
    gap: "15px"
  },
  "& .label": {
    color: "#9B9B9D",
    fontWeight: 500,
    fontSize: "12px",
    fontFamily: "Silika-Medium"
  },
  "& .value": {
    color: "#010101",
    fontWeight: 500,
    fontSize: "13px",
    fontFamily: "Silika-Medium",
    borderBottom: "2px solid #010101",
    display: "inline-block",
    paddingBottom: "2px"
  },
  "& .description": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-light",
    height: "120px",
    borderBottom: "1px solid #DFDFDF",
    marginTop: "10px"
  },
  "& .attachmentsTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    marginBottom: "20px"
  },
  "& .addIcon": {
    color: "#9B9B9B"
  },
  "@media (max-width: 768px)": {
    margin: "0px 20px",

    "& .overViewTxt": {
      fontSize: "12px"
    },
    "& .jobTitle": {
      fontSize: "20px"
    },
    "& .infoBox": {
      flexDirection: "column",
      gap: "20px"
    },
    "& .infoItem": {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%"
    },
    "& .label": {
      fontSize: "10px"
    },
    "& .value": {
      fontSize: "11px"
    },
    "& .description": {
      height: "auto"
    },
    "& .attachmentsTitle": {
      fontSize: "20px"
    }
  },
  "@media (max-width: 480px)": {
    margin: "0px 10px",
    "& .overHeadingBox": {
      height: "30px",
      padding: "0px 5px"
    },
    "& .overViewTxt": {
      fontSize: "10px"
    },
    "& .jobTitle": {
      fontSize: "18px"
    },
    "& .infoBox": {
      flexDirection: "column",
      gap: "15px"
    },
    "& .infoItem": {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%"
    },
    "& .label": {
      fontSize: "9px"
    },
    "& .value": {
      fontSize: "10px"
    },
    "& .description": {
      height: "auto",
      fontSize: "12px"
    },
    "& .attachmentsTitle": {
      fontSize: "18px"
    }
  }
});

const AttachmentContainer = styled(Box)({
  gap: "10px",
  display: "flex",
  overflow: "scroll",
  "& .fileBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "70px"
  },
  "& .filePreview": {
    height: "70px",
    borderRadius: "8px",
    width: "70px",
    border: "1px solid black",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    backgroundColor: "#FFFFFF",
    cursor: "pointer"
  },
  "& .fileName": {
    width: "70px",
    fontWeight: 400,
    color: "#000000",
    fontSize: "10px",
    fontFamily: "Silika-Medium"
  },
  "& .uploadLabel": {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    height: "70px",
    width: "70px",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    border: "1px dashed #9B9B9B",
    cursor: "pointer"
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "15px",
    "& .fileBox, & .uploadLabel": {
      height: "70px",
      width: "70px"
    },
    "& .fileName": {
      fontSize: "11px"
    }
  },
  "@media (max-width: 480px)": {
    flexDirection: "column",
    "& .fileBox, & .uploadLabel": {
      height: "60px",
      width: "60px"
    },
    "& .fileName": {
      fontSize: "10px"
    }
  }
});

const RolesBox = styled(Box)({
  margin: "70px 70px 50px 70px",
  border: "1px solid #DFDFDF",
  "& .roleHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .roleInnerBox": {
    padding: "50px",
    display: "flex",
    gap: "20px",
    flexDirection: "column"
  },
  "& .roleViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
  "& .createRoleText": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",

    "@media (max-width: 768px)": {
      fontSize: "20px"
    },
    "@media (max-width: 480px)": {
      fontSize: "18px"
    }
  },
  "& .roleDescription": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 768px)": {
      fontSize: "12px"
    },
    "@media (max-width: 480px)": {
      fontSize: "12px"
    }
  },
  "& .createBtn": {
    textTransform: "unset",
    width: "105px",
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    cursor: "pointer",
    borderRadius: "1px",
    "&:hover": {
      backgroundColor: "#0D0C22",
      color: "#FFFFFF"
    },
    "@media (max-width: 480px)": {
      width: "100%",
      fontSize: "14px",
      marginTop: "10px"
    }
  },
  "@media (max-width: 768px)": {
    margin: "30px",
    "& .roleInnerBox": {
      padding: "30px"
    }
  },
  "@media (max-width: 480px)": {
    margin: "20px",
    "& .roleInnerBox": {
      flexDirection: "column",
      padding: "20px"
    },
    "& .roleHeadingBox": {
      height: "40px"
    }
  }
});

const CreateRolesBox = styled(Box)({
  margin: "70px",
  border: "1px solid #DFDFDF",

  "@media (max-width: 1000px)": {
    margin: "20px"
  },

  "@media (max-width: 480px)": {
    margin: "10px"
  },
  "& .createHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      height: "45px",
      padding: "0px 15px"
    },
    "@media (max-width: 768px)": {
      height: "40px",
      padding: "0px 10px"
    },
    "@media (max-width: 480px)": {
      height: "35px",
      padding: "0px 5px"
    }
  },
  "& .headingIconText": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  "& .createViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "16px"
    },
    "@media (max-width: 768px)": {
      fontSize: "15px"
    },
    "@media (max-width: 480px)": {
      fontSize: "14px"
    }
  },
  "& .roleAttachmentsTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    margin: "15px 0px"
  },
  "& .roleInvoiceTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    margin: "15px 0px"
  },
  "& .contentBox": {
    display: "flex",
    "@media (max-width: 900px)": {
      flexDirection: "row"
    },
    "@media (max-width: 768px)": {
      flexDirection: "column"
    },
    "@media (max-width: 480px)": {
    }
  },

  "& .mainInnerBox": {
    margin: "40px",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 900px)": {
      margin: "30px"
    },
    "@media (max-width: 768px)": {
      margin: "20px"
    },
    "@media (max-width: 480px)": {
      margin: "15px"
    }
  },
  "& .userImg": {
    height: "180px",
    width: "180px",
    borderRadius: "50%",
    "@media (max-width: 900px)": {
      height: "150px",
      width: "150px"
    },
    "@media (max-width: 768px)": {
      height: "120px",
      width: "120px",
      margin: "0 auto"
    },
    "@media (max-width: 480px)": {
      height: "100px",
      width: "100px",
      margin: "0 auto"
    }
  },
  "& .userInfoBox": {
    width: "100%",
    marginLeft: "20px",
    "@media (max-width: 900px)": {
      marginLeft: "15px"
    },
    "@media (max-width: 768px)": {
      marginLeft: "0",
      marginTop: "10px"
    }
  },
  "& .roleInfoBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      gap: "15px"
    },
    "@media (max-width: 768px)": {
      gap: "10px"
    }
  },
  "& .roleDetails": {
    display: "flex",
    gap: "30px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "5px"
    }
  },
  "& .actionBox": {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    "@media (max-width: 900px)": {
      gap: "15px"
    },
    "@media (max-width: 480px)": {
      gap: "10px"
    }
  },
  "& .terminateBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "15px",
    fontFamily: "Silika-Medium",
    width: "105px",
    backgroundColor: "#0D0C22",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "10px 0px",
    "@media (max-width: 900px)": {
      width: "95px",
      fontSize: "14px"
    },
    "@media (max-width: 768px)": {
      width: "90px",
      fontSize: "13px"
    },
    "@media (max-width: 480px)": {
      width: "85px",
      fontSize: "12px",
      padding: "8px 0"
    }
  },
  "& .userName": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "20px"
    },
    "@media (max-width: 768px)": {
      fontSize: "18px"
    },
    "@media (max-width: 480px)": {
      fontSize: "16px"
    }
  },
  "& .roleSubText": {
    color: "#9B9B9B",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "13px"
    },
    "@media (max-width: 768px)": {
      fontSize: "12px"
    },
    "@media (max-width: 480px)": {
      fontSize: "11px"
    }
  },
  "& .infoSection": {
    margin: "20px 0px",
    "@media (max-width: 900px)": {
      margin: "15px 0"
    },
    "@media (max-width: 768px)": {
      margin: "10px 0",
      flexDirection: "row"
    },
    "& .infoRow": {
      borderTop: "1px solid #DFDFDF",
      padding: "10px 0px",
      display: "flex",
      gap: "50px",
      "@media (max-width: 900px)": {
        gap: "30px",
      },
      "@media (max-width: 768px)": {
        gap: "10px",
      }
    },
    "& .infoLabel": {
      color: "#9B9B9B",
      fontWeight: 500,
      fontSize: "12px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 900px)": {
        fontSize: "12px",
      },
      "@media (max-width: 768px)": {
        fontSize: "11px",
      },
      "@media (max-width: 480px)": {
        fontSize: "10px",
      }
    },
    "& .infoValue": {
      color: "#000000",
      fontWeight: 500,
      fontSize: "12px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 900px)": {
        fontSize: "12px",
      },
      "@media (max-width: 768px)": {
        fontSize: "11px",
      },
      "@media (max-width: 480px)": {
        fontSize: "10px",
      }
    }
  },
  "& .fixedWidth": {
    width: "100%",
    maxWidth: "180px",
    "@media (max-width: 1024px)": {
      maxWidth: "160px"
    },
    "@media (max-width: 768px)": {
      maxWidth: "140px"
    },
    "@media (max-width: 480px)": {
      maxWidth: "120px"
    }
  },
  "& .borderBottom": {
    borderBottom: "1px solid #DFDFDF"
  },
  "& .infoInsideBox": { display: "flex", flexDirection: "row", gap: "10px" },
  "& .descriptionRole": {
    color: "#000000",
    fontSize: "14px",
    fontFamily: "Silika-light",
    "@media (max-width: 480px)": {
      fontSize: "12px"
    }
  }

});

const RoleAttachmentContainer = styled(Box)({
  gap: "10px",
  display: "flex",
  overflow: "scroll",
  "& .roleAttachmentFileBox": {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "70px"
  },
  "& .roleAttachmentFilePreview": {
    height: "70px",
    width: "70px",
    borderRadius: "8px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    marginLeft: "10px",
    cursor: "pointer"
  },
  "& .roleAttachmentFileName": {
    fontSize: "10px",
    width: "70px",
    color: "#000000",
    fontWeight: 400,
    fontFamily: "Silika-Medium"
  },
  "& .roleAttachmentUploadLabel": {
    height: "70px",
    width: "70px",
    border: "1px dashed #9B9B9B",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    display: "flex",
    cursor: "pointer"
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "15px",
    "& .roleAttachmentFileName": {
      fontSize: "11px"
    },
    "& .roleAttachmentFileBox, & .roleAttachmentUploadLabel": {
      width: "70px",
      height: "70px"
    },
  },
  "@media (max-width: 480px)": {
    flexDirection: "column",
    "& .roleAttachmentFileName": {
      fontSize: "10px"
    },
    "& .roleAttachmentFileBox, & .roleAttachmentUploadLabel": {
      width: "60px",
      height: "60px"
    },
  }
});

const RoleInvoiceContainer = styled(Box)({
  display: "flex",
  gap: "10px",
  overflow: "scroll",
  "& .roleInvoiceFileBox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70px"
  },
  "& .roleInvoiceFilePreview": {
    height: "70px",
    width: "70px",
    borderRadius: "8px",
    border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    marginLeft: "10px",
    cursor: "pointer"
  },
  "& .roleInvoiceFileName": {
    width: "70px",
    color: "#000000",
    fontWeight: 400,
    fontSize: "10px",
    fontFamily: "Silika-Medium"
  },
  "& .roleInvoiceUploadLabel": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    width: "70px",
    borderRadius: "5px",
    border: "1px dashed #9B9B9B",
    backgroundColor: "#FFFFFF",
    cursor: "pointer"
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "15px",
    "& .roleInvoiceFileBox, & .roleInvoiceUploadLabel": {
      width: "70px",
      height: "70px"
    },
    "& .roleInvoiceFileName": {
      fontSize: "11px"
    }
  },
  "@media (max-width: 480px)": {
    flexDirection: "column",
    "& .roleInvoiceFileBox, & .roleInvoiceUploadLabel": {
      width: "60px",
      height: "60px"
    },
    "& .roleInvoiceFileName": {
      fontSize: "10px"
    }
  }
});

const DeliverableBox = styled(Box)({
  margin: "70px",
  border: "1px solid #DFDFDF",

  "@media (max-width: 1000px)": {
    margin: "20px"
  },
  "@media (max-width: 480px)": {
    margin: "10px"
  },

  "& .createHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      height: "45px",
      padding: "0px 15px"
    },
    "@media (max-width: 768px)": {
      height: "40px",
      padding: "0px 10px"
    },
    "@media (max-width: 480px)": {
      height: "35px",
      padding: "0px 5px"
    }
  },

  "& .createViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "16px"
    },
    "@media (max-width: 768px)": {
      fontSize: "15px"
    },
    "@media (max-width: 480px)": {
      fontSize: "14px"
    }
  },

  "& .rowsValue": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium"
  },

  "& .statusBox": {
    backgroundColor: "pink",
    width: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "20px",
    padding: "3px 0px"
  },

  "& .actionBox": {
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    width: "190px",
    display: "flex",
    alignItems: "center",
    padding: "3px 0px",
    justifyContent: "center"
  },

  "& .actioneeBox": {
    display: "flex",
    gap: "20px",
    flexDirection: "row",
    alignItems: "center"
  },

  "& .MuiTableCell-root": {
    padding: "10px 5px"
  },

  "& .MuiTableCell-head": {
    color: "#000000",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
});
const TerminateModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .modalInsideBox": {
    width: "500px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    height: "200px",
    "@media (max-width: 600px)": {
      width: "90%",
      height: "auto"
    }
  },
  "& .header": {
    height: "50px",
    backgroundColor: "#0D0C22",
    display: "flex",
    alignItems: "center",
    padding: "0px 25px",
    flexDirection: "row",
    "@media (max-width: 600px)": {
      padding: "0px 15px"
    }
  },
  "& .headerText": {
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium"
  },
  "& .content": {
    display: "flex",
    padding: "10px"
  },
  "& .contentText": {
    marginTop: "20px",
    color: "#010101",
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    textAlign: "center",
    width: "100%"
  },
  "& .actionButtons": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    padding: "10px 25px",
    "@media (max-width: 600px)": {
      padding: "10px 15px"
    }
  },
  "& .cancelBtn": {
    textTransform: "unset",
    color: "#0D0C22",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#FFFFFF",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px"
  },
  "& .yesBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    backgroundColor: "#010101",
    padding: "8px 10px",
    border: "1px solid #010101",
    cursor: "pointer",
    borderRadius: "1px"
  }
});

// Customizable Area End
