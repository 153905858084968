// Customizable Area Start
import React from "react";
import { Grid, 
  Box, 
  Typography, 
  Button,
  styled, 
  ButtonGroup, 
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import JobListingController from "./JobListingController"
import HeaderNavbar from "../../../components/src/Navbar"
import JobCard from "../../../components/src/JobCard.web"
import { AdvanceSearch }from "./components/advanceSearch"
import JobPostPopUp from "../../../components/src/JobPostPopUp";
import PostPositionPopUp from '../../../components/src/PostPositionPopUp';
import { closeIcon, companyLogo } from "./assets";

const StyledTabWrapper = styled(Box)({
  marginTop:"35px",
  display:"flex",
  flexDirection:"column",
  padding: "80px",
  "@media (max-width:960px)": {
    padding:"20px",
  },
  "& .tabTitle":{
    fontFamily:"Silika-Medium !important",
    "@media (max-width:600px)": {
      fontSize:"14px !important",
    },
  },
  "& .tabBtn":{
    borderRadius:"0px"
  },
  "& .cardContainer":{
   display:"flex",
   columnGap:"2%",
    "@media (max-width:960px)": {
      marginBottom:"20px",
    },
  },
  "& .OpportunitiesText":{
    "@media (max-width:600px)": {
     fontSize:"24px !important"
    },
  },
  "& .opportunityBtn":{
    "@media (max-width:600px)": {
      width:"135px !important",
      fontSize:"14px !important"
     },
  }
  
})

const StyledDialogApplyJob = styled(Dialog)({
  "& .MuiDialog-paperFullWidth":{
    maxWidth: "600px",
    height:"234px"
  },
  "& .MuiDialogTitle-root":{
    padding:"15px 40px"
  },
  "& .MuiTypography-h6":{
    fontSize:"16px",
    fontFamily:"Silika-Medium"
  },
  "& .subTitle":{
    fontFamily:"Silika-Light",
    fontSize:"16px",
    fontWeight:300,
  },
  "& .cancelBtn":{
    textTransform:"none",
    border:"1px solid black",
    borderRadius:"2px",
    fontSize:"16px",
    fontFamily:"Silika-Medium",
    width:"104px",
    height:"48px"
  },
  "& .yesBtn":{
    width:"112px",
    backgroundColor:"black",
    color:"white",
    marginRight:"32px"
  }
})



class JobListing extends JobListingController {

  async componentDidMount() {
    this.setState({ showMessage: true });
  }

  handleClose = () => {
    this.setState({ showMessage: false });
  };
  
  buttonGroupOfTab = () =>{
    return(
      <ButtonGroup style={webStyle.opportunityDiv}>
      <Button
        data-test-id="InfoDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChange(1);
        }}
        style={this.state.activeTab ===1 ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={this.state.activeTab ===1 ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
         Search
        </Typography>
      </Button>
      <Button
        data-test-id="workDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChange(2);
        }}
        style={this.state.activeTab === 2 ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={this.state.activeTab === 2 ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
          Invitations{" "}
        </Typography>
      </Button>
      <Button
        data-test-id="workDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChange(3);
        }}
        style={this.state.activeTab === 3 ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={this.state.activeTab === 3 ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
          Applications{" "}
        </Typography>
      </Button>
    </ButtonGroup>
    )
  }

  DialogApplyJob = () =>(
    <StyledDialogApplyJob
    fullWidth={true}
    maxWidth="md"
    open={this.state.applyPopup}
    aria-describedby="alert-dialog-slide-description"
>
    <div style={webStyle.experienceInnerDiv1}>
        <DialogTitle style={webStyle.dialougeHeading}>
          Appy Job
        </DialogTitle>
        <img src={closeIcon} style={{ width: "15px", height: "15px", marginRight: "32px", cursor: "pointer" }} data-test-id="closeBtnIcon" id="close-img" onClick={()=>this.handlePopupApply()}></img>
    </div>
    <DialogContent style={webStyle.dialogContent}>
      <Typography className="subTitle">Are you sure you want to apply for this role?</Typography>
    </DialogContent>
    <DialogActions style={{marginBottom:"16px"}}>
        <Button className="cancelBtn" data-test-id="cancelBtn" onClick={()=>this.handlePopupApply()}>Cancel</Button>
        <Button className="yesBtn cancelBtn" data-testId="sendDataId" >yes, Apply</Button>
      </DialogActions>
    </StyledDialogApplyJob>)

  render() {
    return (
      <Grid>
        <HeaderNavbar navigation={this.props.navigation}/>
        <JobPostPopUp isOpen= {this.state.isPostJobOpen} onClose= {this.handleNewOpportunity}/>
        {this.DialogApplyJob()}
       <StyledTabWrapper>
        <Box style={webStyle.opportunityBox}>
          <Typography style={webStyle.OpportunitiesText} className="OpportunitiesText">Opportunities</Typography>
          <Button style={webStyle.opportunityBtn} className="opportunityBtn" onClick={this.handleNewOpportunity}>New Opportunity</Button>
        </Box>
         {this.buttonGroupOfTab()}
        {this.state.activeTab ===1 && 
       <Grid container style={webStyle.jobCardContainer}>
          <AdvanceSearch/>
        <JobCard
              title="Automotive Designer"
              category="Supercars"
              updatedTime="5 days ago"
              level="Senior"
              location="Coventry, UK"
              type="Full Time | Remote"
              profession="Interior Design | UI/UX Design | Visualization"
              dates="13 Mar 24 - 25 Apr 24"
              rate="£45.00/hr"
              budget="£2,000"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
              imageUrl={companyLogo}
              stage="open"
              btnText="Apply"
              handleApplyPopUp={this.handlePopupApply}
              data-test-id="jobcard"
         />
        </Grid>}
        {this.state.activeTab ===2 && 
        <Grid container style={webStyle.jobCardContainer}>
           {Array.from(Array(2)).map((_, index) => (
         <Box style={{marginBottom: "40px"}}>
           <JobCard
               title="Automotive Designer"
               category="Supercars"
               updatedTime="5 days ago"
               level="Senior"
               location="Coventry, UK"
               type="Full Time | Remote"
               profession="Interior Design | UI/UX Design | Visualization"
               dates="13 Mar 24 - 25 Apr 24"
               rate="£45.00/hr"
               budget="£2,000"
               description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
               imageUrl={companyLogo}
               stage="invitation"
               btnText="Accept"
               handleApplyPopUp={this.handlePopupApply}
            
          />
        </Box>
        ))}
         </Grid>
        }
        {this.state.activeTab === 3 && 
        <Grid container style={webStyle.jobCardContainer}>
         
           {Array.from(Array(4)).map((_, index) => (
         <Box style={{marginBottom: "40px", minWidth: "100%"}}>
        <JobCard
              title="Automotive Designer"
              category="Supercars"
              updatedTime="5 days ago"
              level="Senior"
              location="Coventry, UK"
              type="Full Time | Remote"
              profession="Interior Design | UI/UX Design | Visualization"
              dates="13 Mar 24 - 25 Apr 24"
              rate="£45.00/hr"
              budget="£2,000"
              description=""
              imageUrl={companyLogo}
              stage="application"
              btnText="Withdraw"
              handleApplyPopUp={this.handlePopupApply}
         />
         </Box>
        ))}
        </Grid>
        }
        </StyledTabWrapper>
      </Grid>
      
    );
  }
}

export default JobListing;

const webStyle = {
  outerMostContainerSize: {
    height: "600px",
    width: "100%",
    marginTop: "100px",
    marginBottom:'100px',
    paddingTop:50,
    position: 'relative' as 'relative'
  },
  opportunityDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoDiv: {
    width: "50%",
    alignItems: "center",
    border: "none",
    borderBottom: "2px solid #000",
  },
  workDiv: {
    width: "50%",
    alignSelf: "center",
    border: "none",
    borderBottom: "2px solid #DFDFDF",
  },
  info: {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",
    textTransform: "none" as "none",
  },
  info1: {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",
    color: "#9B9B9D",
    textTransform: "none" as "none",
  },
  jobCardContainer: {
    padding: "48px 0 48px 0",
  },
  OpportunitiesText: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    color: "#000000",
  },
  opportunityBtn: {
    width: "157px",
    height: "48px",
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19.2px",
    letterSpacing: "-0.15px",
    textTransform:"none" as "none"
},
opportunityBox: {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "50px"
},
popupOverlay: {
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "1000"
},
popup: {
  background: "white",
  padding: "20px",
  borderRadius: "10px",
  boxshadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
},
buttonpop: {
  margintop: "10px",
  padding: "5px 10px",
  border: "none",
  borderRadius: "5px",
  backgroundColor: "#243642",
  color: "white",
  cursor: "pointer",
  margin: "auto",
  display: "flex"

},
experienceInnerDiv1: {
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "1195px",
  background: "#000",
  borderRadius: "4px, 4px, 0px, 0px",
  fontSize: "14px !important",
  fontWeight: 500,
  fontFamily: "Silika-Light",
  lineHeight: "19.3px",
  color: "white",
  alignItems: "center"
},
dialougeHeading: {
  fontFamily: "Silika-Medium",
  fontSize: "16px !important",
  fontWeight: 500,
  lineHeight: "19px",
},
dialogContent:{
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
}
};
// Customizable Area End
